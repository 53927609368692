<script>
    import { createEventDispatcher } from "svelte";
    let theAdmin = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token");
    import configs from "../config";
    export let data;
    let title = data.title,
        price = data.price,
        wallet = data.wallet,
        channels = data.channels,
        channelss = data.channelss,
        chColors = data.chColors;
    const dispatch = createEventDispatcher();
    const submit = () => {
        dispatch("submit", {
            data: {
                name: title,
                price,
                wallet,
                channels: channelss,
            },
        });
    };
    const close = () => {
        dispatch("close", {
            message: "close",
        });
    };
    const qrEdit = (e) => {
        let formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("payload", "PAYLOAD");
        fetch(`${configs.baseUrl}api/plans/${data._id}/editQr`, {
            method: "PATCH",
            body: formData,
            headers: {
                authorization: `Bearer ${token}`,
                phone: theAdmin.phone,
                email: theAdmin.email,
                imei: theAdmin.imei,
            },
        }).then(async (res) => {
            let data = await res.json();
            console.log(data);
        });
    };
    const isSelected = (index) => {
        chColors[index] = !chColors[index];
    };
    const addToChannelsss = (item, index) => {
        isSelected(index);
        const isThere = channelss.some((i) => i == item._id);
        if (isThere) {
            channelss = channelss.filter((i) => i != item._id);
            console.log(channelss);
        } else {
            channelss = [...channelss, item._id];
            console.log(channelss);
        }
    };
    let src = configs.baseUrl + data.qrCode;
    console.log(data, "DATA ");
</script>

<div
  class="fixed modal overflow-hidden"
  style="
min-height: 130vh;
z-index: 100;
width: 100vw;
position: fixed;
top:0;
left: 0;
background: #000000aa;
inset : 0;

"
>
<button class="hideBtn" on:click={() => dispatch("close", {})} />
  <div class="relative flex justify-center  " style="width: 100% ; height: 100%; ">
    
    <div
      class="mt-2 flex flex-wrap bg-white rounded-lg MODAL md:p-4"
      style=""
    >
            <div class="md:w-6/12 w-full p-5">
                <label for="name">عنوان</label>
                <input
                    type="text"
                    id="name"
                    bind:value={title}
                    class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
                />
            </div>
            <div class="md:w-6/12 w-full p-5">
                <label for="name">مبلغ</label>
                <input
                    type="text"
                    id="name"
                    bind:value={price}
                    class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
                />
            </div>
            <div class="md:w-6/12 w-full p-5">
                <label for="name">آدرس کیف پول</label>
                <input
                    type="text"
                    id="name"
                    bind:value={wallet}
                    class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
                />
            </div>

            <div class="md:w-6/12 w-full p-5">
                <div class="flex flex-wrap">
                    <div class="w-full ">
                        <p class="text-bold">افزودن کانال ها</p>
                    </div>

                    <div class="w-full" style="padding: 1rem 0 ">
                        <div class="flex flex-wrap">
                            {#each channels as itemm, index}
                                {#if chColors[index]}
                                    <div
                                        class="w-4/12"
                                        on:click={() =>
                                            addToChannelsss(itemm, index)}
                                        style="padding: 0.4rem 1rem ; background-color: lime ; cursor: pointer;
                                border-radius: 8px ;"
                                    >
                                        {itemm.name}
                                    </div>
                                {:else}
                                    <div
                                        class="w-4/12"
                                        on:click={() =>
                                            addToChannelsss(itemm, index)}
                                        style="padding: 0.4rem 1rem ; background-color: #ded ;
                                border-radius: 8px ;"
                                    >
                                        {itemm.name}
                                    </div>
                                {/if}
                            {/each}
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col w-full md:w-1/2">
                <div class="w-full md:w-6/12 p-5">
                    <label
                        id="img"
                        style="background-color: blueviolet;float: right"
                        class=" hover:bg-indigo-dark text-center text-white font-bold py-2 rounded  px-4 w-full items-center"
                    >
                        انتخاب عکس
                        <input
                            style="visibility: hidden;"
                            id="img"
                            on:change={(e) => {
                                qrEdit(e);
                                src = URL.createObjectURL(e.target.files[0]);
                            }}
                            accept="image/*"
                            class="cursor-pointer absolute block opacity-0 pin-r pin-t"
                            type="file"
                            name="vacancyImageFiles"
                        />
                    </label>
                </div>
                <div class="w-full md:w-6/12 p-5 " style="height: 16rem;">
                    <div
                        class=" inline-block mt-3"
                        style="width: 100%;
                          border: 2px dashed gray; border-radius: 8px;
                          background-image: url({src}) ; height: 100%; background-size: cover ; background-repeat: round; "
                    />
                </div>
            </div>

            <div class="w-full">
                <button
                    on:click={submit}
                    style="margin: 0rem auto 1.5rem auto; display: inherit;background-color: blueviolet; width:96% ; cursor: pointer;"
                    class="rounded text-white w-full py-2 text-center"
                >
                    ارسال تغیرات</button
                >
            </div>
            <!-- <div class="w-full md:w-6/12">
                <button on:click={close}
                    style="margin: 0rem auto 1.5rem auto; display: inherit;background-color: tomato; width:96% ; cursor: pointer;"
                    class="rounded text-white w-full py-2 text-center"  > بستن پنجره</button>
            </div> -->
        </div>
    </div>
</div>

<style>
  .MODAL {
    border-radius: 16px;
    animation-name: modal;
    animation-duration: 1s;
    z-index: 110;
    inset: 0;
    position: relative;
    overflow-y: auto;
    height: 80vh;
    width: 90%;
  }

  @media screen and (max-width : 992px){
    .MODAL{
      width: 90%;
      overflow-x: hidden;
    }
  }

    @keyframes modal {
        0% {
            transform: scale(0.8) translateY(1000px);
            opacity: 0;
        }
        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
        }
    }

    .MODAL::-webkit-scrollbar {
        width: 5px;
    }

    .MODAL::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    .MODAL::-webkit-scrollbar-thumb {
        background-color: #dcdcdc;
        border-radius: 16px;
    }

    .hideBtn {
        all: unset;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
    }
</style>
