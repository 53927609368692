import {writable} from "svelte/store"

const user =  writable({})
const users =  writable([])
const signals =  writable([])
const posts =  writable([])
const payments =  writable([])
const getPay = writable([])
const infos =  writable({})
const chartItems =  writable([])
const persianChannels = writable([])
const englishChannels = writable([])
const postPageNumber = writable(0)
const userPageNumber = writable(0)
const totalPosts = writable(0)
const totalUsers = writable(0)
const totalPayments = writable(0)
const totalSignals =  writable(0)
const totalMedia =  writable(0)
const totalRobot =  writable(0)
const signalPageNumber = writable(0)
const mediaPageNumber = writable(0)
const paymentPageNumber = writable(0)
const lotteryPageNumber = writable(0)
const RobotPageNumber = writable(0)
const totalLottery = writable(0)
const showSide = writable(false);
export {user , infos , users , signals , chartItems , totalRobot,RobotPageNumber,
     posts , persianChannels , englishChannels , postPageNumber , totalMedia,mediaPageNumber,
     totalPosts , totalSignals , signalPageNumber,totalUsers,userPageNumber, getPay , showSide, lotteryPageNumber , totalLottery , paymentPageNumber, totalPayments , payments}