<script>
  import { onMount } from "svelte";
  // library that creates chart objects in page
  import Chart from "chart.js";
  import {chartItems} from "../../store"
  
  let datasets = []
  let names = []
  let colors = ['#44ff22'  , '#aa6940' , '#fa3295' , '#450812' , '#dafeae' , '#636261']
  let  id = ""
  export let items
  // init chart
 
 $: {

  
    if(items.length > 0 )
    {
     
      items.forEach(async(item , index) => {
            if(item.labels.length > 0)
            {
            if(names.includes(item.enname)) {
              return 
            }
            names.push(item.enname)
            datasets.push({
            label: item.name,
            backgroundColor: colors[index],
            borderColor: colors[index],
            data:item.counts,
            fill: false
          })
            }
         
     })
      let labels =  items[0].labels 
      
     
      var config = {
      type: "line",
      data: {
        labels,
        datasets
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Sales Charts",
          fontColor: "black",
        },
        legend: {
          labels: {
            fontColor: "black",
          },
          align: "end",
          position: "bottom",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: "black",
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "تاریخ",
                fontColor: "black",
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: "black",
                zeroLineColor: "yellow",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: "black",
              },
              display: true,
              scaleLabel: {
                display: true,
                labelString: "تـعداد",
                fontColor: "black",
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: "black",
                zeroLineColor: "rgba(33, 37, 41, 0)",
                zeroLineBorderDash: [0],
                zeroLineBorderDashOffset: [0],
              },
            },
          ],
        },
      },
    };
   setTimeout(() => {
    
      var ctx = document.getElementById("line-chart").getContext("2d");
      window.myLine = new Chart(ctx, config);
    
   }, 1000);
  }
      } 
    
  
    

</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white"
>
  <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full max-w-full flex-grow flex-1">
        
       
        <h2 class="text-black text-xl font-semibold">
          آمارها
        </h2>
       
      </div>
    </div>
  </div>
  <div class="p-4 flex-auto">
    <!-- Chart -->
   
    <div class="relative h-350-px">
      <canvas id="line-chart"></canvas>
    </div>  <!-- content here -->
    
  </div>
</div>
