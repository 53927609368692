<script>
    import { onMount, tick } from "svelte";
    import configs from "../../config";
    import {createEventDispatcher} from "svelte"
    import AddBtn from "../../components/addButton/AddBtn.svelte";

    let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
    export let externalData = {};
    const dispatch = createEventDispatcher();
    let {
            active,
            description,
            amount,
            code,
            _id
    } = externalData;
    let value = ""
    let amountt;

    const sendDiscount = () => {
         dispatch("data", {
        data: {
            active,
            description,
            amount : parseFloat(amount),
            code,
            _id

        },
      });
    
      
    }
</script>




<div class="fixed modal overflow-hidden" style="
min-height: 130vh;
z-index: 100;
width: 100vw;
position: fixed;
top:0;
left: 0;
background: #000000aa;

">
<button class="hideBtn" on:click={() => dispatch("close", {})} />

<div class="" style="width: 100% ; height: 100%;">

    <div class="mt-2 w-100 flex flex-wrap bg-white rounded-lg MODAL" style="">
    <div class="w-full md:w-6/12">
        <label for="symbol"> كد تخفيف</label>
        <input
            type="text"
            id="discountCode"
            bind:value={code}
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
        />
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="symbol"> مقدار</label>
        <input
            type="text"
            id="amount"
            bind:value={amount}
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
        />
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="symbol"> توضيحات</label>
        <textarea  class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow" bind:value={description} rows="4" cols="35" />
    </div>

    <div class="md:w-6/12 mx-5 w-full p-5">
        <p>فعال سازي</p>
        <div style="display: inline-block;">
            <label class="inline-block items-center">
                <input
                    type="checkbox"
                    class="form-radio"
                    name="forFirstone"
                    on:click={() => {
                        active = !active;
                        console.log(active);
                    }}
                    checked={active}
                />
                <span class="ml-2">بله</span>
            </label>
        </div>
    </div>
    <div class="w-full p-2">
        <!-- <button 
        style="background-color: blueviolet; width:100% ;  cursor: pointer;" 
        class="rounded  text-white w-full p-2 text-center"
        on:click="{sendDiscount}"> ارسال</button> -->
        <AddBtn on:click="{sendDiscount}">ارسال</AddBtn>
    </div>

</div>


</div>
</div>


<style>
    .MODAL{
        border-radius: 16px;
        animation-name: modal;
        animation-duration: 1s;
        z-index: 8;
        position: relative;
        inset: 0;
        min-height: 80vh;
        overflow-y: auto;

        
    }


    @keyframes modal {
        0% {
    transform:scale(.8) translateY(1000px);
    opacity:0;
  }
  100% {
    transform:scale(1) translateY(0px);
    opacity:1;
  }
    }

    .MODAL::-webkit-scrollbar {
        width: 5px;
    }
    
    .MODAL::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    .MODAL::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 16px;
    }

    .hideBtn{
    all: unset;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

</style>
