<script>
    import {onMount} from "svelte"
    import Notification from "../../components/Notification.svelte";
    import Imageviewer from '../../components/Imageviewer.svelte'
    import RobotPagination from '../../components/RobotPagination.svelte'
    let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
    let getUser = [] , searchUserList = []
    let statuss = 0
    let messageReceived = false , messageStatus , result="",modal = false , modalData,phoneSearch = "",textIdSearch = ""
    const openModal2 = (data) => {
    modalData =data
    modal = true;  
    }
    let total , page=1;
    let disabled = false,
        rDisabled = false,
        lDisabled = false

    onMount(()=>{
        fetch("https://api.pishroopardakht.com/api/marvelpay/getpaylist",{
            method : "POST",
        headers:{"Content-Type": "application/json"},
        body : JSON.stringify({
            page : 1,
            limit : 10
        })
        }).
        then(async (res) => {


            res = await res.json();
            console.log(res)
            if(page==1){
                rDisabled=true
            }else{
                rDisabled = false
            }
            getUser = [...res];
            console.log(getUser.length);
            total = getUser.length

            // if (res.status < 300){
            //     res = await res.json();
            //     console.log(res)
            //     lotteryUser = [...res];
            // }else{
            //     res = await res.json();
            //     console.log(res)
            // }
        }).catch()
    })
    let boolPage = false

    const handlePage = (enter) => {
        if(page==1){
            rDisabled=true
            if(enter < 0){
                page =1
            }else{
                page = page + enter
            }
        }else{
            rDisabled=false
            page = page + enter
        }
        
        fetch("https://api.pishroopardakht.com/api/marvelpay/getpaylist",{
            method : "POST",
        headers:{"Content-Type": "application/json"},
        body : JSON.stringify({
            page : page,
            limit : 10
        })
        }).
        then(async (res) => {


            res = await res.json();
            getUser = [...res];
            total = getUser.length
            // if(page==1){
            //     rDisabled=true
            // }else{
            //     rDisabled = false
            // }

            // if (res.status < 300){
            //     res = await res.json();
            //     console.log(res)
            //     lotteryUser = [...res];
            // }else{
            //     res = await res.json();
            //     console.log(res)
            // }
        }).catch()
    
    }

    const accRej = (enterPhone,enterStatus) => {
        fetch("https://api.pishroopardakht.com/api/marvelpay/acceptPay",{
            method : "POST",
        headers:{"Content-Type": "application/json"},
        body : JSON.stringify({
            phone : enterPhone,
            status : enterStatus
        })

        }).
        then(async (res) => {
            if(res.status < 300){
                res = await res.json();
                console.log(res)
                if(res.message== 'پرداخت تایید شد'){
                    messageReceived=true
                    result = "پرداخت تایید شد"
                    messageStatus = 200
                    setTimeout(() => {
                        messageReceived = false
                        result = ""
                        messageStatus = 0
                    }, 4000);
                }else{
                    messageReceived=true
                    result = "پرداخت رد شد"
                    messageStatus = 400
                    setTimeout(() => {
                        messageReceived = false
                        result = ""
                        messageStatus = 0
                    }, 4000);
                }
                
            }else{
                res = await res.json();
                console.log(res)
               
            }
            
           
        }).catch()
    }

    const searchPhone = ()=>{
        fetch("https://api.pishroopardakht.com/api/marvelpay/search",{
            method : "POST",
        headers:{"Content-Type": "application/json"},
        body : JSON.stringify({
            phone : phoneSearch
        })

        }).
        then(async (res) => {
            if(res.status < 300){
                res = await res.json();
                searchUserList = [...res]
                
            }else{
                res = await res.json();
                console.log("errorr",res)
               
            }
            
           
        }).catch()
    }

    const searchTextid = ()=>{
        fetch("https://api.pishroopardakht.com/api/marvelpay/search",{
            method : "POST",
        headers:{"Content-Type": "application/json"},
        body : JSON.stringify({
            txid : textIdSearch
        })

        }).
        then(async (res) => {
            if(res.status < 300){
                res = await res.json();
                searchUserList = [...res]
                
                
            }else{
                res = await res.json();
                console.log(res)
               
            }
            
           
        }).catch()
    
    }
</script>

{#if messageReceived}
{#if messageStatus > 299}
<Notification message={result}  color = "tomato"></Notification>
{:else}
<Notification color="#43ff11" message={result}></Notification>
{/if}

{/if}

{#if modal}
    <Imageviewer external={modalData}   on:close={()=> modal = false} />
{/if}
<div class=" md:mt-50 z-10 mx-auto" style="padding-bottom: 2rem; margin-top: 8rem ; height : auto; width : 90%; max-width:100%;">
    <div class="w-100 bg-white p-2 rounded-lg shadow-lg" style="margin-top: 7rem ; margin-bottom : 1rem" >
        <div class="w-full  p-5">
          <label for="formName" class="w-full block" style="direction: rtl !important; float: right;">لیست پرداخت ربات </label>
          <div class="flex flex-col items-center md:flex-row md:justify-between  mt-5 w-full" >
            <div class="m-3">
              <input type="text" id="formphone" bind:value="{phoneSearch}" placeholder="جستجو تلفن"
              class="shadow mt-2 appearance-none border
                  border-red-500 rounded  w-9/12 py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                <button on:click="{searchPhone}" style="padding:0.55rem ; border: none;  border-radius: 8px; background-color: #44ff11; color: tomato; font-weight: 500;" class="w-2/12">جستجو</button>
            </div>
            
            <div class="m-3">
                <input type="text"  id="formtextId" bind:value="{textIdSearch}" placeholder="جستجو تكس آيدي"
                class="shadow mt-2 appearance-none border
                    border-red-500 rounded  w-9/12 py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                <button disabled={textIdSearch.length != 64}  on:click="{searchTextid}"
                 style="padding:0.55rem ; border: none;  border-radius: 8px; background-color: {textIdSearch.length != 64 ? "#44ff1177" : "#44ff11"}; color: tomato; font-weight: 500;" class="w-2/12">جستجو</button>
            </div>
          </div>
         </div>
         <div class="channel-table">

                  <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                      <div class="w-full overflow-x-auto">
                          <table class="w-full">
                              <thead>
                                  <tr class="text-md font-semibold tracking-wide text-left text-white  uppercase border-b border-gray-600" style="background: #343434 ">
                                      <th class="px-4 py-3 text-right">نام کاربری</th>
                                      <th class="px-4 py-3 text-right">نام و نام خانوادگی</th>
                                      <th class="px-4 py-3 text-right">عکس</th>
                                      <th class="px-4 py-3 text-right">صرافی</th>
                                      <th class="px-4 py-3 text-right">secret key</th>
                                      <th class="px-4 py-3 text-right">api key</th>
                                      <th class="px-4 py-3 text-right">تکس آیدی</th>
                                      <th class="px-4 py-3 text-right">وضعیت</th>
                                      <th class="px-4 py-3 text-right"></th>
                                  </tr>
                              </thead>
                              <tbody class="bg-white">
                                  {#each searchUserList as i , index}
                                  <tr class="text-gray-700">
      
                                      <td class="px-4 py-3 border">
                                          <div class="flex items-center text-sm">
                                              <p>{i.phone}</p>
                                              <!-- <button class="border-0 rounded bg-indigo-400 text-dark px-2 py-1">پرداخت</button> -->
                                          
                                          </div>
                                      </td>
                                      <td class="px-4 py-3 border">
                                          <div class="flex items-center text-sm">
                                              <p>{i.name} {i.family}</p>
                                              <!-- <button class="border-0 rounded bg-indigo-400 text-dark px-2 py-1">پرداخت</button> -->
                                          
                                          </div>
                                      </td>
                                      <td class="px-4 py-3 border">
                                          <div class="flex items-center text-sm">
                                            <div>
                                              
                                            {#if i.image != undefined && i.image.length > 1}
                                            <div on:click={openModal2(`http://marveltradeconsult.com${i.image}`)} style="width: 80px ; background-image:url('{`http://marveltradeconsult.com${i.image}`}') ; background-size: cover ; background-position: center  ; height : 80px ; cursor : zoom-in " ></div>
                                            {:else}
                                              <div  style="width: 80px ; background-image:url('/assets/img/noimage.png') ; background-size: cover ; background-position: center ; height : 80px ; cursor : zoom-in" >
                                                <!-- <img src={Noimage} alt="no image" /> -->
                                              </div>
                                            {/if}
                      
                                            </div>
                                          </div>
                                        </td>
      
                                        <td class="px-4 py-3 border">
                                          <div class="flex items-center text-sm">
                                              {#if i.exchange != null}
                                              <p>{i.exchange}</p>
                                              {:else}
                                              <p></p>
                                              {/if}
                                             
                                          
                                          </div>
                                      </td>
      
                                      <td class="px-4 py-3 border">
                                          <div class="flex items-center text-sm">
                                              <p>{i.secretkey}</p>
                                              <!-- <button class="border-0 rounded bg-indigo-400 text-dark px-2 py-1">پرداخت</button> -->
                                          
                                          </div>
                                      </td>
                                      <td class="px-4 py-3 border">
                                          <div class="flex items-center text-sm">
                                              <p>{i.apikey}</p>
                                              <!-- <button class="border-0 rounded bg-indigo-400 text-dark px-2 py-1">پرداخت</button> -->
                                          
                                          </div>
                                      </td>
                                      <td class="px-4 py-3 border">
                                          <div class="flex items-center text-sm">
                                              <p>{i.txid}</p>
                                              <!-- <button class="border-0 rounded bg-indigo-400 text-dark px-2 py-1">پرداخت</button> -->
                                          
                                          </div>
                                      </td>
      
                                      <td class="px-4 py-3 border">
                                          <div class="flex items-center text-sm">
                                              
                                              {#if i.status == 1}
                                              <p  class=" font-bold" >در انتظار تایید</p>
                                              {:else if i.status == 2}
                                              <p style="color: #32CD32;" class=" font-bold">تایید شد</p>
                                              {:else}
                                              <p class="text-red-500 font-bold">رد شد</p>
                                              {/if}
                                          
                                          
                                          </div>
                                      </td>
                                     
                                      <td class="px-4 py-3 border">
                                          <div class="text-sm flex flex-col justify-around items-center">
                                              
                                              <button on:click={()=>{accRej(i.phone , 2)}} class="outline-0  m-1	 border-0 rounded font-bold text-white px-3 py-1 paymentBtn">تایید</button>
                                              <button on:click={()=>{accRej(i.phone , 3)}} class="outline-0	mx-1 border-0 rounded font-bold text-white px-3 py-1 rejectBTN">رد</button>
      
                                          </div>
                                          
                                      </td>
                                  </tr>
                                  {/each}
                                 
      
                              </tbody>
                          </table>
                         
                      </div>
                  </div>
                </div>
      </div>


      


    <div class="channel-table">
      <p class="my-2 font-bold text-lg">درآمد کاربران</p>
            <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                <div class="w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="text-md font-semibold tracking-wide text-left text-white  uppercase border-b border-gray-600" style="background: #343434 ">
                                <th class="px-4 py-3 text-right">نام کاربری</th>
                                <th class="px-4 py-3 text-right">نام و نام خانوادگی</th>
                                <th class="px-4 py-3 text-right">عکس</th>
                                <th class="px-4 py-3 text-right">صرافی</th>
                                <th class="px-4 py-3 text-right">secret key</th>
                                <th class="px-4 py-3 text-right">api key</th>
                                <th class="px-4 py-3 text-right">تکس آیدی</th>
                                <th class="px-4 py-3 text-right">وضعیت</th>
                                <th class="px-4 py-3 text-right"></th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            {#each getUser as i , index}
                            <tr class="text-gray-700">

                                <td class="px-4 py-3 border">
                                    <div class="flex items-center text-sm">
                                        <p>{i.phone}</p>
                                        <!-- <button class="border-0 rounded bg-indigo-400 text-dark px-2 py-1">پرداخت</button> -->
                                    
                                    </div>
                                </td>
                                <td class="px-4 py-3 border">
                                    <div class="flex items-center text-sm">
                                        <p>{i.name} {i.family}</p>
                                        <!-- <button class="border-0 rounded bg-indigo-400 text-dark px-2 py-1">پرداخت</button> -->
                                    
                                    </div>
                                </td>
                                <td class="px-4 py-3 border">
                                    <div class="flex items-center text-sm">
                                      <div>
                                        
                                      {#if i.image != undefined && i.image.length > 1}
                                      <div on:click={openModal2(`http://marveltradeconsult.com${i.image}`)} style="width: 80px ; background-image:url('{`http://marveltradeconsult.com${i.image}`}') ; background-size: cover ; background-position: center  ; height : 80px ; cursor : zoom-in " ></div>
                                      {:else}
                                        <div  style="width: 80px ; background-image:url('/assets/img/noimage.png') ; background-size: cover ; background-position: center ; height : 80px ; cursor : zoom-in" >
                                          <!-- <img src={Noimage} alt="no image" /> -->
                                        </div>
                                      {/if}
                
                                      </div>
                                    </div>
                                  </td>

                                  <td class="px-4 py-3 border">
                                    <div class="flex items-center text-sm">
                                        {#if i.exchange != null}
                                        <p>{i.exchange}</p>
                                        {:else}
                                        <p></p>
                                        {/if}
                                       
                                    
                                    </div>
                                </td>

                                <td class="px-4 py-3 border">
                                    <div class="flex items-center text-sm">
                                        <p>{i.secretkey}</p>
                                        <!-- <button class="border-0 rounded bg-indigo-400 text-dark px-2 py-1">پرداخت</button> -->
                                    
                                    </div>
                                </td>
                                <td class="px-4 py-3 border">
                                    <div class="flex items-center text-sm">
                                        <p>{i.apikey}</p>
                                        <!-- <button class="border-0 rounded bg-indigo-400 text-dark px-2 py-1">پرداخت</button> -->
                                    
                                    </div>
                                </td>
                                <td class="px-4 py-3 border">
                                    <div class="flex items-center text-sm">
                                        <p>{i.txid}</p>
                                        <!-- <button class="border-0 rounded bg-indigo-400 text-dark px-2 py-1">پرداخت</button> -->
                                    
                                    </div>
                                </td>

                                <td class="px-4 py-3 border">
                                    <div class="flex items-center text-sm">
                                        
                                        {#if i.status == 1}
                                        <p  class=" font-bold" >در انتظار تایید</p>
                                        {:else if i.status == 2}
                                        <p style="color: #32CD32;" class=" font-bold">تایید شد</p>
                                        {:else}
                                        <p class="text-red-500 font-bold">رد شد</p>
                                        {/if}
                                    
                                    
                                    </div>
                                </td>
                               
                                <td class="px-4 py-3 border">
                                    <div class="text-sm flex flex-col justify-around items-center">
                                        
                                        <button on:click={()=>{accRej(i.phone , 2)}} class="outline-0  m-1	 border-0 rounded font-bold text-white px-3 py-1 paymentBtn">تایید</button>
                                        <button on:click={()=>{accRej(i.phone , 3)}} class="outline-0	mx-1 border-0 rounded font-bold text-white px-3 py-1 rejectBTN">رد</button>

                                    </div>
                                    
                                </td>
                            </tr>
                            {/each}
                           

                        </tbody>
                    </table>
                   
                </div>
            </div>
            <div class="" style="max-width: 150px ; height: 30px; border: 2px #334477aa solid;margin: 0 auto ; border-radius: 6px;">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class="w-2/5" style="text-align: center ;background-color: whitesmoke;float:right; width:40%; height: 100% ; 
                    ; font-weight: 600;cursor: pointer  ; border-radius: 6px ; border-top-left-radius: 0px;border-bottom-left-radius: 0px;
                     {lDisabled ? "color: #34343434 ; background: #ffffff22!important": "color:  #3333aa ; background: whitesmoke" }" disabled={lDisabled} on:click={()=>{handlePage(1)}}>بعدی</a>
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class=""
                    style="width: 20% ; text-align: center ; height: 100%;border-right: 1px solid gray; border-left: 1px solid gray;display: inline-block">{page
                    }</a>
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class="w-2/5" style="text-align: center  ;float: left;width: 40%;height: 100% ;
                     ; font-weight: 600;cursor: pointer ; border-radius: 6px ; 
                     {rDisabled ? " color: #34343434 ; background: #ffffff22 !important"
                    : "color:  #3333aa; background: whitesmoke" }" disabled={rDisabled} on:click={()=>{handlePage(-1)}} >قبلی</a>
            
            </div>
            
    </div>
    
</div>

<style>
    .paymentBtn{
        background-color: blueviolet;
        outline: none;
        width: 75px;
    }
    .paymentBtn:hover , .paymentBtn:focus{
        background-color: rgb(127, 22, 225);
    }

    .rejectBTN{
        background-color: tomato;
        color: white;
        outline: none;
        width: 75px;
    }

    .rejectBTN:hover , .rejectBTN:focus{
        background-color: rgb(249, 72, 41);
    }
</style>
