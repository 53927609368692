<script>
    import { onMount } from "svelte";
    import { Moon } from 'svelte-loading-spinners'
    import configs from "../../config";
    let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
    let a = "a"
    let step = false
    let lotteryUser = []
    onMount(()=>{
        fetch(`${configs.baseUrl}api/users/getrewardableusers`,{
            method : "GET",
        headers:{
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
        }).
        then(async (res) => {
            res = await res.json();
            
            lotteryUser = [...res];
            console.log( "lll" , lotteryUser)
            // if (res.status < 300){
            //     res = await res.json();
            //     console.log(res)
            //     lotteryUser = [...res];
            // }else{
            //     res = await res.json();
            //     console.log(res)
            // }
        }).catch()
    })

    let winner = {}

    const doLottery = () => {
        if(!step){
            const keys =  Object.keys(lotteryUser)
            const prop =  keys[Math.floor(Math.random() * keys.length)]
            winner = lotteryUser[prop]
            console.log(winner)
            step=true
            return winner
        }else{
            step=false
        }
        
    }
</script>

<div class="z-10 mx-auto h-auto md:h-full " style="padding-bottom: 2rem; margin-top: 8rem ;  width : 80%; ">
    <div style="height : 350px" class="bg-white shadow-lg rounded relative w-full md:w-3/5 p-4">
        {#if !step}
            <div class="flex flex-col items-center justify-center py-3">
                <img  alt="lottery" />
                
            </div>
        {:else}
            <div class="flex flex-col items-center justify-center mt-2 py-3 test">
                
                <p class="font-bold mb-2">برنده خوش شانس</p>
                <div style="background-color : #90EE90;" class=" text-white  border-0 shadow md:w-2/3  p-3 rounded-lg mb-3 flex flex-col md:flex-row items-center justify-center md:justify-around md:items-center">
                    <p class="font-bold">نام و نام خانوادگي : </p>
                    <span>{winner.fullname}</span>
                </div>
                <div  style="background-color : #2FBEFF;" class="text-white border-0 shadow md:w-2/3  p-3 rounded-lg mb-3 flex flex-col md:flex-row items-center justify-center md:justify-between md:items-center">
                    <p class="font-bold mx-1">  شماره تماس: </p>
                    <span>  {winner.phone}  </span>
                </div>
                <div  style="background-color : #FF6F7F;" class="text-white border-0 shadow md:w-2/3  p-3 rounded-lg mb-3 flex flex-col md:flex-row items-center justify-center md:justify-between md:items-center">
                    <p class="font-bold"> امتياز : </p>
                    <span>{winner.amount * 50}</span>
                </div>
                
            
            </div>
        {/if}
        <div class="flex justify-center">
            <button style="background-color : #00CF8A " class="rounded mx-auto  py-2 px-4 text-white absolute bottom-0 mb-2 font-bold" on:click={doLottery}>{step ? "بازگشت" : "قرعه كشي"}</button>
        </div>
    </div>
</div>

<style>
    .test{
        transition: all 3s;
        animation-name: example;
        animation-duration: 1s;
        
    }
    @keyframes example {
        0% {transform: translateX(-50px) ; opacity: 0.5;}
        100% {transform: translateX(0px) ;opacity: 1;}
    }
</style>