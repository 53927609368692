<script>
  // core components
  import CardStats from "components/Cards/CardStats.svelte";
  import {infos} from "../../store"
  import _ from "lodash"
import { onMount } from "svelte";
import configs from "../../config";
let infs = []
    let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
let colors = ["bg-emerald-400" , "bg-lightBlue-200" , "bg-pink-500" , "bg-yellow-500" , "bg-white" , "bg-lightBlue-500"]
  onMount(()=> {
    infos.subscribe(d=> {
      if(_.isEmpty(d)) {
        fetch(configs.baseUrl+"api/infos/getCounts",{
          headers:{
               "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
              }).then(async(res) => {
           res = await res.json()
         
           Object.keys(res).forEach((key , index)=>{
             infs[index] = {}
            infs[index]["key"] =  key
            infs[index]["value"] =  res[key]
            infs[index]["color"]=  colors[index]
          })
         
          infos.update(d=> d = infs)
          
        })
      }
    })
  })
</script>

<!-- Header -->
<div class="relative header m-3 bg-white md:pt-16 pb-32 pt-12">
  <div class="px-4 md:px-10 mx-auto w-full">
    <div>
      <!-- Card stats -->
      <div class="flex justify-center items-center flex-wrap">
        
        {#each infs as item }
        <div class="w-full flex justify-around items-center flex-wrap lg:w-4/12 xl:w-4/12 px-4 my-2">
          <CardStats
            background="bg-white"
            statSubtitle= {item.key}
            statTitle= {item.value}
            statIconName="far fa-chart-bar"
            statIconColor="bg-lightBlue-500"
          />
        </div>
        {/each}
     
      </div>
    </div>
  </div>
</div>

<style>
  .header{
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    background: #673AB7;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #512DA8, #673AB7);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #512DA8, #673AB7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */



  }
</style>
