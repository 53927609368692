<script>
    import { createEventDispatcher, onMount } from "svelte";
    import AddBtn from "../../components/addButton/AddBtn.svelte";
    import configs from "../../config";
    const dispatch = createEventDispatcher();

    let theAdmin = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token");
    let code = "";
    let amountt;
    let description= "";
    let value = "";
    let active = false;
    let expiresIn = "" ;

    const handleDiscount = () => {
        // let form = new FormData();

        // form.append("active", active);
        // form.append("description", description);
        // form.append("code", code);
        // form.append("amount", amount);
        let discount = JSON.stringify({
            active,
            description : value,
            amount : parseFloat(amountt),
            code,
            ExpireDate : expiresIn
            
        });
        fetch(`http://127.0.0.1/api/discounts/addDiscount`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                authorization: `Bearer ${token}`,
                phone: theAdmin.phone,
                email: theAdmin.email,
                imei: theAdmin.imei,
            },
            body: discount,
        })
            .then(async (res) => {
                
                if (res.status < 300) {
                    res = await res.json();
                    alert("كد تخفيف با موفقیت افزوده شد")
                    active = false
                    value = ""
                    code = ""
                    amountt=0
                    
                    
                } else {
                    res = await res.json();
                    if(res.message ==  "duplicated discount code"){
                        alert("كد تخفيف وجود دارد")
                    }
                }
                // setTimeout(() => {}, 4000);
            })
            .catch(console.log);
    };

    let formSubmit = true
    $:if(code.length>0 && amountt<100 && amountt>0 && value.length>0){
        formSubmit = false
    }

    const expireHandle = (enter) => {
        const date = new Date(enter)
        expiresIn = date.toISOString()
    }
</script>

<div
class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4" style=" margin-top: 8rem; overflow: hidden; margin-bottom: 3rem;border-radius : 16px; "  
>
    <div class="mt-5 flex flex-wrap bg-white" style="width: 100%;">
        <div class="w-full md:w-6/12 p-5">
            <label for="symbol"> كد تخفيف</label>
            <input
                type="text"
                id="discountCode"
                bind:value={code}
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            />
        </div>
        <div class="w-full md:w-6/12 p-5">
            <label for="symbol"> مقدار</label>
            <input
                min="1"
                max="100"
                type="text"
                id="amount"
                bind:value={amountt}
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            />
        </div>
        <div class="w-full md:w-6/12 p-5">
            <label for="symbol"> توضيحات</label>
            <textarea  bind:value rows="4" cols="35"  class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow" ></textarea>
        </div>

        <div class="w-full md:w-6/12 p-5">
            <label for="symbol">تاریخ انقضا</label>
            <input type="date" id="symbol"  on:change="{(e)=> { expireHandle(e.target.value)}}"
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
        </div>

        <div class="md:w-6/12 mx-5 w-full p-5">
            <p>فعال سازي</p>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input
                        type="checkbox"
                        class="form-radio"
                        name="forFirstone"
                        on:click={() => {
                            active = !active;
                            console.log(active);
                        }}
                        checked={active}
                    />
                    <span class="ml-2">بله</span>
                </label>
            </div>
        </div>

        <div class="w-full p-2">
            <!-- <button
                style={`background-color: ${formSubmit ?  "#B272EC" : "blueviolet"}; width:100% ;  cursor: pointer;`}
                class="rounded  text-white w-full py-2 text-center"
                on:click={handleDiscount}
                disabled={formSubmit}
            >
                ارسال</button
            > -->
            <AddBtn  on:click={handleDiscount} disabled={formSubmit}>ارسال</AddBtn>
        </div>
    </div>
</div>

<style>
    @import url('https://v1.fontapi.ir/css/Estedad');
    p  , div , input , label{
        font-family: Estedad, sans-serif;
        
    }
</style>