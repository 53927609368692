<script>
import {
    onMount,
    tick
} from "svelte";
import Editor from "../../components/Editor.svelte";
import Notification from "../../components/Notification.svelte";
import Spinner from "../../components/Spinner.svelte"
import PlanModal from "../../components/PlanModal.svelte"
import InsertPlan from "../../components/InsertPlan.svelte"

let wallet, price, title, channelss = [],
    chColors = [],
    modalChColors = [],
    lastItemSelected = {}
let plansReceived = false
let channelsReceived = false
let loading = false
import configs from "../../config";
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
let plans = [],
    channels = [],
    extData = {},
    modalOpen = false , pr = false 
onMount(() => {
    pr = JSON.parse(localStorage.getItem("user")).priviliages[11]
    fetch(configs.baseUrl + "api/channels/public/getChannels/english",{
        headers: {
              "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
        if (res.data) channels = [...channels, ...res.data];
        channelsReceived = true
    })
    fetch(configs.baseUrl + "api/channels/public/getChannels/persian",{
        headers: {
              "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
        if (res.data) {
            channels = [...channels, ...res.data]
            chColors.length = channels.length
            chColors.fill(false)
        }
    })
    fetch(configs.baseUrl + "api/plans/getPlans",{
        headers: {
              "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
        if (res) plans = [...res];
        console.log(plans);
        plansReceived = true
    })
})
const getChannel = (id) => {
    let c = channels.find(item => {
        console.log(item._id, id);
        return item._id == id
    })

    if (c) return c.name
}
let notifyMessage = ""
let notifyReceived = false , addModal = false
let notifyColor = "" 
const toggleAdd = ()=> {
  addModal = !addModal
}
const deleteItem = (item) => {
    fetch(configs.baseUrl + "api/plans/" + item._id + "/deletePlan", {
        method: "DELETE",
        headers: {
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(res => {
        notifyReceived = true
        if (res.status < 300) {
            notifyMessage = "پلن با موفقیت حذف شد "
            notifyColor = "#43ff11"
            plans = plans.filter(i => i == item)
        } else {
            notifyMessage = "خطایی رخ داد"
            notifyColor = "tomato"
        }

        setTimeout(() => {
            notifyMessage = ""
            notifyReceived = false
        }, 4000);
    })
}
const openModal = (item) => {
modalChColors = []      
    channels.forEach(ch => {
       
        if (item.channels.includes(ch._id))
            modalChColors.push(true)
        else modalChColors.push(false)
    })

    extData = {
        _id: item._id,
        title: item.name,
        price: item.price,
        wallet: item.wallet,
        channels,
        channelss: item.channels,
        chColors: modalChColors,
    }
    lastItemSelected = item
    modalOpen = true

};
const isSelected = (index) => {
    chColors[index] = !chColors[index]
}
const addToChannelss = (item, index) => {
    isSelected(index)
    const isThere = channelss.some(i => i == item._id)
    if (isThere) {
        channelss = channelss.filter(i => i != item._id)
        console.log(channelss);
    } else {
        channelss = [...channelss, item._id]
        console.log(channelss);
    }
}
const submit = (e) => {
  console.log(e.detail);
  notifyMessage = e.detail.notifyMessage
  notifyReceived = e.detail.notifyReceived
  notifyColor = e.detail.notifyColor
  if (e.detail.status < 300)
  {
    plans = [e.detail.data , ...plans]
  }
  setTimeout(() => {
                    notifyReceived = false
                    notifyMessage = ""
                    notifyColor = ""
                }, 4000)
}
const modalSubmit = (e) => {
    let data = e.detail.data
    let id = extData._id
    console.log(data, plans , id);
    fetch(`${configs.baseUrl}api/plans/${extData._id}/editPlan`, {
        method: "PATCH",
        body: JSON.stringify(e.detail.data),
        headers: {
            "Content-Type": "application/json",
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).then(async (res) => {
        if (res.status < 300) {
            notifyMessage = "پلن با موفقیت ویرایش شد"
            notifyColor = "#43ff11"
            notifyReceived = true
            modalOpen = false
            data._id = id
            let index = plans.indexOf(lastItemSelected)
            console.log(index);
            plans[index] = data
            plans = [...plans]
            lastItemSelected = {}
            setTimeout(() => {
                notifyReceived = false
            }, 4000);
        } else {
            notifyMessage = "خطایی رخ داد"
            notifyColor = "tomato"
            notifyReceived = true
            modalOpen = false
            setTimeout(() => {
                notifyReceived = false
            }, 4000);
        }

    })
}
const modalClose = () => {
    modalOpen = false
}
</script>

{#if notifyReceived}
<Notification color = {notifyColor}  message = {notifyMessage}></Notification>
{/if}
<div class=" md:mt-50 z-10 " style="padding-bottom: 2rem;">
    <div class="channel-table">
      
        <h2 class="mb-3 " style="font-weight: 600; font-size: 1.2rem ; padding: 2rem 0">لیست پلن ها</h2>
        <section class="container mx-auto w-full  font-mono" style="">
            <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                <div class="w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="text-sm bg-white font-medium tracking-wide text-left text-black  uppercase border-b shadow" style=" ">
                                <th class="px-2 py-3 text-right">عنوان</th>
                                <th class="px-2 py-3 text-right">کانال ها</th>
                                <th class="px-2 py-3 text-right">کیف پول</th>
                                <th class="px-2 py-3 text-right">قیمت</th>
                                <th class="px-2 py-3 text-right">حذف / بازنویسی</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            {#if plansReceived && channelsReceived}

                            {#each plans as item}

                            <tr class="text-gray-700 border-b shadow">
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{item.name}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>

                                            {#each item.channels as chItem}

                                            <p class="font-semibold text-black">{getChannel(chItem)}</p>
                                            {/each}
                                        </div>
                                    </div>
                                </td>

                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>

                                            <p class="font-semibold text-black">{item.wallet}</p>

                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>

                                            <p class="font-semibold text-black">{item.price}</p>

                                        </div>
                                    </div>
                                </td>

                                <td class=" px-4 py-3 " >
                                  {#if pr}
                                  <i class="fa-edit fas"  style="margin: 0 1rem; cursor: pointer " on:click={openModal(item)} ></i>
                                  <i class="fa-trash fas text-red-500"  style="margin: 0 1rem; cursor: pointer" on:click={deleteItem(item)} ></i>
                                  {/if}
                                </td>
                            </tr>

                            {/each}
                            {:else}
                            <div class="w-full" style="background-color: #343434; width: 100%">
                                <Spinner></Spinner>
                            </div>
                            {/if}

                        </tbody>
                    </table>
                </div>
            </div>

        </section>
        <div class="mt-5">
            <!-- <Editor on:message={setEditorData}></Editor> -->
        </div>
    </div>
   

</div>
{#if modalOpen}
<PlanModal on:submit={modalSubmit} on:close={modalClose} data={extData}></PlanModal>
{/if}
{#if addModal}
  
<div class="fixed modal" style="
min-height: 100vh;
z-index: 1000;
width: 100vw;
position: fixed;
overflow: hidden;
top:0;
left: 0;
background: #000000aa;
">

</div>
{/if}