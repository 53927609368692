<script>
  // core components

  export let statSubtitle = "Traffic";
  export let statTitle = "350,897";
  // The value must match one of these strings
  // "up" or "down"
  export let statArrow = "up";
  export let statPercent = "3.48";
  // can be any of the text color utilities
  // from tailwindcss
  export let statPercentColor = "text-emerald-500";
  export let statDescripiron = "Since last month";
  export let statIconName = "far fa-chart-bar";
  export let background = ""
  // can be any of the background color utilities
  // from tailwindcss
  export let statIconColor = "bg-red-500";
</script>

<div
  class={background + ' relative flex flex-col items-center justify-center   break-words  rounded m-4 xl:mb-0  cardStatus'}
>
  <div class="p-4">
    <div class="flex justify-center items-center">
      <div class="relative pl-4 flex-initial">
        <div
          class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full {statIconColor}"
        >
          <i class="{statIconName}"></i>
        </div>
      </div>
      <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
        <h5 class=" uppercase font-bold text-xs">
          {statSubtitle}
        </h5>
        <span class="font-semibold text-xl text-black">
          {statTitle}
        </span>
      </div>
      
    </div>
   
  </div>
</div>


<style>
  .cardStatus{
    width: 200px;
    height: 150px;
    border-radius: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    transition: ease-in-out 0.5s;
  }

  .cardStatus:hover{
    transform: scale(1.1);
    box-shadow: rgba(29, 121, 182, 0.25) 0px 4px 8px -2px, rgba(0, 0, 0, 0.3) 0px 3px 5px -3px;
  }



  @media (max-width : 992px){
    .cardStatus{
    width: 80%;
    height: 100px;
    border-radius: 16px;
  }
  }
</style>