<script>
    import {
    onMount,
    tick
} from "svelte";
import configs from "../../config";
import EditDiscount from './EditDiscount.svelte'


let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
    let getDiscount = []
    let modall = false
    let item = {}
    let activee;
    const codeList = [{name : "fadf" , discountCode : "asdadsfersdfsadfwedf" , status : true , amount : 12.5 },
    {name : "fadf" , discountCode : "asdadsfersdfsadfwedf" , status : false , amount : 19 }]

onMount(()=>{
    fetch(configs.baseUrl + "api/discounts/getallDiscount",{
        method : "GET",
        headers: {
              "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
        getDiscount = res
        console.log(res)
        
    })
})

const openModal = (data) => {
    item = data;
    modall = true;
}
const reActive = (enterId , enter) => {
    fetch(`${configs.baseUrl}api/discounts/${enterId}/${enter}/reactiveDiscount`,{
        method : "PATCH",
        headers: {
              "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
        console.log(res)
        
    })
}


const sendEdit = (e) => {
        console.log(e);
        let data = {
            ...e.detail.data
        }
        data = JSON.stringify(data)
        console.log("DATA" , data);
    
      fetch(configs.baseUrl + "api/discounts/" + e.detail.data._id + "/editDiscount ", {
        method: "PATCH",
        body: data,
        headers: {
            "Content-type": "application/json",
            authorization: `Bearer ${token}`,
            phone: theAdmin.phone,
            email: theAdmin.email,
            imei: theAdmin.imei,
        },
        }).then(async (res) => {
            if(res.status <300){
            res = await res.json();
            console.log(res);
            let item = getDiscount.find(i => i._id == JSON.parse(data)._id)
            
            data = JSON.parse(data)
            Object.keys(item).forEach(k => {
                if (k != "_id")
                    item[k] = data[k]
            })
            console.log(item);
            let index = getDiscount.indexOf(item)

            getDiscount = [...getDiscount]
            alert("كد تخفيف با موفقيت ويرايش شد")
            modall=false
        }else{

            
            alert("كد تخفيف ويرايش نشد")
        }
        
        
        });
    }


</script>

{#if modall}
<EditDiscount externalData={item} on:data={sendEdit} on:close={()=> {modall = false}} ></EditDiscount>
{/if}

<div class="z-10 mx-auto" style="padding-bottom: 2rem; margin-top: 8rem ; height : auto; width : 90%; ">
     <h2 class="mb-3 " style="font-weight: 600; font-size: 1.2rem ; padding: 2rem 0">ليست كد تخفيف</h2>
        <section class="container mx-auto w-full  font-mono" style="">
            <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                <div class="w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="text-sm bg-white font-medium tracking-wide text-left text-black  uppercase border-b shadow" style=" ">
                                <th class="px-2 py-3 text-right"> توضيحات </th>
                                <th class="px-2 py-3 text-right"> كد تخفيف</th>
                                <th class="px-2 py-3 text-right">تاریخ انقضا</th>
                                <th class="px-2 py-3 text-right">وضعيت</th>
                                <th class="px-2 py-3 text-right">مقدار</th>
                                <th class="px-2 py-3 text-right">بازنویسی</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
 

                            {#each getDiscount as i , index}

                            <tr class="text-gray-700 border-b shadow">
                                
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>

                                            {i.description}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>

                                            <p class="font-semibold text-black">{i.code}</p>

                                        </div>
                                    </div>
                                </td>

                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>

                                            <p class="font-semibold text-black">{i.ExpireDate}</p>

                                        </div>
                                    </div>
                                </td>

                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div class="w-full">
                                            <div style="display: inline-block;">
                                                <label class="inline-block items-center">
                                                    <input type="checkbox" class="form rounded checked:bg-red-600" name="discount"
                                                        on:change={()=>{reActive(i._id,true);i.active=true}}  checked={i.active} group={i.active}>
                                                    <span class="ml-2">فعال</span>
                                                </label>
                                            </div>
                                            <div style="display: inline-block;">
                                                <label class="inline-block items-center">
                                                    <input type="checkbox" class="form rounded" name="discount"
                                                        on:change={()=>{reActive(i._id,false);i.active=false}} checked={!i.active} group={i.active}>
                                                    <span class="ml-2">غير فعال</span>
                                                </label>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>

                                            <p class="font-semibold text-black">{i.amount}</p>

                                        </div>
                                    </div>
                                </td>

                                <td class="px-4 py-3 " style="padding: 0 1rem">
                                  
                                  <i class="fa-edit fas"  style="margin: 0 1rem; cursor: pointer " on:click={()=>{openModal(i)}} ></i>
                                  <!-- <i class="fa-trash fas"  style="margin: 0 1rem; cursor: pointer" on:click={deleteItem(i)} ></i> -->
                                  
                                </td>
                            </tr>

                            {/each}
                            <!-- {:else}
                            <div class="w-full" style="background-color: #343434; width: 100%">
                                <Spinner></Spinner>
                            </div>
                            {/if} -->

                        </tbody>
                    </table>
                </div>
            </div>

        </section>
        <div class="mt-5">
        </div>
    </div>
   
    <style>
        @import url('https://v1.fontapi.ir/css/Estedad');
        p  , table , tr , th , td , div , input , label{
            font-family: Estedad, sans-serif;
            
        }
    </style>