<script>
  import { link , navigate } from "svelte-routing";
  import {user} from "../../store"
  import configs from "../../config"
    import BackDrop from "../../components/BackDrop/BackDrop.svelte";
    import Spinner from "../../components/Spinner/Spinner.svelte";
  // core components
  const github = "../assets/img/github.svg";
  const google = "../assets/img/google.svg";
  let email, password , loading = false 

  const doNav = ()=> {
    loading=true
    let body = {password}
    if (!Number.isNaN(+email)) body.phone = email 
    else body.email = email
    body = JSON.stringify(body)

    fetch(`${configs.baseUrl}api/authentication/login`, 
    {
      method: "POST",
      body,
      headers : {"Content-Type" : "application/json"}
    }).then(async(res) => {
      res = await res.json()
      
      console.log(res);
      if (res.isAdmin) { 
          user.update(d =>  {
            d = res
            localStorage.setItem("token" , res.token)
            localStorage.setItem("user" , JSON.stringify(res.user))
            loading=false
            navigate("/admin/dashboard")

          })
      } else {
        alert("YOU ARE NOT AUTHORIZED")
        loading=false
      }
    })
  }
</script>

{#if loading}
  <BackDrop/>
  <Spinner/>
{/if}

<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
      >
        
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          
          <form class="mt-6">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-email"
              >
                Email or Phone
              </label>
              <input
                id="grid-email"
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Email"
                bind:value={email}
              />
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-password"
              >
                Password
              </label>
              <input
                id="grid-password"
                type="password"
                bind:value={password}
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Password"
              />
            </div>
            <div>
             
            </div>

            <div class="text-center mt-6">
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                type="button"
                on:click={doNav}
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
      
    </div>
  </div>
</div>
