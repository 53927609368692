<script>
  import { Router, Route, navigate } from "svelte-routing";
  import {user} from "../store"
  // components for this layout
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar from "components/Sidebar/Sidebar.svelte";
  import HeaderStats from "components/Headers/HeaderStats.svelte";
  import Channels from "../views/admin/Channels.svelte"
  import Reports from "../views/admin/Reports.svelte"
  import Posts from "../views/admin/Posts.svelte"
  import InsertUser from "../views/admin/InsertUsers.svelte"
  import InsertSignal from "../views/admin/InsertSignal.svelte"
  import InsertPlan from "../views/admin/InsertPlan.svelte"
  import InsertChannel from "../views/admin/InsertChannel.svelte"
  import InsertPost from "../views/admin/InsertPost.svelte"
  import InsertMedia from "../views/admin/InsertMedia.svelte"
  import InsertCategory from "../views/admin/InsertCategory.svelte"
  import Lottery from "../views/admin/Lottery.svelte"
  import Privates from "../views/admin/Privates.svelte"
  // pages for this layout
  import Dashboard from "views/admin/Dashboard.svelte";
    let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
import { onMount } from "svelte";
import configs from "../config";
import {users , signals , posts} from "../store"
import Signals from "../views/admin/Signals.svelte";
import Plans from "../views/admin/Plans.svelte";
import Users from "../views/admin/Users.svelte";
import Admins from "../views/admin/Admins.svelte";
import Medias from "../views/admin/Media.svelte";
import Payments from "../views/admin/Payments.svelte";
import Discount from "../views/admin/Discount.svelte";
import { showSide } from './../store'
import InsertDiscount from "../views/admin/InsertDiscount.svelte";
import Robotpayment from "../views/admin/Robotpayment.svelte";
    import EnterExel from "../views/admin/EnterExel.svelte";
  export let location
  export const admin = "";
  $: innerWidth = 0

  onMount(()=> {
  
      fetch(configs.baseUrl + "api/users/getusers" ,{
        headers:{"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
      }).then(async(res)=> {
          res = await  res.json()
          users.update(d=> d = res.data)
      })
      
      fetch(configs.baseUrl + "api/signals/getSignals" ,{
        headers:{"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
      }).then(async(res)=> {
          res = await  res.json()
          signals.update(d=> d = res)
      })
      fetch(configs.baseUrl + "api/posts/getposts" ,{
        headers:{"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
      }).then(async(res)=> {
          res = await  res.json()
        
          posts.update(d=> d = res)
      })
     
    if(token)
    {
      user.update(d => {
        d = {token , user:theAdmin}
      })
    } 
    else navigate("/login")
  })


  
    let a =false;
    const closeSide = () => {
        // showSide.update(e=>!e)
        // console.log(showSide);
        a = !a
        console.log(a)
        if(a == true){
            document.getElementById("divside").style.display = "none"
        }else{
            document.getElementById("divside").style.display = "block"
        }
    }
</script>
<svelte:window bind:innerWidth />
<div>
 
  <div id="cont" class={a ? "relative h-full bg-gray-100 estedad" : "relative h-full mr64  bg-gray-100 estedad"} dir="rtl">
    <AdminNavbar />
    <HeaderStats />
    <div  class="px-4 md:px-10 mx-auto w-full estedad -m-24 " >
      <Router url="admin">
        <Route path="dashboard" component="{Dashboard}" />
        <Route path="channels" component="{Channels}" />
        <Route path="reports" component="{Reports}" />
        <Route path="posts" component="{Posts}" />
        <Route path="signals" component="{Signals}" />
        <Route path="plans" component="{Plans}" />
        <Route path="users" component="{Users}" />
        <Route path="insertUser" component="{InsertUser}" />
        <Route path="insertSignal" component="{InsertSignal}" />
        <Route path="insertPlan" component="{InsertPlan}" />
        <Route path="insertChannel" component="{InsertChannel}" />
        <Route path="insertPost" component="{InsertPost}" />
        <Route path="insertMedia" component="{InsertMedia}" />
        <Route path="payments" component="{Payments}" />
        <Route path="admins" component="{Admins}" />
        <Route path="media" component="{Medias}" />
        <Route path="insertCategory" component="{InsertCategory}" />
        <Route path="privates" component="{Privates}" />
        <Route path="Discount-code" component="{Discount}" />
        <Route path="insertDiscount" component="{InsertDiscount}" />
        <Route path="Lottery" component="{Lottery}" />
        <Route path="Robot-payment" component="{Robotpayment}" />
        <Route path="useExcel" component="{EnterExel}" />
        

        
      </Router>
      
    </div>
    {#if innerWidth > 767}
    <button class="p-2"  style="position : absolute ; top : 70px; right:5px;cursor : pointer; background-color :rgb(23 23 23); " on:click={closeSide}>
      {#if a}
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white	" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>
      {:else}
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white	" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
              <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
      {/if}
  </button>
    {/if}

  </div>
    <div class="" id="divside">
      <Sidebar  location={location}/>
    </div>

</div>

<style>
    @import url('https://v1.fontapi.ir/css/Estedad');
    div {
      font-family: Estedad, sans-serif;

    }
</style>