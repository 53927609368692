<script>
import {
    onMount,
    tick
} from "svelte";
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
import Notification from "../../components/Notification.svelte";
import Spinner from "../../components/Spinner.svelte"
import EditorModal from "../../components/EditorModal.svelte"
import {
    postPageNumber,
    totalMedia,
    mediaPageNumber
} from "../../store"
let result, messageReceived = false,
    messageStatus
let postReceived = false,
    modal = false
let medias = []
let item = {}
let total = 0,
    addPost = false

const toggleAddPost = () => addPost = !addPost

import configs from "../../config"
import MediaPaginator from "../../components/MediaPaginator.svelte";
const openModal = (data) => {
    item = data;
    modal = true;
}
const deleteItem = async (item) => {
    fetch(`${configs.baseUrl}api/medias/${item._id}/deleteMedia`, {
            method: "DELETE",
            headers:{
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
        .then(res => {
            if (res.status < 300) {
                messageReceived = true
                result = "فایل با موفقیت حذف شد"
                messageStatus = 200
                medias = medias.filter(i => i != item)
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            } else {
                messageReceived = true
                result = "خطایی رخ داد"
                messageStatus = 400
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            }
        })
}
let sort = "createdAt",
    sm = -1,
    pr = false
onMount(() => {
    mediaPageNumber.subscribe(d => {
        fetchPosts(10, d, sort, sm)
    })
    fetch(`${configs.baseUrl}api/infos/getbanner`, {
            method: "GET",
            headers:{
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        }).then(async(res)=> {
            banner = await res.json()
        })
})
let banner 

const deleteBannerItem = async(item)=> {
    fetch(`${configs.baseUrl}api/infos/${item._id}/deleteFromBanner`, {
            method: "DELETE",
            headers:{
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
        .then(res => {
            if (res.status < 300) {
                messageReceived = true
                result = "فایل با موفقیت حذف شد"
                messageStatus = 200
                banner = medias.filter(i => i != item)
                banner = [...banner]
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            } else {
                messageReceived = true
                result = "خطایی رخ داد"
                messageStatus = 400
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            }
        })
}
const fetchPosts = (num, skip, sort, sm) => {
    fetch(`${configs.baseUrl}api/medias/${num}/${skip}/${sort}/${sm}/getMedias` , {
        headers: {
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).then(async (res) => {
        res = await res.json()
        medias = res.medias
        console.log(medias, res.medias.length);
        total = res.total
        
        totalMedia.update(d => d = medias.length)
        postReceived = true

    })
}
const setEditorData = (e) => {

    result = e.detail.message
    let data = e.detail.data
    messageReceived = true
    messageStatus = e.detail.status
    if (messageStatus < 300 && !modal) {

        medias = [e.detail.data, ...medias]
    } else if (messageStatus < 300 && modal) {

        let i = medias.find(itemm => itemm._id == data._id)
        let index = medias.indexOf(i)
        medias[index] = e.detail.data
        medias = [...medias]

    }

    modal = false
    setTimeout(() => {
        messageReceived = false
        result = ""
        messageStatus = 0
    }, 4000);

}
const doSort = (data) => {
    let temp = data.split("/")
    sort = temp[0]
    sm = +temp[1]

    mediaPageNumber.subscribe(d => {
        fetchPosts(10, d, sort, sm)
    })
}
</script>

{#if messageReceived}
{#if messageStatus > 299}
<Notification message={result}  color = "tomato"></Notification>
{:else}
<Notification color="#43ff11" message={result}></Notification>
{/if}

{/if}
{#if modal}
<EditorModal on:close={()=> modal = false} on:message={setEditorData} external={item}></EditorModal>
{/if}
<div class=" md:mt-50 z-10 " style="padding-bottom: 2rem;">
    <div class="w-full">

        <div class="" style="float:left ; margin-bottom: 1rem;margin-top: 7rem">
            <!-- <div class="" style="float: left;">
        <label for="sort" style="display: block; margin-bottom: 0.2rem; ">  مرتب سازی</label>
        <select on:change={(e)=> doSort(e.target.value)} name="" style="display: inline-block;" id="sort">
            <option style="text-align: right;" value="createdAt/-1">  تاریخ ثبت /  نزولی</option>
            <option style="text-align: right;" value="createdAt/1"> تاریخ ثبت  / سعودی</option>
            <option style="text-align: right;" value="category/-1"> دسته بندی   / نزولی</option>
            <option style="text-align: right;" value="category/1"> دسته بندی   / سعودی</option>
            <option style="text-align: right;" value="title/-1"> عنوان / نزولی</option>
            <option style="text-align: right;" value="title/1"> عنوان / سعودی</option>
            <option style="text-align: right;" value="content/-1"> محتوا  / نزولی</option>
            <option style="text-align: right;" value="content/1"> محتوا  / سعودی</option>

        </select>
            </div> -->
        </div>
    </div>
    <div class="channel-table">
        <h2 class="mb-3 " style="font-weight: 600; font-size: 1.2rem ; padding: 2rem 0">لیست فایل ها</h2>
        <section class="container mx-auto w-full  font-mono" style="">
            <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                <div class="w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="text-sm bg-white font-medium tracking-wide text-left text-black  uppercase border-b shadow" style=" ">
                                <th class="px-2 py-3 text-right">عنوان</th>
                                <th class="px-2 py-3 text-right">دسته بندی</th>
                                <th class="px-2 py-3 text-right">زیر دسته بندی</th>
                                <th class="px-2 py-3 text-right">لینک</th>

                                <th class="px-2 py-3 text-right">حذف </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            {#if postReceived}
                            {#each medias as item, index}
                            {#if index < 10}
                            <tr class="text-gray-700 border-b shadow">
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{item.title}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{item.category}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{item.subCategory}</p>
                                        </div>
                                    </div>
                                </td>
                              
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">http://marveltradeconsult.com/media/{item.link}</p>
                                        </div>
                                    </div>
                                </td>

                                <td class="px-4 py-3 " style="padding: 0 1rem">
                                    
                                    <!-- content here -->
                                    <i class="fa-trash fas text-red-500"  style="margin: 0 1rem; cursor: pointer" on:click={deleteItem(item)} ></i>
                                    
                                </td>
                            </tr>
                            {/if}
                            {/each}
                            {:else}
                            <div class="w-full" style="background-color: #343434; width: 100%">
                                <Spinner></Spinner>
                            </div>
                            {/if}

                        </tbody>
                    </table>
                </div>
            </div>
           {#if postReceived}
           <MediaPaginator total={total} ></MediaPaginator>
           {/if}
        </section>

    </div>
    <div class="channel-table">
        <h2 class="mb-3 " style="font-weight: 600; font-size: 1.2rem ; padding: 2rem 0">لیست فایل ها</h2>
        <section class="container mx-auto w-full  font-mono" style="">
            <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                <div class="w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="text-sm bg-white font-medium tracking-wide text-left text-black  uppercase border-b shadow" style=" ">


                                <th class="px-2 py-3 text-right">لینک</th>
                                <th class="px-2 py-3 text-right">عکس</th>

                                <th class="px-2 py-3 text-right">حذف </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            {#if banner}
                            {#each banner as item, index}
                       
                            <tr class="text-gray-700 border-b shadow">
                                <td class="px-4 py-3 border">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{item.link}</p>
                                        </div>
                                    </div>
                                </td>
                               
                              
                                <td class="px-4 py-3 border">
                                    <div class="flex items-center text-sm">
                                        <div >
                                            <img src={`http://marveltradeconsult.com${item.image}`} alt=""  style="width: 120px;height: 80px;">
                                        </div>
                                    </div>
                                </td>

                                <td class="border " style="padding: 0 1rem">
                                    
                                    <!-- content here -->
                                    <i class="fa-trash fas text-red-500"  style="margin: 0 1rem; cursor: pointer" on:click={deleteBannerItem(item)} ></i>
                                    
                                </td>
                            </tr>
                         
                            {/each}
                            {:else}
                            <div class="w-full" style="background-color: #343434; width: 100%">
                                <Spinner></Spinner>
                            </div>
                            {/if}

                        </tbody>
                    </table>
                </div>
            </div>
           
        </section>

    </div>
</div>
