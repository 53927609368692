<script >
    import {createEventDispatcher} from "svelte"
import { now } from "svelte/internal";
import configs from "../config";
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
import { onMount } from 'svelte'
    import AddBtn from "./addButton/AddBtn.svelte";
    export let external, plans
    let {
        phone,
        firstName,
        lastName,
        imei,
        isPersian,
        expiresIn,
        plan,
        plan2,
        firstTimeCharge,
        firstTime,
        _id,
        email,
        device,
        channelsNotify 
    } = external
    let planMenu, showPlanMenu = false,showPlanMenu2=false , devicee 
  
    let firstTimee

    let Datepicker;
    if (device.toLowerCase() =="android") 
    {
        devicee = true 
        device = "android"
    }
    else 
    {
        devicee = false
        device = "ios"
    }


    // if (firstTime == true) 
    // {
    //     firstTimee = true 
        
    // }
    // else 
    // {
    //     firstTimee = false
    // }
const dispatch = createEventDispatcher();
const editUser = () => {
    if(channelss.length == 0 || channelss == null)
            channelss = ["000000000000000000000001"]
    let data = JSON.stringify({
        phone,
        firstName,
        lastName,
        imei,
        isPersian : "true" ? true: false,
        expiresIn,
        plan,
        plan2,
        firstTimeCharge,
        firstTime,
        email,
        device,
        channelsNotify: channelss
    })
    
    fetch(`${configs.baseUrl}api/users/${_id}/editUser` ,{
        method: "PATCH",
        body: data ,
        headers: {"Content-Type" : "application/json",
        "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    })
    .then( res=> {
      
        if (res.status < 300)
        {
            dispatch("finish" , {
                data : {
        phone,
        firstName,
        lastName,
        imei,
        isPersian,
        expiresIn,
        plan,
        plan2,
        firstTimeCharge,
        firstTime,
        _id,
        email,
        device,
        channelsNotify: channelss
    }  , 
    message :  "کاربر با موفقیت وبرایش شد",
    status: 200
            })
        }
        else 
        {
            
            dispatch("finish" , {
               
    message :  "خطایی رخ داد",
    status: 400
            })
        }
    })
} 

const close = ()=> {
    dispatch("close")
}


// const firstTimeHandler = () => {
//     var currentTime = new Date(Datepicker);
//     var dayy = currentTime.getDate();
//     var year = currentTime.getFullYear()
//     var month = currentTime.getMonth();
    

//     firstTimeCharge = new Date(year , month , dayy - 8);
//     console.log(firstTimeCharge);
//     console.log(dayy-8);
// }

const handleFirstTime = ()=>{
    firstTime = !firstTime

    if(firstTime){
        let now = new Date()
        firstTimeCharge=now
    }else{
        let noww = (Date.now() + (7*24*60*60*1000))
        firstTimeCharge = new Date(noww)
    }
}

 let channels = [];
let channelss = []
let chColors = []
let channelsReceived;
let plansReceived;
let pr = false 

onMount(() => {
    pr = JSON.parse(localStorage.getItem("user")).priviliages[11]
    
    fetch(configs.baseUrl + `api/channels/public/${_id}/getChannels`,{
        headers: {
              "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
        console.log(res);
        if (res != undefined) {
            if(channelsNotify != undefined){
                chColors.length = channelsNotify.length 
            }else{
                chColors.length = plans.length 

            }
            
            chColors.fill(false)
            channels = [...res[0].channels]
            console.log(channels , " CHANNELS ");
            chColors = channels.map(i => {
                console.log(channelsNotify);
                if(channelsNotify.includes(i._id))
                    return true
                return false
            })
        }

    })
    fetch(configs.baseUrl + "api/plans/getPlans",{
        headers: {
              "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
        if (res) plans = [...res];
        console.log(plans);
        plansReceived = true
    })
})
const getChannel = (id) => {
    let c = channelsNotify.find(item => {
        console.log(item._id, id);
        return item._id == id
    })

    if (c) return c.name
}

const isSelected = (index) => {
    chColors[index] = !chColors[index]
}
// in ye bar check mishe, bayad vaghte send intori she , in fgaghat too starte 
if(channelsNotify){
    channelss = [...channelsNotify]
}else{
    channelss = ["000000000000000000000001"]
}

const addToChannelss = (item, index) => {
    isSelected(index)
    const isThere = channelss.some(i => i == item._id)
    if (isThere) {
        channelss = channelss.filter(i => i != item._id)
        console.log(channelss);
    } else {
        channelss = [...channelss, item._id]
        console.log(channelss);
    }
}


</script>

<div
  class="fixed modal overflow-hidden"
  style="
min-height: 130vh;
z-index: 100;
width: 100vw;
position: fixed;
top:0;
left: 0;
background: #000000aa;
inset : 0;

"
>
<button class="hideBtn" on:click={() => dispatch("close", {})} />
  <div class="relative flex justify-center  " style="width: 100% ; height: 100%; ">
    
    <div
      class="mt-2 flex flex-wrap bg-white rounded-lg MODAL md:p-4"
      style=""
    >
        
        <div class="mt-5 flex flex-wrap bg-white"></div>
        <div class="w-full md:w-6/12 p-5">
            <label for="symbol">نام کاربری (شماره همراه)</label>
            <input type="text" id="symbol" bind:value="{phone}"
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
        </div>
        <div class="w-full md:w-6/12 p-5">
            <label for="symbol">ایمیل</label>
            <input type="email" validate id="symbol" bind:value="{email}"
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
        </div>
        <div class="w-full md:w-6/12 p-5">
            <label for="symbol">نام</label>
            <input type="text" id="symbol" bind:value="{firstName}"
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
        </div>
        <div class="w-full md:w-6/12 p-5">
            <label for="symbol">نام خانوادگی</label>
            <input type="text" id="symbol" bind:value="{lastName}"
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
        </div>
        <div class="w-full md:w-6/12 p-5">
            <label for="symbol">IMEI</label>
            <input type="text" id="symbol" bind:value="{imei}"
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
        </div>

        <!-- <div class="w-full md:w-6/12 p-5">
            <label for="firstTimeCharge">تاريخ اولين پرداخت</label>
            <input on:change={firstTimeHandler} type="date" id="firstTimeCharge" bind:value="{Datepicker}"
                class="shadow mt-2 appearance-none border border-red-500 rounded  w-full py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
        </div> -->

        <div class="w-full md:w-6/12 p-5">
            <label for="symbol">تاریخ انقضا</label>
            <input type="date" id="symbol"  on:change="{(e)=> expiresIn = new Date(e.target.value)}"
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
        </div>

        <div class="md:w-6/12 w-full p-4">
            <p>كاربر ايراني</p>
            <div class="" style="display: inline-block;">
                <label class="inline-block items-center">
                    <input type="checkbox" class="form-radio " name="forFirstone"
                        bind:value={isPersian} checked={isPersian}>
                    <span class="ml-2">بله</span>
                </label>
            </div>
            

        </div>
        
       
        <div class="md:w-6/12 w-full p-1 p-3">
            <p>فعال سازي 7روزه اوليه</p>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input type="checkbox" class="form-radio" name="forFirstone"
                        
                        on:click={handleFirstTime} checked={firstTime}>
                    <span class="ml-2">بله</span>
                </label>
            </div>
            

        </div>
        <div class="md:w-6/12 w-full p-3">
            <p class="mr-1">دستگاه</p>
            <div class="mr-1" style="display: inline-block;">
                <label class="inline-block items-center">
                    <input type="radio" class="form-radio" name="forPersian"
                        on:input="{()=> {device = "android" ;console.log(device);
                        }}" checked={devicee} group={devicee}>
                    <span class="ml-2">َANDROID</span>
                </label>
            </div>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input type="radio" class="form-radio" name="forPersian"
                        on:input="{()=> {device = 'ios' ;console.log(device);}}"  checked={!devicee} group={devicee}>
                    <span class="ml-2">IOS</span>
                </label>
            </div>
            <!-- on:input="{()=> {firstTime = !firstTime ;console.log(firstTime);
            }}" -->
        </div>

        <div class="w-full md:w-6/12" style="padding: 1rem 0 ">
            <div class="flex flex-wrap">
                <div class="w-full ">
                    <p class="text-bold"> کانال ها</p>
                </div>
                {#each channels as itemm, index}
                {#if chColors[index]}
                <div class="w-4/12 "  on:click={()=> addToChannelss(itemm , index)} 
                    style="margin-right:5px;padding: 0.4rem 1rem ; background-color: lime ; cursor: pointer;
                    border-radius: 8px ;">{itemm.name}</div>
                {:else}
                <div class="w-4/12 "  on:click={()=> addToChannelss(itemm , index)}
                    style="padding: 0.4rem 1rem ; background-color: #ded ; cursor: pointer;
                    border-radius: 8px ;">{itemm.name}</div>
                {/if}
                {/each}

            </div>

        </div>

        <div class="w-full md:w-6/12 p-5">
            <p>انتخاب پلن تحلیلی</p>
            <div class="relative mt-2 text-left">
                <div>
                    <button type="button" on:click="{()=> showPlanMenu = !showPlanMenu}"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                        id="menu-button" aria-expanded="true" aria-haspopup="true">
                        پلن تحلیلی
                        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>

                {#if showPlanMenu }
            <div class="">

                <div bind:this="{planMenu}" class="origin-top-right absolute right-0 w-full mt-2  rounded-md shadow-lg
                 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                
                 <div class="py-1" role="none">
                            {#each plans as r}
                                {#if r._id.slice(0,3) != "321"}
                                    <div style="cursor: pointer;" 
                                    on:click="{()=> {plan = r._id ;showPlanMenu = false ; console.log(plan);
                                    }}" class="text-gray-700 block px-4 py-2 text-sm" 
                                    role="menuitem" tabindex="-1" id="menu-item-0">{r.name}</div>
                                {/if}
                            {/each}
                       </div>
                </div>
            </div>
            {/if}
           
          </div>
    </div>


    <div class="w-full md:w-6/12 p-5">
        <p> انتخاب پلن آموزشی</p>
        <div class="relative mt-2 text-left">
            <div>
                <button type="button" on:click="{()=> showPlanMenu2 = !showPlanMenu2}"
                    class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                    id="menu-button" aria-expanded="true" aria-haspopup="true">
                    پلن آموزشی
                    <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd" />
                    </svg>
                </button>
            </div>

            {#if showPlanMenu2 }
        <div class="">

            <div bind:this="{planMenu}" class="origin-top-right absolute right-0 w-full mt-2  rounded-md shadow-lg
             bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            
             <div class="py-1" role="none">
                        {#each plans as r}
                            {#if r._id.slice(0,3) == "321"}
                                <div style="cursor: pointer;" 
                                on:click="{()=> {plan2 = r._id ;showPlanMenu2 = false ;;
                                }}" class="text-gray-700 block px-4 py-2 text-sm" 
                                role="menuitem" tabindex="-1" id="menu-item-0">{r.name}</div>
                            {/if}
                        {/each}
                   </div>
            </div>
        </div>
        {/if}
       
      </div>
</div>
    
    <div class="w-full p-2">
      <AddBtn on:click="{editUser}">ویرایش کاربر</AddBtn>
  </div>
    
    </div>
 
    </div>
</div>  

<style>
  .MODAL {
    border-radius: 16px;
    animation-name: modal;
    animation-duration: 1s;
    z-index: 110;
    inset: 0;
    position: relative;
    overflow-y: auto;
    height: 80vh;
    width: 90%;
  }

  @media screen and (max-width : 992px){
    .MODAL{
      width: 90%;
      overflow-x: hidden;
    }
  }

    @keyframes modal {
        0% {
    transform:scale(.8) translateY(1000px);
    opacity:0;
  }
  100% {
    transform:scale(1) translateY(0px);
    opacity:1;
  }
    }

    .MODAL::-webkit-scrollbar {
        width: 5px;
    }
    
    .MODAL::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    
    .MODAL::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 16px;
    }

    .hideBtn{
    all: unset;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

</style>