<script>
    import {
    onMount
} from "svelte";
import { get } from "svelte/store";
import configs from "../../config";



let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")

    // let rep = [];
    // let resbool = false
    let roomMenu, showRoomMenu = false

    let start;
	let finish;

    let startSignal;
    let finishSignal;

    let startSpecial ; 
    let endSpecial ; 
    let channel;

	
	function toDateTime() {
        fetch(`http://95.217.81.86/api/infos/getpaymentscsv/${start}/${finish}/download` , {
            headers : {
                "authorizattion" : `Bearer ${token}`,
                "imei" : theAdmin.imei,
                "phone" : theAdmin.phone,
                "email": theAdmin.email
            }
        }).then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "report.csv";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });

}
const getStartDate = (date)=> {
    let x  = new Date(date)
    start = x.getTime()
}
const getEndDate = (date)=> {
    let x  = new Date(date)
    finish = x.getTime()
}


// --------------------------------------signal---------------------------------------------


    function toDateTimeSignal() {
        fetch(`http://95.217.81.86/api/signals/${startSignal}/${finishSignal}/getTragetedSignals` , {
            headers : {
                "authorizattion" : `Bearer ${token}`,
                "imei" : theAdmin.imei,
                "phone" : theAdmin.phone,
                "email": theAdmin.email
            }
        }).then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "siganl.csv";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });

}


const getStartSignal = (date)=> {
    let x  = new Date(date)
    startSignal = x.getTime()
}
const getEndSignal = (date)=> {
    let x  = new Date(date)
    finishSignal = x.getTime()
}


// --------------------------------------signal---------------------------------------------


// --------------------------------------SpecialChannel---------------------------------------------

function getSpecialChannel() {
        fetch(`http://95.217.81.86/api/signals/${startSpecial}/${endSpecial}/${channel}/getTragetedSignals` , {
            headers : {
                "authorizattion" : `Bearer ${token}`,
                "imei" : theAdmin.imei,
                "phone" : theAdmin.phone,
                "email": theAdmin.email
            }
        }).then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "specialChannel.csv";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });

}


const getStartSpecial = (date)=> {
    let x  = new Date(date)
    startSpecial = x.getTime()
}
const getEndSpecial = (date)=> {
    let x  = new Date(date)
    endSpecial = x.getTime()
}

let channelsName = []
onMount(()=> {
    fetch(`${configs.baseUrl}api/infos/getchannelnames`,{
        headers: {
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    })
        .then(async (res) => {
            res = await res.json()
            channelsName = res
            console.log(channelsName);
        })
})

// --------------------------------------SpecialChannel---------------------------------------------





// --------------------------------------user7Days---------------------------------------------

function user7Days() {
        fetch("http://95.217.81.86/api/infos/getnoobs/download" , {
            headers : {
                "authorizattion" : `Bearer ${token}`,
                "imei" : theAdmin.imei,
                "phone" : theAdmin.phone,
                "email": theAdmin.email
            }
        }).then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "user7Days.csv";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });

}


// --------------------------------------user7Days---------------------------------------------



// --------------------------------------plans---------------------------------------------


let plans = [];
let showPlanMenu = false;
let planMenu;

function getPlanss() {
        fetch(`http://95.217.81.86/api/infos/${planMenu}/getUsers/download` , {
            headers : {
                "authorizattion" : `Bearer ${token}`,
                "imei" : theAdmin.imei,
                "phone" : theAdmin.phone,
                "email": theAdmin.email
            }
        }).then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "plan.csv";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });

}




onMount(()=> {
    fetch(`${configs.baseUrl}api/plans/getPlans`,{
        headers:{
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
        if (res) plans = [...res];
        console.log(plans);
    }).catch()
})




// --------------------------------------plans---------------------------------------------

const lottery = () => {
    fetch(`http://95.217.81.86/api/users/getrewardableuserscsv` , {
            headers : {
                "authorizattion" : `Bearer ${token}`,
                "imei" : theAdmin.imei,
                "phone" : theAdmin.phone,
                "email": theAdmin.email
            }
        }).then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "lottry.csv";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();  //afterwards we remove the element again         
        });
}



</script>


<div class="z-10 mx-auto  " style="padding-bottom: 2rem; margin-top: 8rem ; height : auto; width : 80%; ">
    <div class=" flex flex-col  bg-white rounded-lg shadow-xl py-5 mb-5 h-full w-full">
        <div style="background-color : rgb(74 222 128); width : 100px ; height : 40px" class="rounded p-2 mb-4 mr-2 text-white">
            <h2 class="text-center">پرداخت ها</h2>
        </div>
        <div class=" flex flex-col lg:flex-row  justify-around items-center ">
            <div class="my-2">
                <label class="text-base  font-bold ml-3" for="start">از تاريخ</label>
                <input id="start" on:change="{(e)=> getStartDate(e.target.value)}"  class="rounded-lg"  type="date"  >
            </div>

            <div class="my-2">
                <label class="text-base my-1	font-bold ml-3" for="finish">به تاريخ</label>
                <input on:change="{(e)=> getEndDate(e.target.value)}"  class=" border border-transparent rounded-lg"  type="date" >
            </div>
        </div>
        <div class=" mt-4 lg:mt-6 flex justify-center items-center">
            <button on:click={toDateTime} type="submit"  class=" downloadBtn  py-2 px-4 text-white font-semibold rounded-lg shadow-md ">
                دانلود گزارش پرداخت ها
              </button>
        </div>


        
    </div>


    <div class=" flex flex-col  bg-white rounded-lg shadow-xl py-5 mt-5  h-full w-full">
        <div style="background-color : rgb(74 222 128); width : 100px ; height : 40px" class="rounded p-2 mb-4 mr-2 text-white">
            <h2 class="text-center">سيگنال ها</h2>
        </div>
        <div class=" flex flex-col lg:flex-row  justify-around items-center ">
            <div class="my-2">
                <label class="text-base  font-bold ml-3" for="start">از تاريخ</label>
                <input id="start" on:change="{(e)=> getStartSignal(e.target.value)}"  class="rounded-lg"  type="date"  >
            </div>

            <div class="my-2">
                <label class="text-base my-1	font-bold ml-3" for="finish">به تاريخ</label>
                <input on:change="{(e)=> getEndSignal(e.target.value)}"  class=" border border-transparent rounded-lg"  type="date" >
            </div>
        </div>
        <div class=" mt-4 lg:mt-6 flex justify-center items-center">
            <button on:click={toDateTimeSignal} type="submit"  class=" downloadBtn  py-2 px-4 text-white font-semibold rounded-lg shadow-md ">
                دانلود گزارش سيگنال ها
              </button>
        </div>


        
    </div>




    <div class=" flex flex-col  bg-white rounded-lg shadow-xl py-5 mt-5  h-full w-full">
        <div style="background-color : rgb(74 222 128); width : 120px ; height : 40px" class="rounded p-2 mb-4 mr-2 text-white">
            <h2 class="text-center"> كانال هاي خاص</h2>
        </div>
        <div class=" flex flex-col lg:flex-row  justify-around items-center ">
            <div class="my-2">
                <label class="text-base  font-bold ml-3" for="start">از تاريخ</label>
                <input id="start" on:change="{(e)=> getStartSpecial(e.target.value)}"  class="rounded-lg"  type="date"  >
            </div>

            <div class="my-2">
                <label class="text-base my-1	font-bold ml-3" for="finish">به تاريخ</label>
                <input on:change="{(e)=> getEndSpecial(e.target.value)}"  class=" border border-transparent rounded-lg"  type="date" >
            </div>
        </div>
        <div class="w-full  p-5">
            
            <div class="relative mt-2 text-left">
                <div>
                    <button type="button"
                        on:click="{()=> showRoomMenu = !showRoomMenu}"
    
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                        انتخاب کانال 
                        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
    
                {#if showRoomMenu }
                <div class="">
    
                    <div bind:this="{roomMenu}" class="origin-top-right absolute right-0 w-full mt-2  rounded-md shadow-lg
                        bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
    
                        <div class="py-1" role="none">
                            {#each channelsName as r}
                            <div style="cursor: pointer;"
                                on:click="{()=> {channel = r._id ; console.log(channel);showRoomMenu = false}}" class="text-gray-700 block px-4 py-2 text-sm"
                                role="menuitem" tabindex="-1" id="menu-item-0">{r.name}</div>
                            {/each}
                        </div>
                    </div>
                </div>
                {/if}
    
            </div>
        </div>
        <div class=" mt-4 lg:mt-6 flex justify-center items-center">
            <button on:click={getSpecialChannel} type="submit"  class=" downloadBtn  py-2 px-4 text-white font-semibold rounded-lg shadow-md ">
                دانلود گزارش سيگنال 
              </button>
        </div>


        
    </div>

    <div class=" flex flex-col  bg-white rounded-lg shadow-xl py-5 mt-5  h-full w-full">
        <div style="background-color : rgb(74 222 128); width : 100px ; height : 40px" class="rounded p-2 mb-4 mr-2 text-white">
            <h2 class="text-center">پلن ها</h2>
        </div>
       
        <div class="w-full p-5">
            <div class="relative mt-2 text-left">
                <div>
                    <button type="button"
                        on:click="{()=> showPlanMenu = !showPlanMenu}"
    
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                        پلن
                        <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </button>
                </div>
    
                {#if showPlanMenu }
                <div class=""  style="height: auto;">
    
                    <div  style=""  class="origin-top-right absolute right-0 w-full mt-2  rounded-md shadow-lg
                        bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
    
                        <div class="py-1" role="none" style="height: auto;">
                            {#each plans as r}
                            <div style="cursor: pointer;"
                                on:click="{()=> {planMenu = r._id ;showPlanMenu = false ; console.log(plans);
                                }}" class="text-gray-700 block px-4 py-2 text-sm"
                                role="menuitem" tabindex="-1" id="menu-item-0">{r.name}</div>
                            {/each}
                        </div>
                    </div>
                </div>
                {/if}
    
            </div>
          </div>
        <div class=" mt-4 lg:mt-6 flex justify-center items-center">
            <button on:click={getPlanss} type="submit"  class=" downloadBtn  py-2 px-4 text-white font-semibold rounded-lg shadow-md ">
                دانلود گزارش پلن ها
              </button>
        </div>


        
    </div>


    <div class=" flex flex-col  bg-white rounded-lg shadow-xl py-5 mt-5  h-full w-full">
        <div class="flex flex-col items-center">
            <div style="background-color : rgb(74 222 128); width : 80% ;   height : 40px" class="rounded p-2  mx-auto text-white">
                <h2 class="text-center">كاربران داراي اشتراك اوليه 7 روزه</h2>
            </div>
            <div class=" ml-2 flex justify-center items-center">
                <button on:click={user7Days} type="submit"  class=" downloadBtn mt-3  py-2 px-4 text-white font-semibold rounded-lg shadow-md ">
                    دانلود   
                </button>
            </div>
        </div>
    </div>


    <div class=" flex flex-col  bg-white rounded-lg shadow-xl py-5 mt-5  h-full w-full">
        <div class="flex flex-col items-center">
            <div style="background-color : rgb(74 222 128); width : 80% ;   height : 40px" class="rounded p-2  mx-auto text-white">
                <h2 class="text-center">قرعه كشي</h2>
            </div>
            <div class=" ml-2 flex justify-center items-center">
                <button on:click={lottery} type="submit"  class=" downloadBtn mt-3  py-2 px-4 text-white font-semibold rounded-lg shadow-md ">
                    دانلود   
                </button>
            </div>
        </div>
    </div>
    
</div>


<style>
    .downloadBtn{
        background-color: #6366F1;
    }
    .downloadBtn:hover , .downloadBtn:active , .downloadBtn:focus  {
        background-color: #4338CA ;
        outline: none;
        border: none;
    }
    
</style>