<script>
    export let external 
    import {createEventDispatcher} from "svelte"
import configs from "../config";
    const dispatch = createEventDispatcher();

    
   
</script>
<div class="fixed modal" style="
        height: 100vh;
        z-index: 1000;
        width: 100%;
        position: fixed;
        overflow: auto;
        top:0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000000aa;
        ">
    <div  class="containerr" style="width: 90% ; height: 90%; overflow : auto ;  position: relative; padding:  1.5rem ; background-color: #fff; border-radius: 10px;">
                <button class="btnn"  on:click={()=>{dispatch("close")}} >X</button>
                <div class=" mt-6 mx-auto "  style="font-size: 1.2rem; width : auto ; height : auto; ">
                    <img style="width: 100%; height : 100%" src={external} alt="imagee" />
                </div>

    </div>


</div>


<style>
    .btnn{
        position: absolute;
        top : 5px;
        right : 10px;
        width : 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: white;
        color: red;
        outline: none;
        cursor: pointer;
        font-weight: bold;
        
    }
</style>