<div role="alert" id="notification" class="fixed" style="position: fixed; top:2rem ;left: -20rem; z-index: 10000; width: 20rem; ">
    <div class=" text-white font-bold rounded-t px-4 py-2" style="background-color: {color} ;">
      
    </div>
    <div class="border border-t-0 font-semibold border-red-400 rounded-b bg-red-100 px-4 py-4 text-red-700" style=" font-size: 1.5rem;color: #343434; background: whitesmoke;">
      <p>{message}.</p>
    </div>
  </div>

  <script>
import { onMount } from "svelte";


      export let color , message ;
     
      onMount(()=> {
          document.getElementById("notification").style.transition = " all 1s"
          setTimeout(() => {
            document.getElementById("notification").style.left = "1rem"
          }, 200);
      })

  </script>