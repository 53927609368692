<script>
import {
    createEventDispatcher
} from 'svelte';
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
import configs from '../../config';
import Notification from "../../components/Notification.svelte"
import AddBtn from '../../components/addButton/AddBtn.svelte';
let name, color = "#33ff22",
    forPersian = true,
    logo , publicc = false
let disabled = false
let src , colorr , messageReceived , result
const sendForm = () => {
    disabled = true
    let form = new FormData()
    if (name == undefined || color == undefined || forPersian == undefined || logo == undefined) {
        disabled = false
        return dispatch("validation", {
            "message": "مقادیر ورودی را بررسی کنید"
        })
    }

    form.append("logo", logo)
    form.append("color", color)
    form.append("name", name)
    let p = publicc ? "public": "private"
    form.append("forPersian", forPersian)
    fetch(configs.baseUrl + `api/channels/${p}/channel/insert`, {
            method: "POST",
            body: form,
            headers:{
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
        .then(async (res) => {
            disabled = false
            let code = res.status
            res = await res.json()
            console.log(res);
            if (code < 300)
               {
                   colorr = "#43ff11"
                   result = "کانال با موفقیت افزوده شد"
                   messageReceived = true
               }
            else
                {
                    colorr = "tomato"
                   result = "خطایی رخ داد"
                   messageReceived = true
                }setTimeout(() => {
                    messageReceived = false 
                }, 4000);

        })
}
let loader = false
let imageDone = 0

const dispatch = createEventDispatcher()
</script>
{#if messageReceived}
<Notification color={colorr} message= {result}></Notification>
{/if}
<div class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4" style=" margin-top: 8rem; overflow: hidden; margin-bottom: 3rem;border-radius : 16px; "   >
    <h2 style="font-weight: 600; font-size: 1.2rem ; padding: 1rem"> افزودن کانال</h2>
    <div class=" flex flex-wrap"
        style="padding: 2rem 0 ; width: 100%;min-height: 4rem; ">

        <div class="md:w-6/12 w-full p-3">
            <label for="formName">نام</label>
            <input  type="text" id="formName" bind:value="{name}"
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
        </div>
        <div class="md:w-6/12 w-full p-3">
            <div>
                <label for="formColor">رنگ</label>
                <input style="outline: none;border : 2px solid {color};color : {color} " type="text"  id="formColor"  bind:value="{color}" class="bg-gray-100 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
            </div>
            <!-- <span class="inline-block"
                style="width : 2rem;height: 2rem ; border-radius : 50% ; background-color: {color}; display: inline-block">
            </span> -->
        </div>
        <div class="md:w-6/12 w-full p-1">
            <p>زبان</p>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input type="radio" class="form-radio" name="forPersian"
                        on:change="{()=> {forPersian = true ; console.log(forPersian)}}" checked group={forPersian}>
                    <span class="ml-2">فارسی</span>
                </label>
            </div>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input type="radio" class="form-radio" name="forPersian"
                        on:change="{()=> {forPersian = false ;console.log(forPersian)}}" group={forPersian}>
                    <span class="ml-2">غیر فارسی</span>
                </label>
            </div>

        </div>
        <div class="md:w-6/12 w-full p-1">
            <p>نوع کانال</p>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input type="radio" class="form-radio" name="public"
                        on:change="{()=> {publicc = false ; console.log(publicc)}}" checked group={publicc}>
                    <span class="ml-2">خصوصی</span>
                </label>
            </div>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input type="radio" class="form-radio" name="public"
                        on:change="{()=> {publicc = true ;console.log(publicc)}}" group={publicc}>
                    <span class="ml-2">عمومی</span>
                </label>
            </div>

        </div>
        <div class="md:w-6/12 w-full p-2"></div>
        <div class="md:w-6/12 w-full p-2">
            <div class="overflow-hidden relative w-64  mb-4" style="position: relative;">
                <label id="img" style="background-color: blueviolet;float: right"
                    class=" hover:bg-indigo-dark text-white font-bold py-2 rounded  px-4 w-full items-center">
                    انتخاب عکس
                    <input style="visibility: hidden;" id="img" on:change="{e=>{ logo = e.target.files[0] ;  src = URL.createObjectURL(e.target.files[0])}}"
                    accept="image/*" class="cursor-pointer absolute block opacity-0 pin-r pin-t" type="file"
                    name="vacancyImageFiles">

                </label>
                <div class=" inline-block mt-3" style="width: 100%;
                    border: 2px dashed gray; border-radius: 8px;
                    background-image: url({src}) ;height: 16rem; background-size: cover ; " >

                </div>

                {#if imageDone == 0}
                <div class=""></div>
                {:else if imageDone == 1}
                <small>لوگو با موفقیت ارسال شد</small>
                {:else if imageDone == 2}
                <small>خطایی رخ داد</small>
                {/if}
            </div>
        </div>
        <div class="w-full p-2 mt-5">
            <!-- svelte-ignore a11y-missing-attribute -->
            <!-- <button on:click={()=> sendForm()}
                style="background-color: {disabled ? "#5511ff77": "blueviolet" }; width:100% ;  cursor: pointer;" class="rounded  text-white w-full py-2 text-center" disabled={disabled}  > ثبت کانال</button> -->
                <AddBtn  disabled={disabled} on:click={()=> sendForm()}> ثبت کانال</AddBtn>
        </div>
        
    </div>
</div>
<div class="" style="width: 100%; height: 3rem;"></div>

<style>
    @import url('https://v1.fontapi.ir/css/Estedad');
    p, div , input , label{
        font-family: Estedad, sans-serif;
        
    }
</style>