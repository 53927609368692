<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  let theAdmin = JSON.parse(localStorage.getItem("user"));
  let token = localStorage.getItem("token");
  let dispatch = createEventDispatcher();
  import configs from "../config";
    import AddBtn from "./addButton/AddBtn.svelte";
  export let external;
  let cats = [""];
  let media;

  const changeImage = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    fetch(`${configs.baseUrl}api/posts/${external._id}/changeImage`, {
      method: "PATCH",
      body: formData,
      headers: {
        authorization: `Bearer ${token}`,
        phone: theAdmin.phone,
        email: theAdmin.email,
        imei: theAdmin.imei,
      },
    })
      .then(console.log)
      .catch(console.log);
  };
  let subCategory = external.subCategory,
    src = "https://marveltradeconsult.com/" + external.image,
    title = external.title,
    category = external.category,
    createdAt = external.createdAt;

  let disabled = false;
  onMount(() => {
    fetch(`${configs.baseUrl}api/infos/getCategories`, {
      headers: {
        authorization: `Bearer ${token}`,
        phone: theAdmin.phone,
        email: theAdmin.email,
        imei: theAdmin.imei,
      },
    }).then(async (res) => {
      let d = await res.json();
      console.log(d);
      cats = ["", ...d];
    });
  });

  let sendPost = () => {
    disabled = true;

    if (
      text == "" ||
      text == null ||
      text == undefined ||
      title == "" ||
      title == undefined ||
      title == null
    ) {
      dispatch("message", {
        message: "مقادیر ورودی را بررسی کنید",
        status: 400,
      });
    } else {
      fetch(`${configs.baseUrl}api/posts/${external._id}/editPost`, {
        method: "PATCH",
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${token}`,
          phone: theAdmin.phone,
          email: theAdmin.email,
          imei: theAdmin.imei,
        },
        body: JSON.stringify({
          title,
          category,
          subCategory,
          text,
          media,
        }),
      }).then(async (res) => {
        if (res.status < 300) {
          res = await res.json();
          dispatch("message", {
            message: "پست با موفقیت به روزرسانی شد",
            status: 200,
            data: { _id: external._id, title, text, category, createdAt },
          });
        } else {
          res = await res.json();
          console.log(res);
          dispatch("message", {
            message: "خطایی رخ داد",
            status: 400,
          });
        }
        disabled = false;
      });
    }
  };
  let text;
  onMount(async () => {
    getSubCat(category);
    console.log(external);
    text = external.text;
    console.log(external);
  });
  let d = false;

  let subCats = [];

  const getSubCat = (cat) => {
    fetch(`${configs.baseUrl}api/infos/${cat}/getSubCategories`, {
      headers: {
        authorization: `Bearer ${token}`,
        phone: theAdmin.phone,
        email: theAdmin.email,
        imei: theAdmin.imei,
      },
    }).then(async (res) => {
      let data = await res.json();
      subCats = [...data];
      console.log(subCats);
    });
  };

  let medias = [];
  let theMedia = "";
  let video;

  const getMedias = (cat, subCategory) => {
    fetch(
      `${configs.baseUrl}api/medias/${cat}/${subCategory}/getMediasByCategory`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          phone: theAdmin.phone,
          email: theAdmin.email,
          imei: theAdmin.imei,
        },
      }
    ).then(async (res) => {
      let data = await res.json();
      medias = [...data];
    });
  };
  console.log(category);
</script>

<div
  class="fixed modal overflow-hidden"
  style="
min-height: 130vh;
z-index: 100;
width: 100vw;
position: fixed;
top:0;
left: 0;
background: #000000aa;
inset : 0;

"
>
<button class="hideBtn" on:click={() => dispatch("close", {})} />
  <div class="relative flex justify-center  " style="width: 100% ; height: 100%; ">
    
    <div
      class="mt-2 flex flex-wrap bg-white rounded-lg MODAL md:p-4"
      style=""
    >
      <div class="w-full md:w-6/12 p-5">
        <label for="formName">عنوان</label>
        <input
          type="text"
          id="formName"
          bind:value={title}
          class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
        />
      </div>

      <div class="w-full md:w-6/12 p-5">
        <label for="cats" style="direction: rtl !important; float: right;"
          >دسته بندی</label
        >
        <!-- svelte-ignore a11y-no-onchange -->
        <select
          name=""
          value={category}
          on:change={(e) => {
            category = e.target.value;
            console.log(e.target.value);
            getSubCat(e.target.value);
          }}
          id="cats"
          class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
        >
          {#each cats as cat}
            <option value={cat}>{cat}</option>
          {/each}
        </select>
      </div>

      <div class="w-full md:w-6/12 p-5">
        <label for="cats" style="direction: rtl !important; float: right;"
          >زیر دسته بندی</label
        >
        <!-- svelte-ignore a11y-no-onchange -->
        <select
          name=""
          value={subCategory}
          on:change={(e) => {
            subCategory = e.target.value;
            console.log(e.target.value);
            getMedias(category, e.target.value);
          }}
          id="cats"
          class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
        >
          {#each subCats as cat}
            <option value={cat}>{cat}</option>
          {/each}
        </select>
      </div>

      <div class="w-full md:w-6/12 p-5">
        <label for="scats" style="direction: rtl !important; float: right;"
          >ويديو</label
        >
        <!-- svelte-ignore a11y-no-onchange -->
        <select
          name=""
          bind:value={theMedia}
          on:change={(e) => {
            console.log(e.target.value);
          }}
          id="cats"
          class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
        >
          <option value={undefined} />
          {#each medias as media}
            <!-- {#each media as i}
              <option value="{i.link}">{media[0].link == "/media/text".split("media/")[1]}</option>
            {/each} -->
            <option value={media.link}>{media.title}</option>
            <!-- {media[0].link == "/media/text".split("media/")[0]} -->
          {/each}
        </select>
      </div>

      <div class="flex flex-col w-full md:w-6/12 p-5">

          <div class="w-fullp-5">
            <div
              class=" inline-block mt-3"
              style="width: 100%;
            border: 2px dashed gray; border-radius: 8px;
            background-image: url({src}) ;height: 16rem; background-size: cover ; "
            >
          </div>
          </div>

          <div class="w-full p-5">
            <label
              id="img"
              style="background-color: blueviolet;float: right"
              class=" hover:bg-indigo-dark text-white font-bold py-2 rounded  px-4 w-full items-center"
            >
              انتخاب عکس
              <input
                style="visibility: hidden;"
                id="img"
                on:change={(e) => {
                  changeImage(e.target.files[0]);
                  src = URL.createObjectURL(e.target.files[0]);
                }}
                accept="image/*"
                class="cursor-pointer absolute block opacity-0 pin-r pin-t"
                type="file"
                name="vacancyImageFiles"
              />
            </label>
          </div>
        </div>

        <div class="w-full p-5">
          <textarea
            name=""
            bind:value={text}
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            rows="10"
          />
        </div>
        <div class="w-full  flex justify-center items-center p-2">
          <AddBtn disabled={disabled} on:click={sendPost}> ارسال پست</AddBtn>
        </div>
        <!-- <div class="w-full md:w-6/12 p-2">
          <button
            on:click={() => dispatch("close", {})}
            style="background-color: tomato ; width:100% ; cursor:
          pointer;"
            class="rounded text-white w-full py-2 mt-3 text-center"
          >
            بستن پنجره</button
          >
        </div> -->
      </div>
    </div>

</div>

<style>

  .MODAL {
    border-radius: 16px;
    animation-name: modal;
    animation-duration: 1s;
    z-index: 110;
    inset: 0;
    position: relative;
    overflow-y: auto;
    height: 80vh;
    width: 90%;
  }

  @media screen and (max-width : 992px){
    .MODAL{
      width: 90%;
      overflow-x: hidden;
    }
  }

  @keyframes modal {
    0% {
      transform: scale(0.8) translateY(1000px);
      opacity: 0;
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
    }
  }

  .MODAL::-webkit-scrollbar {
    width: 5px;
  }

  .MODAL::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  .MODAL::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
    border-radius: 50rem;
  }

  .hideBtn{
    all: unset;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 105;
  }
</style>
