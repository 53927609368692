<script>
import {
    onMount,
    tick
} from "svelte";

import Notification from "../../components/Notification.svelte";
import Imageviewer from "../../components/Imageviewer.svelte";
// import Noimage from "../../../public/assets/img/noimage.png"
import Spinner from "../../components/Spinner.svelte"
import PaymentApprovement from "../../components/PaymentApprovement.svelte"
import {
    userPageNumber,
    totalUsers,
    totalPayments,
    paymentPageNumber,
    getPay
} from "../../store"
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
let modal = false,
    sort = "createdAt",
    sm = -1
let result, messageReceived = false,
    messageStatus
let paymentsReceived = false,
    plansReceived = false,
    userPaymentsReceived = false,
    showPlanMenu = false,
    editTable = false
let payments = [],
    plans = [],
    userPayments = [],
    textidList = []
let item = {},
    modalData
let total = 0,
    search
let modal2=false , modalData2;
let planMenu;
let paymentToEdit ; 
import configs from "../../config"
let textid = "";

import UserPaginator from "../../components/UserPaginator.svelte";
import PaymentPaginator from "../../components/PaymentPaginator.svelte";
const searchUser = () => {
    fetch(`${configs.baseUrl}api/payments/${search}/getUserPayments`,{
      headers:{  "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    })
        .then(async (res) => {
            if (res.status < 300) {
                res = await res.json()
                console.log(res);
                userPaymentsReceived = true
                userPayments = res
            } else {
                messageReceived = true
                messageStatus = 400
                result = "اطلاعاتی یافت نشد"

                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            }
        })
}
const openModal = (data, _id) => {
    modalData = {
        data,
        _id
    };
    modal = true;
}


const openModal2 = (data) => {
    modalData2 =data
    modal2 = true;
}


const deleteItem = (item) => {
    fetch(`${configs.baseUrl}api/posts/${item._id}/deletePost`, {
            method: "DELETE",
            headers:{
              "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
        .then(res => {
            if (res.status < 300) {
                messageReceived = true
                result = "پست با موفقیت حذف شد"
                messageStatus = 200
                payments = payments.filter(i => i != item)
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            } else {
                messageReceived = true
                result = "خطایی رخ داد"
                messageStatus = 400
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            }
        })
}
 
$: paymentPageNumber.subscribe(d => {
    console.log(d);
    fetchPayments(10, d , sort , sm)
  })

let pr = false
onMount(() => {
  // paymentPageNumber.subscribe(d => {
  //   console.log(d);
  //   fetchPayments(d, 10)
  // })
  pr = JSON.parse(localStorage.getItem("user")).priviliages[9]
    fetchPayments(10, 0, sort , sm)
    fetch(configs.baseUrl + "api/plans/getPlans",{
      headers: {
        "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
      }
    }).
    then(async (res) => {
        res = await res.json();
        if (res) {
            plans = [...res];
            console.log(plans);
        }

        paymentsReceived = true
    })
    fetch(configs.baseUrl + "api/plans/getPlans",{
      headers: {
        "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
      }
    }).
    then(async (res) => {
        res = await res.json();
        if (res) plans = [...res];
        console.log(res);
        plansReceived = true
    })
})

const fetchPayments = (num, skip , sort , sm) => {
    fetch(`${configs.baseUrl}api/payments/${num}/${skip}/${sort}/${sm}/getPayments`,{
      headers: {
        "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
      }
    }).then(async (res) => {
        res = await res.json()
        console.log(res);
        if (res) {
            payments = [...res.payments]
            console.log('Paymentssssssssssss' , payments);
            getPay.update(d => d=payments)
            totalPayments.update(d => d = payments)
            paymentsReceived = true
            total = res.paymentsCount
            console.log(total);
        }

    })
}
const setEditorData = (e) => {
    console.log(e);
    result = e.detail.message
    console.log(e.detail)
    let data = e.detail.data
    messageReceived = true
    messageStatus = e.detail.status
    if (messageStatus < 300) {
        let itemm = payments.find(i => i._id == data._id)
        let index = payments.indexOf(itemm)
        console.log(data.res , "DATA RES");
        if (data.res) {

                itemm.status = "APPROVED"
                if(userPayments.length > 0){
                  userPayments[0].status =   "APPROVED"

                }
                
              }
              
              else {
                 itemm.status = "REJECTED"
                 if(userPayments.length > 0){
                  userPayments[0].status =   "REJECTED"

                }
             }
        payments[index] = itemm

        payments = [...payments]
        userPayments = userPayments
    }
    modal = false

    setTimeout(() => {
        messageReceived = false
        result = ""
        messageStatus = 0

    }, 4000);

}
const doSort = (data) => {
    let temp = data.split("/")
    sort = temp[0]
    sm = +temp[1]

    paymentPageNumber.subscribe(d => {
        fetchPayments(10, d, sort, sm)
    })
}
const getPlan = (item) => {
  // console.log(plans);
    let temp = plans.find(i => i._id == item)
    // console.log(temp , plans , item);
    if (temp) return temp.name
}


  const searchTextid = () => {
      fetch(`${configs.baseUrl}api/payments/${textid}/getPaymentByTextId`,{
            headers:{  "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
        })
        .then(async (res) => {
            if (res.status < 300) {
                res = await res.json()
                console.log(res);
                // textidReceived = true
                // console.log(textidReceived);
                // textidList = []
                // console.log(textidList);
                if(res.payment.textId.length > 0)
               {   
                  userPayments=[]
                userPayments = [...userPayments,res.payment]
                userPaymentsReceived = true
                } else {
                messageReceived = true
                messageStatus = 400
                result = "اطلاعاتی یافت نشد"

                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
                }

            } else {
                messageReceived = true
                messageStatus = 400
                result = "اطلاعاتی یافت نشد"

                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            }
        })
    
  }



  onMount(()=> {
    fetch(configs.baseUrl + "api/plans/getPlans",{
        headers:{
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
        if (res) plans = [...res];
        console.log(plans);
        plansReceived = true
    })
})

const saveChanges = () => {

  fetch(configs.baseUrl + `api/payments/${paymentToEdit._id}/${paymentToEdit.phone}/editpayment`,{
        method : "PATCH",
        headers:{
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei,
                "content-type" : "application/json"

        },
        body : JSON.stringify({
          selectedPlan : planMenu
        })

    }) .then(async (res) => {
            if (res.status < 300) {

              messageReceived = true
                messageStatus = 200
                result = "ويرايش با موفقيت انجام شد"
           
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);

            } else {
                messageReceived = true
                messageStatus = 400
                result = "خطايي رخ داد"

                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            }
        })
}
</script>

  {#if messageReceived}
  {#if messageStatus > 299}
  <Notification message={result}  color = "tomato"></Notification>
  {:else}
  <Notification color="#43ff11" message={result}></Notification>
  {/if}

  {/if}

  {#if modal}
    <PaymentApprovement on:close={()=> modal = false} on:finish={setEditorData} external={modalData}  />
  {/if}
      
  {#if modal2}
      <Imageviewer external={modalData2}   on:close={()=> modal2 = false}  />
  {/if}

  <div class="w-100 bg-white p-2 rounded-lg shadow-lg" style="margin-top: 7rem ; margin-bottom : 1rem" >
    <div class="w-full  p-5">
      <label for="formName" class="w-full block" style="direction: rtl !important; float: right;">لیست تراکنش های کاربر</label>
      <div class="flex flex-col items-center md:flex-row md:justify-between  mt-5 w-full" >

          <div class="flex my-2">
            <input bind:value="{search}" placeholder="جستجو تلفن"  type="text" style="border-top-right-radius : 16px;border-bottom-right-radius : 16px;"  class="w-full border-0 shadow bg-white pl-2 text-base font-semibold outline-0" id="" />
            <button  on:click="{searchUser}" style="border-top-left-radius : 16px;border-bottom-left-radius : 16px;" class="flex bg-lightBlue-500 text-white w-10 items-center justify-center bg-white p-5">
                <svg fill="white" stroke="white" viewBox="0 0 20 20" aria-hidden="true" class="pointer-events-none absolute w-5 text-white transition">
                  <path d="M16.72 17.78a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM9 14.5A5.5 5.5 0 0 1 3.5 9H2a7 7 0 0 0 7 7v-1.5ZM3.5 9A5.5 5.5 0 0 1 9 3.5V2a7 7 0 0 0-7 7h1.5ZM9 3.5A5.5 5.5 0 0 1 14.5 9H16a7 7 0 0 0-7-7v1.5Zm3.89 10.45 3.83 3.83 1.06-1.06-3.83-3.83-1.06 1.06ZM14.5 9a5.48 5.48 0 0 1-1.61 3.89l1.06 1.06A6.98 6.98 0 0 0 16 9h-1.5Zm-1.61 3.89A5.48 5.48 0 0 1 9 14.5V16a6.98 6.98 0 0 0 4.95-2.05l-1.06-1.06Z"></path>
                </svg>
            </button>
          </div>

        
        

          <div class="flex my-2">
            <input bind:value="{textid}" placeholder="جستجو تكست آيدي"  type="text" style="border-top-right-radius : 16px;border-bottom-right-radius : 16px;"  class="w-full border-0 shadow bg-white pl-2 text-base font-semibold outline-0" id="" />
            <button disabled={textid.length != 64}  on:click="{searchTextid}" style="border-top-left-radius : 16px;border-bottom-left-radius : 16px;" class="flex bg-lightBlue-500 text-white w-10 items-center justify-center bg-white p-5">
                <svg fill="white" stroke="white" viewBox="0 0 20 20" aria-hidden="true" class="pointer-events-none absolute w-5 text-white transition">
                  <path d="M16.72 17.78a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM9 14.5A5.5 5.5 0 0 1 3.5 9H2a7 7 0 0 0 7 7v-1.5ZM3.5 9A5.5 5.5 0 0 1 9 3.5V2a7 7 0 0 0-7 7h1.5ZM9 3.5A5.5 5.5 0 0 1 14.5 9H16a7 7 0 0 0-7-7v1.5Zm3.89 10.45 3.83 3.83 1.06-1.06-3.83-3.83-1.06 1.06ZM14.5 9a5.48 5.48 0 0 1-1.61 3.89l1.06 1.06A6.98 6.98 0 0 0 16 9h-1.5Zm-1.61 3.89A5.48 5.48 0 0 1 9 14.5V16a6.98 6.98 0 0 0 4.95-2.05l-1.06-1.06Z"></path>
                </svg>
            </button>
          </div>

      </div>
     
  </div>

    <div class="mt-3 w-full p-2" style="overflow-x: auto;">
      <table class="w-full">
        <thead>
          <tr class="text-sm bg-white font-medium tracking-wide text-left text-black  uppercase border-b shadow" style=" ">
        
            <th class="px-2 py-3 text-right">تلفن</th>
            <th class="px-2 py-3 text-right">وضعیت</th>
            
            <th class="px-2 py-3 text-right"> تکست آیدی</th>
            <th class="px-2 py-3 text-right">پلن</th>
            <th class="px-2 py-3 text-right">تاریخ ثبت</th>
            <th class="px-2 py-3 text-right">ويرايش / ذخيره</th>
            <th>تاييد / رد </th>

          </tr>
        </thead>
        <tbody class="bg-white">
         {#if userPaymentsReceived && plansReceived}
         {#each userPayments as item, index}

         <tr class="text-gray-700 border-b shadow">
      

          <td class="px-4 py-3">
              <div class="flex items-center text-sm">
                <div>
                  <p class="font-semibold text-black">{item.phone}</p>   
                </div>
              </div>
          </td>
          <td class="px-4 py-3">
            <div class="flex items-center text-sm">
              <div>
                <p class="font-semibold text-black">{item.status}</p>   
              </div>
            </div>
          </td>
          <td class="px-4 py-3">
            <div class="flex items-center text-sm">
              <div>
                <p class="font-semibold text-black">{item.textId}</p>   
              </div>
            </div>
          </td>
          

            <td class="px-4 py-3">
              {#if !editTable}
                <div class="flex items-center text-sm">
                  <div>
                    <p class="font-semibold text-black">{getPlan(item.selectedPlan)}</p>   
                  </div>
                </div>
                {:else}
                  <div class="flex items-center text-sm">
                    <div class="relative mt-2 text-left">
                        <div>
                            <button type="button"
                                on:click="{()=> showPlanMenu = !showPlanMenu}"
            
                                class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                                پلن
                                <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>
            
                        {#if showPlanMenu }
                        <div class=""  style="height: auto;">
            
                            <div  style=""  class="origin-top-right absolute right-0 w-full mt-2  rounded-md shadow-lg
                                bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
            
                                <div class="py-1" role="none" style="height: auto;">
                                    {#each plans as r}
                                    <div style="cursor: pointer;"
                                        on:click="{()=> {planMenu = r._id ;showPlanMenu = false ; console.log(plans);
                                        }}" class="text-gray-700 block px-4 py-2 text-sm"
                                        role="menuitem" tabindex="-1" id="menu-item-0">{r.name}</div>
                                    {/each}
                                </div>
                            </div>
                        </div>
                        {/if}
            
                    </div>
                  </div>
                {/if}
              </td>
            <td>
            <div class="flex items-center text-sm">
              <div>
                <p class="font-semibold text-black">{new Date(Date.parse(item.createdAt)).toDateString()}</p>   
              </div>
            </div>
          </td>
          <td>
            <div class="flex justify-between items-center">
              <i class="fa-edit fas"  style="margin: 0 1rem; cursor: pointer " on:click={()=>{editTable=true;
              paymentToEdit=userPayments[0];
              }} ></i>
              <i on:click={saveChanges} class="fa fa-check" aria-hidden="true"  style="margin: 0 1rem; cursor: pointer" on:click={()=>{editTable=false}} ></i>
            </div>
          </td>

          <td>
            <div class="flex justify-between items-center">
              <button on:click={()=> openModal(true , item._id)} style="padding: .2rem .5rem; border: none ; border-radius: 8px; background: blueviolet; min-width: 3rem; color: whitesmoke; cursor : pointer">تایید</button>
              <button on:click={()=> openModal(false , item._id)} style="padding: .2rem .5rem; border: none ; border-radius: 8px; background: tomato; min-width: 3rem; color: whitesmoke; cursor : pointer">رد</button>
            </div>
          </td>

        </tr>

         {/each}
        
         {/if}

       

        </tbody>
      </table>
    </div>
</div>


  <div class=" md:mt-50 z-10 " style="padding-bottom: 2rem;">
  <div class="channel-table">
    
    <div class="" style="float:left ; margin-bottom: 1rem;">
      <div class="" style="float: left;margin-top: 1rem">
          <label for="sort" style="display: block; margin-bottom: 0.2rem; ">  مرتب سازی</label>
          <!-- svelte-ignore a11y-no-onchange -->
          <select on:change={(e)=> doSort(e.target.value)} name="" style="display: inline-block;border-radius : 16px" id="sort" class="border-0 shadow">
              <option style="text-align: right;" value="createdAt/-1">  تاریخ ثبت /  نزولی</option>
              <option style="text-align: right;" value="createdAt/1"> تاریخ ثبت  / سعودی</option>
              <option style="text-align: right;" value="expiresIn/-1"> تاریخ انقضا / نزولی</option>
              <option style="text-align: right;" value="expiresIn/1"> تاریخ انقضا / سعودی</option>
              <option style="text-align: right;" value="status/-1"> وضعیت / نزولی</option>
              <option style="text-align: right;" value="status/1"> وضعیت / سعودی</option>
              <option style="text-align: right;" value="receiver/-1"> گیرنده / نزولی</option>
              <option style="text-align: right;" value="receiver/1"> گیرنده / سعودی</option>
              <option style="text-align: right;" value="price/-1">  قیمت / نزولی</option>
              <option style="text-align: right;" value="price/1"> قیمت / سعودی</option>
              <option style="text-align: right;" value="plan/-1">  پلن / نزولی</option>
              <option style="text-align: right;" value="plan/1"> پلن / سعودی</option>
          </select>
      </div>
  </div>
      <section class="container mx-auto w-full  font-mono" style="">
          <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
            <div class="w-full overflow-x-auto">
              <table class="w-full">
                <thead>
                  <tr class="text-sm bg-white font-medium tracking-wide text-left text-black  uppercase border-b shadow" style=" ">
                    <!-- <th class="px-4 py-3 text-right">فرستنده</th>
                    <th class="px-4 py-3 text-right">گیرنده</th> -->
                    <th class="px-2 py-3 text-right">تلفن</th>
                    <th class="px-2 py-3 text-right">وضعیت</th>
                    <th class="px-2 py-3 text-right">تکست آیدی</th>
                    <th class="px-2 py-3 text-right">كد تخفيف</th>
                    <th class="px-2 py-3 text-right">عكس</th>
                    <th class="px-2 py-3 text-right">پلن</th>
                    <th class="px-2 py-3 text-right">تاریخ ثبت</th>
                    <th class="px-2 py-3 text-right">تایید / رد</th>
                  </tr>
                </thead>
                <tbody class="bg-white">
                 {#if paymentsReceived && plansReceived}
                 {#each payments as item, index}
                 {#if index < 10}
                 <tr class="text-gray-700 border-b shadow">
                  <!-- <td class="px-4 py-3 border">
                    <div class="flex items-center text-sm">
                      <div>
                        <p class="font-semibold text-black">{item.sender}</p>   
                      </div>
                    </div>
                  </td>
                  <td class="px-4 py-3 border">
                    <div class="flex items-center text-sm">
                      <div>
                        <p class="font-semibold text-black">{item.receiver}</p>   
                      </div>
                    </div>
                  </td> -->

                  <td class="px-4 py-3 ">
                      <div class="flex items-center text-sm">
                        <div>
                          <p class="font-semibold text-black">{item.phone}</p>   
                        </div>
                      </div>
                  </td>
                  
                  <td class="px-4 py-3 ">
                    <div class="flex items-center text-sm">
                      <div>
                        <p class="font-semibold text-black">{item.status}</p>   
                      </div>
                    </div>
                  </td>
                  <td class="px-4 py-3 ">
                    <div class="flex items-center text-sm">
                      <div>
                        <p class="font-semibold text-black">{item.textId}</p>   
                      </div>
                    </div>
                  </td>

                  <td class="px-4 py-3 ">
                    <div class="flex items-center text-sm">
                      <div>
                        <p class="font-semibold text-black">{item.discount}</p>
                        <p class="font-semibold text-black">{item.discountDescription}</p>   
                      </div>
                    </div>
                  </td>

                  <td class="px-4 py-3 ">
                    <div class="flex items-center text-sm">
                      <div>
                        
                      {#if item.image != undefined && item.image.length > 1}
                      <div on:click={openModal2(`http://marveltradeconsult.com${item.image}`)} style="width: 80px ; background-image:url('{`http://marveltradeconsult.com${item.image}`}') ; background-size: cover ; background-position: center  ; height : 80px ; cursor : zoom-in " ></div>
                      {:else}
                        <div  style="width: 80px ; background-image:url('/assets/img/noimage.png') ; background-size: cover ; background-position: center ; height : 80px ; cursor : zoom-in" >
                          <!-- <img src={Noimage} alt="no image" /> -->
                        </div>
                      {/if}

                      </div>
                    </div>
                  </td>

                    <td class="px-4 py-3 ">
                        <div class="flex items-center text-sm">
                          <div>
                            <p class="font-semibold text-black">{getPlan(item.selectedPlan)}</p>   
                          </div>
                        </div>
                      </td>
                    <td>
                    <div class="flex items-center text-sm">
                      <div>
                        <p class="font-semibold text-black">{new Date(Date.parse(item.createdAt)).toDateString()}</p>   
                      </div>
                    </div>
                  </td>

                   <td class="flex justify-around items-center px-4 py-3 ">
                    
                     <div class="w-100">
                      {#if item.status == "IN PROGRESS" && pr}
                     <button on:click={()=> openModal(true , item._id)} > <i style="color: green;" class="fa fa-check" aria-hidden="true"></i> </button>
                     <button on:click={()=> openModal(false , item._id)} style="color : red;font-weight : bold;font-size:18px" >X</button>

                     {/if}
                     </div>
                   </td>
                </tr>
                 {/if}
                 {/each}
                 {:else}
                   <div class="w-full" style="background-color: #343434; width: 100%">
                    <Spinner></Spinner>
                   </div>
                 {/if}
                 

                </tbody>
              </table>
            </div>
          </div>
          <PaymentPaginator total={total} ></PaymentPaginator>
        </section>
       </div>
  </div>

