<script>
import {
    onMount
} from "svelte";
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
import {
    createEventDispatcher
} from "svelte"
    import AddBtn from "../../components/addButton/AddBtn.svelte";
let dispatch = createEventDispatcher()
import Notification from "../../components/Notification.svelte"
import configs from "../../config";
let editor, title, category, image, src , subCategory;
let disabled = false

let messageStatus = 0,
    messageReceived = false,
    result = ""
let sendPost = () => {
    disabled = true

    if (text == "" || text == null ||
        text == undefined || title == "" || title == undefined || title == null) {
        dispatch("message", {
            message: "مقادیر ورودی را بررسی کنید",
            status: 400
        })
        disabled = false
    } else {
       
        let formData = new FormData()
        formData.append("text", text)
        formData.append("video", "/media/"+ theMedia)
        formData.append("category", category)
        formData.append("subCategory", subCategory)
        formData.append("title", title)
        formData.append("logo", image)
        console.log(text);
        fetch(`${configs.baseUrl}api/posts/addPost`, {
            method: "POST",
            body: formData,
            headers:{
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        }).then(async (res) => {
            messageStatus = res.status
            messageReceived = true
            disabled = false
            if (res.status < 300) {

                result = "پست با موفقیت افزوده شد"

            } else {
                result = "خطایی رخ داد"
            }
            setTimeout(() => {
                messageReceived = false
            }, 4000);
        }).catch(e => {
            disabled = false
            disabled = false;
            console.log(e)
        })
    }

}
let subCats =[]
const getSubCat =(cat)=> {
    fetch(`${configs.baseUrl}api/infos/${cat}/getSubCategories`,{
        headers: {"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    })
    .then(async (res)=> {
        let data = await res.json()
        subCats = [...data]
        console.log(subCats);
    })
}
let cats = [""]
onMount(async () => {

    fetch(`${configs.baseUrl}api/infos/getCategories`,{
        headers: {"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    })
        .then(async (res) => {
            let d = await res.json()
            console.log(d);
            cats = ["", ...d]
        })
    
        fetch(`${configs.baseUrl}api/medias/getCategories`,{
        headers: {"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
     })
        .then(async (res) => {
            let d = await res.json()
            console.log(d);
            cats = ["", ...d]
        })
});
let medias = []
let theMedia = ""
let text 
const getMedias = ()=> {
    fetch(`${configs.baseUrl}api/medias/${category}/${subCategory}/getMediasByCategory`,{
        headers: {"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
     }).then(async(res)=> {
         let data = await res.json()
         medias = [...data]
     })
}

console.log(medias)
</script>

{#if messageReceived}
{#if messageStatus > 299}
<Notification message={result}  color = "tomato"></Notification>
{:else}
<Notification color="#43ff11" message={result}></Notification>
{/if}
{/if}
<div class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4" style=" margin-top: 8rem; overflow: hidden; margin-bottom: 3rem;border-radius : 16px; "  >
    <div class="w-full md:w-6/12 p-5">
        <label for="formName" style="direction: rtl !important; float: right;">عنوان</label>
        <input type="text" id="formName" bind:value="{title}"
         class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="cats" style="direction: rtl !important; float: right;">دسته بندی</label>
        <!-- svelte-ignore a11y-no-onchange -->
        <select name="" on:change="{(e)=> {category = e.target.value ; console.log(category); getSubCat(e.target.value)
            }}" id="cats" class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
            <option value={undefined}></option>
            {#each cats as cat}
            <option value="{cat}">{cat}</option>
            {/each}
        </select>
    </div>
    
   {#if subCats.length > 0}
   <label for="scats" style="direction: rtl !important; float: right;">زیر دسته بندی</label>
   <!-- svelte-ignore a11y-no-onchange -->
   <select name="" bind:value={subCategory}
   on:change="{(e)=> { 
       getMedias()
   }}"
   id="cats" style="margin: 1rem 0;
       border-radius: 6px;
       width: 100%;">
        <option value={undefined}></option>
       {#each subCats as cat}
        <option value="{cat}">{cat}</option>
       {/each}
   </select>
   {/if}
   {#if medias.length > 0}
    <label for="scats" style="direction: rtl !important; float: right;">رسانه</label>
    <!-- svelte-ignore a11y-no-onchange -->
    <select name="" bind:value={theMedia}
    on:change="{(e)=> { console.log(e);
    }}"
    id="cats" style="margin: 1rem 0;
        border-radius: 6px;
        width: 100%;">
        <option value={undefined}></option>
        {#each medias as media}
        <option value="{media.link}">{media.title}</option>
        {/each}
    </select>
   {/if}
    <div class="w-full p-5">
        <label for="text" style="direction: rtl !important; float: right;">محتوا</label>
        <textarea name="" id="text" class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow" bind:value = {text} rows="20"></textarea>
    </div>
    <div class="w-full flex flex-col-reverse items-start mt-3">
        <div class="w-full md:w-6/12 p-5">
            <div class=" inline-block mt-3" style="width: 100%;
                border: 2px dashed gray; border-radius: 8px;
                background-image: url({src}) ;height: 16rem; background-size: cover ; " >
    
            </div>
        </div>
        <div class="w-full md:w-6/12 p-5">
            <label id="img" style="background-color: blueviolet;float: right"
                class=" hover:bg-indigo-dark text-white font-bold py-2 rounded  px-4 w-full items-center">
                انتخاب عکس
                <input style="visibility: hidden;" id="img" on:change="{e=>{ image = e.target.files[0] ;  src = URL.createObjectURL(e.target.files[0])}}"
                accept="image/*" class="cursor-pointer absolute block opacity-0 pin-r pin-t" type="file"
                name="vacancyImageFiles">
    
            </label>
        </div>
      
    </div>
    <div class="w-full p-2">
            <!-- <button on:click={sendPost} style="background-color: blueviolet; width:100% ; cursor:
                pointer;" class="rounded text-white w-full py-2 mt-3 text-center" > ارسال پست</button> -->
        <AddBtn  on:click={sendPost} disabled={disabled} > ارسال پست</AddBtn>
    </div>
</div>
{#if disabled}
<div class="flex items-center justify-center" style="width:100%; height:100%; position: fixed ; z-index: 1000 ; background: #00000060 ;top: 0; right: 0;">
    <div class="text-white font-4xl text-center">
            پست در حال ارسال میباشد 
            <br/>
            لطفا شکیبا باشید
    </div>
</div>
{/if}
<div class="w-full" style="height: 3rem;"></div>

<style>
    @import url('https://v1.fontapi.ir/css/Estedad');
    div , input , label{
        font-family: Estedad, sans-serif;
        
    }
</style>
