<script>
    export let external 
    import {createEventDispatcher} from "svelte"
import configs from "../config";
    const dispatch = createEventDispatcher();
    let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
    const paymentDone = (d)=> {
        if (d) {
            fetch(`${configs.baseUrl}api/payments/${external._id}/approvment` , {
            body: JSON.stringify({result: external.data}),
            method: "PATCH",
            headers: {"Content-Type" : "application/json",
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
        }).then(async(res) => {
           
            if (res.status < 300)
            {
               if (external.data)
               dispatch("finish" , {message : "پرداخت با موفقیت تایید شد" , status: 200,  data:{_id:  external._id , res: true, status : external.data.status }})
               else 
               dispatch("finish" , {message : "پرداخت با موفقیت رد شد" , status: 200,  data:{_id:  external._id , res: false, status : external.data.status }})
            }
            else 
            {
                dispatch("finish" , {message : "خطایی رخ داد " , status: 400 ,  data:{_id:  external._id , res: false}})
            }
        })
        } 
        else 
        {
            dispatch("close")
        }
    }
</script>
<div class="fixed modal" style="
        height: 100vh;
        z-index: 1000;
        width: 100vw;
        position: fixed;
        overflow: hidden;
        top:0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #000000aa;
        ">
    <div style="width: 20rem ; height: 12rem; position: relative; padding:  1.5rem ; background-color: #fff; border-radius: 10px;">

            {#if external.data}
                <div class=" mt-6 "  style="font-size: 1.2rem;">
                    آیا از تایید تراکنش اطمینان دارید ؟
                </div>
            {:else}
            <div class=" mt-6 "  style="font-size: 1.2rem;">
                آیا از رد تراکنش اطمینان دارید ؟
            </div>
            {/if}
            <div class="mt-5">
                <button on:click={()=> paymentDone(true)} style="padding: .6rem .5rem; border: none ; border-radius: 8px; background: blueviolet; min-width: 47%; color: whitesmoke; cursor : pointer">بله</button>
                <button on:click={()=> paymentDone(false)} style="padding: .6rem .5rem; border: none ; border-radius: 8px; background: tomato; min-width: 47%; color: whitesmoke; cursor : pointer">خیر</button>
            </div>

    </div>


</div>