<script>
  import { onMount } from "svelte";
  import configs from "../config";
  import Spinner from "./Spinner.svelte";
  import { createEventDispatcher } from "svelte";
    import AddBtn from "./addButton/AddBtn.svelte";
  const dispatch = createEventDispatcher();
  let t = [],
    e = "",
    ep = "";
  export let channelsName,
    externalData = {};
  let theAdmin = JSON.parse(localStorage.getItem("user"));
  let token = localStorage.getItem("token");
  let roomMenu,
    showRoomMenu = false,
    src = configs.baseUrl + externalData.data;
  console.log(src);
  let file;
  let {
    type,
    entry,
    data,
    caption,
    symbol,
    coin,
    externalLink,
    active,
    targets,
    publishedIn,
    isFollowable,
    _id,
    exitPoint,
  } = externalData;
  if (exitPoint == undefined) {
    exitPoint = 0;
  }

  const sendSignal = () => {
    if (targets && targets.length > 0) {
      t = targets.map((item) => item.toString());
    }

    if (isFollowable) {
      e = entry.toString();
      ep = exitPoint.toString();
      console.log(t, ep);
    }

    if (type == "text") {
      dispatch("data", {
        data: {
          type,
          exitPoint: ep,
          entry: e,
          data,
          caption,
          symbol,
          active,
          coin,
          targets: t,
          publishedIn,
          isFollowable,
          _id,
        },
      });
    } else {
      dispatch("data", {
        data: {
          type,
          exitPoint: ep,
          entry: e,
          caption,
          symbol,
          active,
          coin,
          targets: t,
          publishedIn,
          isFollowable,
          _id,
        },
      });
    }

    // let form = new FormData()
    //   if (coin == undefined || symbol == undefined || publishedIn == undefined || caption == undefined) {
    //       missingData = true
    //       setTimeout(() => {
    //           messageReceived = false
    //           messageStatus = missingMessage
    //       }, 4000);
    //       return
    //   }
    //   form.append("type", type)
    //   form.append("isFollowable", isFollowable)
    //   form.append("caption", caption)
    //   form.append("coin", coin)
    //   form.append("symbol", symbol)
    //   form.append("exitPoint", exitPoint)
    //   form.append("externalLink", externalLink)
    //   form.append("entry", entry)
    //   let t = [] , e = "" , ep = ""

    //    if(targets.length >0)
    //    {
    //        t = targets.map(item => item.toString())

    //    }

    //   if (isFollowable) {

    //       form.append("targets", t)
    //       e = entry.toString()
    //       ep = exitPoint.toString()
    //       console.log(t , entry , exitPoint);
    //   }
    //   if (type == "text") {
    //       if (data == null || data == undefined) {

    //           dispatch("mismatch")

    //           return
    //       }
    //       form.append("data", data)

    //       let finalData = JSON.stringify({
    //           type,
    //           isFollowable: isFollowable,
    //           caption,
    //           coin,
    //           symbol,
    //           externalLink,
    //           entry : e,
    //           data,
    //           targets: t,
    //           exitPoint: ep,
    //       })
  };
  let loading = false;
  const editImage = (e) => {
    loading = true;
    let f = new FormData();
    f.append("file", e.target.files[0]);
    fetch(configs.baseUrl + "api/signals/" + _id + "/editVoice", {
      method: "PATCH",
      body: f,
      headers: {
        authorization: `Bearer ${token}`,
        phone: theAdmin.phone,
        email: theAdmin.email,
        imei: theAdmin.imei,
      },
    }).then(async (res) => {
      res = await res.json();
      console.log(res);
      loading = false;
    });
    src = URL.createObjectURL(e.target.files[0]);
  };
  const changeVoice = (e) => {
    loading = true;
    let f = new FormData();
    f.append("file", e.target.files[0]);
    fetch(configs.baseUrl + "api/signals/" + _id + "/editVoice", {
      method: "PATCH",
      body: f,
      headers: {
        authorization: `Bearer ${token}`,
        phone: theAdmin.phone,
        email: theAdmin.email,
        imei: theAdmin.imei,
      },
    }).then(async (res) => {
      res = await res.json();
      console.log(res);
      loading = false;
    });
    src = URL.createObjectURL(e.target.files[0]);
  };
  let isLoaded = false;
  onMount(() => {
    isLoaded = true;
  });
</script>

{#if isLoaded}
<div
  class="fixed modal overflow-hidden"
  style="
min-height: 130vh;
z-index: 100;
width: 100vw;
position: fixed;
top:0;
left: 0;
background: #000000aa;
inset : 0;

"
>
<button class="hideBtn" on:click={() => dispatch("close", {})} />
  <div class="relative flex justify-center  " style="width: 100% ; height: 100%; ">
    
    <div
      class="mt-2 flex flex-wrap bg-white rounded-lg MODAL md:p-4"
      style=""
    >
        <div class="w-full md:w-6/12 p-5">
          <label for="coin">قابلیت فالوو </label>
          <div class="w-full">
            <div style="display: inline-block;">
              <label class="inline-block items-center">
                <input
                  type="radio"
                  class="form- export let location"
                  name="isFollowable"
                  on:change={() => {
                    isFollowable = true;
                    console.log(isFollowable);
                  }}
                  checked={isFollowable}
                  group={isFollowable}
                />
                <span class="ml-2">دارد</span>
              </label>
            </div>
            <div style="display: inline-block;">
              <label class="inline-block items-center">
                <input
                  type="radio"
                  class="form- export let location"
                  name="isFollowable"
                  on:change={() => {
                    isFollowable = false;
                    console.log(isFollowable);
                  }}
                  checked={!isFollowable}
                  group={isFollowable}
                />
                <span class="ml-2">ندارد</span>
              </label>
            </div>
          </div>
        </div>
        <div class="w-full md:w-6/12 p-5">
          <label for="type">نوع سیگنال</label>
          <div class="w-full">
            <div style="display: inline-block;">
              <label class="inline-block items-center">
                <input
                  type="radio"
                  class="form- export let location"
                  name="type"
                  on:change={() => {
                    type = "text";
                    console.log(type);
                  }}
                  checked={type == "text"}
                  group={type}
                />
                <span class="ml-2">متنی</span>
              </label>
            </div>
            <div style="display: inline-block;">
              <label class="inline-block items-center">
                <input
                  type="radio"
                  class="form- export let location"
                  name="isFollowable"
                  on:change={() => {
                    type = "image";
                    console.log(type);
                  }}
                  checked={type == "image"}
                  group={type}
                />
                <span class="ml-2">عکس</span>
              </label>
            </div>
            <div style="display: inline-block;">
              <label class="inline-block items-center">
                <input
                  type="radio"
                  class="form- export let location"
                  name="isFollowable"
                  on:change={() => {
                    type = "voice";
                    console.log(type);
                  }}
                  checked={type == "voice"}
                  group={type}
                />
                <span class="ml-2">صوت</span>
              </label>
            </div>
            <div style="display: inline-block;">
              <label class="inline-block items-center">
                <input
                  type="radio"
                  class="form- export let location"
                  name="isFollowable"
                  on:change={() => {
                    type = "link";
                    console.log(type);
                  }}
                  checked={type == "link"}
                  group={type}
                />
                <span class="ml-2">لينك</span>
              </label>
            </div>
          </div>
        </div>

        <div class="w-full md:w-6/12 p-5">
          <label for="coin">کوین</label>
          <input
            type="text"
            id="coin"
            bind:value={coin}
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
          />
        </div>
        <div class="w-full md:w-6/12 p-5">
          <label for="symbol">نماد کوین</label>
          <input
            type="text"
            id="symbol"
            bind:value={symbol}
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
          />
        </div>
        <div class="w-full md:w-6/12 p-5">
          <label for="symbol">کپشن</label>
          <input
            type="text"
            id="symbol"
            bind:value={caption}
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
          />
        </div>
        <!-- <div class="w-full md:w-6/12 p-5">
      <label for="coin">وضعيت سيگنال</label>
      <div class="w-full">
          <div style="display: inline-block;">
              <label class="inline-block items-center">
                  <input type="radio" class="form- export let location" name="active"
                      on:change="{()=> {active = true ; console.log(active)}}" checked ={active} group={active}>
                  <span class="ml-2">فعال</span>
              </label>
          </div>
          <div style="display: inline-block;">
              <label class="inline-block items-center">
                  <input type="radio" class="form- export let location" name="active"
                      on:change="{()=> {active = false ;console.log(active)}}"checked ={!active} group={active}>
                  <span class="ml-2">غير فعال</span>
              </label>
          </div>
      </div>
    </div> -->

        <div class="w-full  p-5">
          <label for="">انتخاب کانال</label>
          <div class="relative mt-2 text-left">
            <div>
              <button
                type="button"
                on:click={() => (showRoomMenu = !showRoomMenu)}
                class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                کانال
                <svg
                  class="-mr-1 ml-2 h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {#if showRoomMenu}
              <div class="">
                <div
                  bind:this={roomMenu}
                  class="origin-top-right absolute right-0 w-full mt-2  rounded-md shadow-lg
                 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                  tabindex="-1"
                >
                  <div class="py-1" role="none">
                    {#each channelsName as r}
                      <div
                        style="cursor: pointer;"
                        on:click={() => {
                          publishedIn = r._id;
                          console.log(publishedIn);
                          showRoomMenu = false;
                        }}
                        class="text-gray-700 block px-4 py-2 text-sm"
                        role="menuitem"
                        tabindex="-1"
                        id="menu-item-0"
                      >
                        {r.name}
                      </div>
                    {/each}
                  </div>
                </div>
              </div>
            {/if}
          </div>
        </div>

        <div class="w-full p-5">
          {#if type == "text"}
            <label for="symbol">محتوا</label>
            <textarea
              id="symbol"
              style="min-height: 7rem"
              bind:value={data}
              class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            />
          {:else if type == "image"}
            <div class="flex flex-wrap ">
              <div class="w-full md:w-6/12 " style="height: 4rem;">
                <div
                  class="overflow-hidden relative w-64  "
                  style="position: relative;margin-top: 2rem"
                >
                  <label
                    id="img"
                    style="background-color: blueviolet;float: right"
                    class=" hover:bg-indigo-dark text-center text-white font-bold py-2 rounded  px-4 w-full items-center"
                  >
                    انتخاب عکس
                    <input
                      style="visibility: hidden;"
                      id="img"
                      on:change={(e) => {
                        editImage(e);
                      }}
                      accept="image/*"
                      class="cursor-pointer absolute block opacity-0 pin-r pin-t"
                      type="file"
                      name="vacancyImageFiles"
                    />
                  </label>
                </div>
              </div>
              <div class="w-full md:w-6/12 p-5 " style="height: 16rem;">
                {#if !loading}
                  <div
                    class=" inline-block mt-3"
                    style="width: 100%;
              border: 2px dashed gray; border-radius: 8px;
              background-image: url({src}) ; height: 100%; background-size: cover ;background-repeat: round; "
                  />
                {:else}
                  <div
                    class=""
                    style="width: 100%; height: 100; background-color: #343434; display: flex;justify-content: center;align-items: center;"
                  >
                    <Spinner />
                  </div>
                {/if}
              </div>
            </div>
          {:else if type == "voice"}
            <div class="flex flex-wrap ">
              <div class="w-full md:w-6/12 " style="height: 5rem;">
                <div
                  class="overflow-hidden relative w-64  "
                  style="position: relative;margin-top: 2rem"
                >
                  <label
                    id="img"
                    style="background-color: blueviolet;float: right"
                    class=" hover:bg-indigo-dark text-center text-white font-bold py-2 rounded  px-4 w-full items-center"
                  >
                    انتخاب صوت
                    <input
                      style="visibility: hidden;"
                      id="img"
                      on:change={(e) => {
                        changeVoice(e);
                      }}
                      accept="audio/*"
                      class="cursor-pointer absolute block opacity-0 pin-r pin-t"
                      type="file"
                      name="vacancyImageFiles"
                    />
                  </label>
                </div>
              </div>
              <div class="w-full md:w-6/12 p-5 " style="height: 5rem;">
                <!-- svelte-ignore a11y-media-has-caption -->
                {#if !loading}
                  <audio controls class="w-full mt-2" {src}>
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                {:else}
                  <div
                    class=""
                    style="width: 100%; height: 100; background-color: #343434; display: flex;justify-content: center;align-items: center;"
                  >
                    <Spinner />
                  </div>
                {/if}
              </div>
            </div>
          {/if}
        </div>

        {#if isFollowable}
          <div class="w-full md:w-6/12 p-5">
            <label for=""> تعداد هدف ها </label>
            <input
              type="number"
              id=""
              bind:value={targets.length}
              class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            />
          </div>
          <div class="w-full md:w-6/12 p-5">
            {#if targets.length > 0}
              <label for="goals"> اهداف </label>
            {/if}
            {#each targets as _a, i}
              <input
                type="number"
                id="goals"
                bind:value={targets[i]}
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
              />
            {/each}
          </div>

          <div class="w-full md:w-6/12 p-5">
            <label for=""> نقطه ورود </label>
            <input
              type="number"
              id=""
              bind:value={entry}
              class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            />
          </div>

          <div class="w-full md:w-6/12 p-5">
            <label for=""> نقطه خروج </label>
            <input
              type="number"
              id=""
              bind:value={exitPoint}
              class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            />
          </div>
        {/if}
        <div class="w-full p-2">
          <AddBtn on:click={sendSignal} >ارسال</AddBtn>
        </div>
        
      </div>
    </div>
  </div>
{/if}


<style>
  .MODAL {
    border-radius: 16px;
    animation-name: modal;
    animation-duration: 1s;
    z-index: 110;
    inset: 0;
    position: relative;
    overflow-y: auto;
    height: 80vh;
    width: 90%;
  }

  @media screen and (max-width : 992px){
    .MODAL{
      width: 90%;
      overflow-x: hidden;
    }
  }

  @keyframes modal {
      0% {
  transform:scale(.8) translateY(1000px);
  opacity:0;
}
100% {
  transform:scale(1) translateY(0px);
  opacity:1;
}
  }

  .MODAL::-webkit-scrollbar {
      width: 5px;
  }
  
  .MODAL::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  
  .MODAL::-webkit-scrollbar-thumb {
  background-color: #dcdcdc;
  border-radius: 16px;
  }

  .hideBtn{
  all: unset;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

</style>