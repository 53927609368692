<script>
import {
    onMount
} from "svelte";
import configs from "../../config";
import Modal from "../../components/ChannelModal.svelte";
import Add from "../../components/ChannelAdd.svelte";
import {
    persianChannels,
    englishChannels
} from "../../store"
import Notification from "../../components/Notification.svelte";
import Spinner from "../../components/Spinner.svelte"
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
let channels = [];
let isThereNotify = false
let persiansReceived = false
let englishsReceived = false
let modalData = {}
let isOpen = false,
    canAdd = false
let editResult = false
const openModal = (data) => {
    modalData = data
    isOpen = true
}
const closeModal = () => {
    modalData = {}
    isOpen = false
}
const changeImage = (e) => {
    let item = channels.find(item => item._id == e.detail.id)
    let index = channels.indexOf(item)
    item.logo = e.detail.value
    channels[index] = item
}
const finished = (e) => {
    console.log(e.detail.data);
    if (e.detail.message == "done") {
        let item = channels.find(item => item._id == e.detail.data.id)
        let index = channels.indexOf(item)
        item.name = e.detail.data.name
        item.forPersian = e.detail.data.forPersian
        item.color = e.detail.data.color
        channels[index] = item
        editResult = true
    }
    isThereNotify = true
    closeModal()
    setTimeout(() => isThereNotify = false, 3000)
}
let pr = false
onMount(async () => {
    pr = JSON.parse(localStorage.getItem("user")).priviliages[3]
    fetch(configs.baseUrl + "api/channels/public/getchannels/english",{
        headers:{
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).then(async (res) => {
        res = await res.json()
        englishChannels.update(d => d = res.data)
        if (res.data && res.data.length > 0)
            channels = [...channels, ...res.data]
        englishsReceived = true

    })
    fetch(configs.baseUrl + "api/channels/public/getchannels/persian",{
        headers: {
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).then(async (res) => {
        res = await res.json()
        persianChannels.update(d => d = res.data)
        if (res.data && res.data.length > 0)
            channels = [...channels, ...res.data]
        persiansReceived = true
        console.log(channels);
    })
})
let isFormValid = true
let validationMessage = ""
const checkFormValidation = (e) => {
    isFormValid = false
    validationMessage = e.detail.message
    setTimeout(() => {
        isFormValid = true
        console.log(isFormValid);
    }, 4000);
}
let isPostAdded = false
const addResult = (e) => {
    if (e.detail.message = "done") {
        isPostAdded = true
        channels = [...channels, e.detail.data]
    }
}
let isPostDeleted = false
let postNotify = false
const deleteItem = (item) => {
    fetch(`${configs.baseUrl}api/channels/public/${item._id}/delete`, {
            method: "DELETE",
            headers: {
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
        .then(async (res) => {

            if (res.status < 300) {
                channels = channels.filter(i => i._id != item._id)
                channels = channels
                isPostDeleted = true
            }
            postNotify = true
            setTimeout(() => {
                postNotify = false
            }, 4000);
        })
}

const toggelAddModal = () => {
    canAdd = !canAdd
}
</script>

{#if isOpen}
<Modal on:image={changeImage} on:finished={finished} on:close={closeModal}  data={modalData}></Modal>
{/if}
{#if isThereNotify}
{#if !editResult}
<Notification color="tomato" message= "خطایی رخ داد"></Notification>
{:else}
<Notification color="#43ff11" message= "عملیات موفقیت آمیز بود"></Notification>
{/if}
{/if}
{#if !isFormValid}
<Notification color="tomato" message= {validationMessage}></Notification>
{/if}
{#if postNotify}
{#if isPostDeleted}
<Notification color="#43ff11" message="کانال با موفیت حذف شد"></Notification>
{:else}
<Notification color="tomato" message= "خطایی رخ داد"></Notification>
{/if}
{/if}
{#if isPostAdded}
<Notification color="#43ff11" message= "کانال با موفقیت افزوده شد"></Notification>
{/if}
<div class="  z-10 " style="padding-bottom: 2rem; margin-top: 8rem">
    <div class="channel-table">
     
        <section class="container mx-auto w-full  font-mono" style="">
            <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                <div class="w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="text-sm bg-white font-medium tracking-wide text-left text-black  uppercase border-b shadow" style=" ">
                                <th class="px-2 py-3 text-right">نام</th>
                                <th class="px-2 py-3 text-right">رنگ</th>
                                <th class="px-2 py-3 text-right"> سیگنال ها</th>
                                <th class="px-2 py-3 text-right">زبان</th>
                                <th class="px-2 py-3 text-right">حذف / بازنویسی</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            {#if persiansReceived && englishsReceived}
                            {#each channels as item}
                            <tr class="text-gray-700 border-b shadow">
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div class="relative w-8 h-8 mr-3 rounded-full md:block">
                                            <img class="object-cover w-full h-full rounded-full" src={`${configs.baseUrl + item.logo}`} alt="" loading="lazy" />
                                            <div class="absolute inset-0 rounded-full shadow-inner" aria-hidden="true"></div>
                                        </div>
                                        <div>
                                            <p class="font-semibold text-black">{item.name}</p>

                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 text-ms font-semibold ">
                                    <div class="ml-6 inline-block" style="width: 15px; height: 15px;background-color: {item.color};"></div>
                                    <p class="inline-block">{item.color}</p>
                                </td>
                                <td class="px-4 py-3 text-xs ">
                                    <p class=""> {item.totalSignals} </p>
                                </td>
                                {#if item.forPersian}
                                <td class="px-4 py-3 text-sm ">فارسی</td>
                                {:else}
                                <td class="px-4 py-3 text-sm ">غیر فارسی</td>
                                {/if}

                                <td class=" " style="padding: 0 1rem">
                                    <i class="fa-edit fas"  style="margin: 0 1rem; cursor: pointer " on:click={openModal(item)} ></i>
                                    <i class="fa-trash fas text-red-500"  style="margin: 0 1rem; cursor: pointer" on:click={deleteItem(item)} ></i>
                                </td>
                            </tr>
                            {/each}
                            {:else}
                            <div class="w-full" style="background-color: #343434; width: 100%">
                                <Spinner></Spinner>
                            </div>
                            {/if}

                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
</div>

<style>
    @import url('https://v1.fontapi.ir/css/Estedad');
    p  , table , tr , th , td , div {
        font-family: Estedad, sans-serif;
        
    }
</style>