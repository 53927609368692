<script>
  import {
    onMount, tick
  } from "svelte";
  import SignalEdit from "../../components/SignalEdit.svelte";
  import Editor from "../../components/Editor.svelte";
  import Notification from "../../components/Notification.svelte";
  import Spinner from "../../components/Spinner.svelte"
  import EditorModal from "../../components/EditorModal.svelte"
  import {postPageNumber, totalPosts} from "../../store"
  let result, messageReceived = false,
    messageStatus
  let postReceived = false,
    modal = false
  let posts = []
  let item = {}
  let total = 0  , addPost = false 
  let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
  const toggleAddPost = ()=> addPost = !addPost




import configs from "../../config"
import Paginator from "../../components/Paginator.svelte";
  const openModal = (data) => {item = data ; modal = true ;}
  const deleteItem = (item) => {
    fetch(`${configs.baseUrl}api/posts/${item._id}/deletePost`, {
        method: "DELETE",
        headers:{
          "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
      })
      .then(res => {
        if (res.status < 300) {
          messageReceived = true
          result = "پست با موفقیت حذف شد"
          messageStatus = 200
          posts = posts.filter(i => i != item)
          setTimeout(() => {
            messageReceived = false
            result = ""
            messageStatus = 0
          }, 4000);
        } else {
          messageReceived = true
          result = "خطایی رخ داد"
          messageStatus = 400
          setTimeout(() => {
            messageReceived = false
            result = ""
            messageStatus = 0
          }, 4000);
        }
      })
  }
  let sort = "createdAt" , sm = -1 , pr = false 
  onMount(() => {
    postPageNumber.subscribe(d=> {
    fetchPosts(10 , d , sort , sm) 
    pr = JSON.parse(localStorage.getItem("user")).priviliages[5]
    console.log(pr);
  })
  })
let totals = 0
  const fetchPosts = (num , skip , sort , sm) => {
    fetch(`${configs.baseUrl}api/posts/${num}/${skip}/${sort}/${sm}/all/getPosts`,{
      headers:{
        "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
      }
    }).then(async (res) => {
      if(res.status > 299) {
        res = await res.json()
        posts = []
        totalPosts.update(d => d = 0)
        return console.log(res)
      }
      res = await res.json()
      posts = res.posts
      console.log(res);
        if(res.totalPosts && res.totalPosts > 0)
        {
          totalPosts.update(d => d = res.totalPosts)
          totals = res.totalPosts
        }
        else {
          totalPosts.update(d => d = 0)
          totals = 0
        }
      postReceived = true
      
    }).catch(console.error)
  }
  const setEditorData = (e) => {

    result = e.detail.message
    let data = e.detail.data
    messageReceived = true
    messageStatus = e.detail.status
    if (messageStatus < 300 && !modal ) {
   
      posts = [e.detail.data , ...posts]
    } else if (messageStatus < 300 && modal)
    {
      
      let i = posts.find(itemm => itemm._id == data._id)
      let index = posts.indexOf(i)
      posts[index] = e.detail.data
      posts = [...posts]
     
    }

    modal = false
    setTimeout(() => {
      messageReceived = false
      result = ""
      messageStatus = 0
       
    }, 4000);


  }
  const doSort = (data) => {
    let temp = data.split("/")
    sort = temp[0]
    sm = +temp[1]

    postPageNumber.subscribe(d => {
        fetchPosts(10, d, sort, sm)
    })

    
}

let getSignal = [] ;
    const getSignalEdit = (event) => {
      getSignal = event.detail;
    }
</script>
{#if messageReceived}
{#if messageStatus > 299}
<Notification message={result}  color = "tomato"></Notification>
{:else}
<Notification color="#43ff11" message={result}></Notification>
{/if}

{/if}
{#if modal}
  <EditorModal on:close={()=> modal = false} on:message={setEditorData} external={item}></EditorModal>
{/if}
<div class=" md:mt-50 z-10 " style="padding-bottom: 2rem;">
 <div class="w-full">
  
<div class="" style="float:left ; margin-bottom: 1rem;margin-top: 7rem">
  <div class="" style="float: left;">
      <label for="sort" style="display: block; margin-bottom: 0.2rem; ">  مرتب سازی</label>
      <!-- svelte-ignore a11y-no-onchange -->
      <select on:change={(e)=> doSort(e.target.value)} name="" style="display: inline-block;border-radius : 16px" id="sort" class="border-0 shadow">
          <option style="text-align: right;" value="createdAt/-1">  تاریخ ثبت /  نزولی</option>
          <option style="text-align: right;" value="createdAt/1"> تاریخ ثبت  / سعودی</option>
          <option style="text-align: right;" value="category/-1"> دسته بندی   / نزولی</option>
          <option style="text-align: right;" value="category/1"> دسته بندی   / سعودی</option>
          <option style="text-align: right;" value="title/-1"> عنوان / نزولی</option>
          <option style="text-align: right;" value="title/1"> عنوان / سعودی</option>
          <option style="text-align: right;" value="content/-1"> محتوا  / نزولی</option>
          <option style="text-align: right;" value="content/1"> محتوا  / سعودی</option>
         
      </select>
  </div>
</div>
 </div>
<div class="channel-table">
    <h2 class="mb-3 " style="font-weight: 600; font-size: 1.2rem ; padding: 2rem 0">لیست پست ها</h2>
    <section class="container mx-auto w-full  font-mono" style="">
        <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
          <div class="w-full overflow-x-auto">
            <table class="w-full">
              <thead>
                <tr class="text-sm bg-white font-medium tracking-wide text-left text-black  uppercase border-b shadow" style=" ">
                  <th class="px-2 py-3 text-right">عنوان</th>
                  <th class="px-2 py-3 text-right">دسته بندی</th>
                  <th class="px-2 py-3 text-right">زیر دسته بندی</th>
                  <th class="px-2 py-3 text-right">تاریخ ایجاد</th>
                  <th class="px-2 py-3 text-right">حذف / بازنویسی</th>
                </tr>
              </thead>
              <tbody class="bg-white">
               {#if postReceived && totals > 0}
               {#each posts as item, index}
               {#if index < 10}
               <tr class="text-gray-700 border-b shadow">
                <td class="px-4 py-3 ">
                  <div class="flex items-center text-sm">
                    <div>
                      <p class="font-semibold text-black">{item.title}</p>   
                    </div>
                  </div>
                </td>
                <td class="px-4 py-3 ">
                  <div class="flex items-center text-sm">
                    <div>
                      <p class="font-semibold text-lightBlue-400">{item.category}</p>   
                    </div>
                  </div>
                </td>
                <td class="px-4 py-3 ">
                  <div class="flex items-center text-sm">
                    <div>
                      <p class="font-semibold text-black">{item.subCategory}</p>   
                    </div>
                  </div>
                </td>
                <td class="px-4 py-3 ">
                  <div class="flex items-center text-sm">
                    <div>
                      <p class="font-semibold text-black">{item.createdAt}</p>   
                    </div>
                  </div>
                </td>
                
                <td class=" " style="padding: 0 1rem">
                  {#if pr}
                     <!-- content here -->
                     <i class="fa-edit fas"  style="margin: 0 1rem; cursor: pointer " on:click={openModal(item)} ></i>
                     <i class="fa-trash fas text-red-500"  style="margin: 0 1rem; cursor: pointer" on:click={deleteItem(item)} ></i>
                  {/if}
                </td>
              </tr>
               {/if}
               {/each}
               {:else}
                 <div class="w-full" style="background-color: #343434; width: 100%">
                  <Spinner></Spinner>
                 </div>
               {/if}
               
             
              </tbody>
            </table>
          </div>
        </div>
        <Paginator total={total} ></Paginator>
      </section>
      
</div>
</div>