<script lang="ts">
  // core components
  import CardLineChart from "../../components/Cards/CardLineChart.svelte";
  import CardBarChart from "../../components/Cards/CardBarChart.svelte";
  import CardPageVisits from "../../components/Cards/CardPageVisits.svelte";
  import CardSocialTraffic from "../../components/Cards/CardSocialTraffic.svelte";
  import Spinner from "../../components/Spinner.svelte";
import { onMount } from "svelte";
import configs from "../../config";
import {users , signals , chartItems , posts , payments} from "../../store";
  let location;
  let usrs = {}
  let signls = {}
  let psts = {}
  let pyments = {}
  let canShare = false 
  let items = []
  let barItems = {}
  let exp = 0, nexp = 0, actives = 0 , notActives = 0
  onMount(()=> {

      users.subscribe(d=> {
        let userlabels = []
        let usercounts = []
        
        if (d){
           
        d.forEach((item) => {
          
          
          let dd = Date.parse(item.createdAt)
          let date  = new Date(dd)
      
          
          let creationDate = date.getFullYear() + "/" + (date.getUTCMonth() + 1)
          if (userlabels.indexOf(creationDate) == -1) {
            userlabels.push(creationDate)
            usercounts[usercounts.length] = 1 
          } 
          else 
          {
            usercounts[userlabels.indexOf(creationDate)]++
          }
          let expIn = Date.parse(item.expiresIn)
  
          
          if(expIn < Date.now()) {
           
            exp++
          }
          else nexp++
          
          
        })
        }
        
        usrs = {labels:userlabels, counts:usercounts, enname: "users", name : "کاربران" , title: " تعداد کاربران جدید در هر ماه"}
        items[0] = usrs
      })
      signals.subscribe(d=> {
        let labels = []
        let counts = []
        
        if(d){
          
        d.forEach((item) => {
          let dd = Date.parse(item.createdAt)
          let date  = new Date(dd)
          let creationDate = date.getFullYear() + "/" +(date.getUTCMonth() + 1)
          if (labels.indexOf(creationDate) == -1) {
            labels.push(creationDate)
            counts[counts.length] = 1 
          } 
          else 
          {
            counts[labels.indexOf(creationDate)]++
          }
          if(item.active) actives++ 
          else notActives++
        })
        }
        signls = {mainLabels:["اعتبار حساب"], labels, counts ,enname:"signals", name : "سیگنال ها" , title: " تعداد سیگنال های جدید در هر ماه"}
        
        items[1] = signls

      })


      
      payments.subscribe(d=> {
        let labels = []
        let counts = []
        
        if(d){
          d.forEach((item) => {
          let dd = Date.parse(item.createdAt)
          let date  = new Date(dd)
          let creationDate = date.getFullYear() + "/" +(date.getUTCMonth() + 1)
          if (labels.indexOf(creationDate) == -1) {
            labels.push(creationDate)
            counts[counts.length] = 1 
          } 
          else 
          {
            counts[labels.indexOf(creationDate)]++
          }
          if(item.active) actives++ 
          else notActives++
        })
        }
        pyments = {mainLabels:["تراکنش ها"], labels, counts ,enname:"payments", name : "تراکنش ها" , title: " تعداد تراکنش های جدید در هر ماه"}
        
        items[3] = pyments

      })



      posts.subscribe(d=> {
        let postlabels = []
        let postcounts = []
    
    
       if (d){
        d.forEach((item) => {
          let dd = Date.parse(item.createdAt)
          let date  = new Date(dd)
          let creationDate = date.getFullYear() + "/" + (date.getUTCMonth() + 1)
          if (postlabels.indexOf(creationDate) == -1) {
            postlabels.push(creationDate)
            postcounts[postcounts.length] = 1 
          } 
          else 
          {
            postcounts[postlabels.indexOf(creationDate)]++
          }
        })
       }
        psts = {labels: postlabels, counts: postcounts ,enname:"posts", name : "پست ها" , title: " تعداد پست های جدید در هر ماه"}
        
        
        
        items[2] = psts
      })
      
      setTimeout(() => {
         let base = items[0].labels
          let temps = []
        
         for (let index = 0; index < items.length; index++) {
           
            if(items[index].labels.length != base.length) {
               let dif = base.length - items[index].labels.length   
              for (let i = 0; i < dif; i++) {
                items[index].labels.push("!")
                items[index].counts.push(0)
               
              }
            }

            for (let j = 0; j < base.length; j++) {
             let temp = {}
            
             
                if(items[index].labels[j] == "!")
                  {
                    
                    items[index].labels[j] = base[j]
                    items[index].counts[j] = 0
                  
                    
                  } 
                  else if (items[index].labels[j] != base[j]) 
                  {
                      let ind = base.indexOf(items[index].labels[j])
                      items[index].labels[j] = base[j]
                      items[index].labels[ind] = base[ind]
                      items[index].counts[ind] = items[index].counts[j] 
                      items[index].counts[j] = 0
                  }
           }

            if(index == items.length - 1)
             {
           
                 
               barItems = {counts : [[exp , notActives] , [nexp , actives]] , colors : ["#6633dd" ,"#dd7845"] , labels: ["منقصی شده" , "فعال"] , mainLabels: ["کاربران " , "سیگنال ها "] , title: "فعالیت ها"}

               canShare = true}
         }
    
      

        
      }, 6000);
      
     
      
    //  chartItems.update(data => data = items)
     
  })
</script>

<div>
  <div class="flex flex-wrap">
    {#if canShare}
    <div class="w-full  mb-12 xl:mb-0 px-4">
      <!-- svelte-ignore missing-declaration -->
      <CardLineChart items={items}/>
    </div> 
    {:else}
    <!-- svelte-ignore missing-declaration -->
    <div class="" style=" background-color: #334155; width: 95% ; margin: 0 auto; border-radius: 8px;  height: 25rem ; z-index: 100; display: flex; align-items: center ; justify-content: center;">
      <Spinner></Spinner>
    </div>
    {/if}
  </div>
  <div class="flex flex-wrap mt-4">
    <div class="w-full  mb-12 xl:mb-0 px-4">
      <!-- svelte-ignore missing-declaration -->
      {#if canShare}
      
        <!-- svelte-ignore missing-declaration -->
        <CardBarChart items={barItems}/>
     
      {:else}
      <!-- svelte-ignore missing-declaration -->
      <div class="" style=" background-color: #334155; width: 95% ; margin: 0 auto; border-radius: 8px;  height: 25rem ; z-index: 100; display: flex; align-items: center ; justify-content: center;">
        <Spinner></Spinner>
      </div>
      {/if}
    </div>
  </div>
</div>

<style>
  @import url('https://v1.fontapi.ir/css/Estedad');
   div {
      font-family: Estedad, sans-serif;
      
  }
</style>