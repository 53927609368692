<script>
import {
    onMount
} from "svelte";
import configs from "../../config";
import Modal from "../../components/ChannelModal.svelte";
import Add from "../../components/ChannelAdd.svelte";
import {
    persianChannels,
    englishChannels
} from "../../store"
import Notification from "../../components/Notification.svelte";
import Spinner from "../../components/Spinner.svelte"
    import AddBtn from "../../components/addButton/AddBtn.svelte";
let theAdmin = JSON.parse(localStorage.getItem("user"))
let token = localStorage.getItem("token")
let channels = [];
let theUser
let persiansReceived = false ,
englishsReceived = false
let pr = false,
    id = "",
    color = "#43ff11"
let selectList = []
onMount(async () => {
    pr = JSON.parse(localStorage.getItem("user")).priviliages[3]
    fetch(configs.baseUrl + "api/channels/public/getchannels/english", {
        headers: {
            "authorization": `Bearer ${token}`,
            "phone": theAdmin.phone,
            "email": theAdmin.email,
            "imei": theAdmin.imei
        }
    }).then(async (res) => {
        res = await res.json()
        englishChannels.update(d => d = res.data)
        if (res.data && res.data.length > 0)
            channels = [...channels, ...res.data]
        englishsReceived = true
        selectList.length = channels.length
        selectList.fill(false, 0, channels.length)
        selectList[0] = true
        id = channels[0]._id
        console.log(id);
    })
    fetch(configs.baseUrl + "api/channels/public/getchannels/persian", {
        headers: {
            "authorization": `Bearer ${token}`,
            "phone": theAdmin.phone,
            "email": theAdmin.email,
            "imei": theAdmin.imei
        }
    }).then(async (res) => {
        res = await res.json()
        persianChannels.update(d => d = res.data)
        if (res.data && res.data.length > 0)
            channels = [...channels, ...res.data]
        persiansReceived = true
        channels = channels.filter(item => item.isPublic == false)

    })
})

let isPostAdded = false
let message = ""
const submit = () => {
    fetch(configs.baseUrl + `api/channels/private/${id}/${theUser}/addToMembers`, {
        headers: {
            "authorization": `Bearer ${token}`,
            "phone": theAdmin.phone,
            "email": theAdmin.email,
            "imei": theAdmin.imei
        } , method: "PATCH"
    }).then(async (res) => {
        if (res.status == 404) {
            isPostAdded = true
            message = "کاربر یافت نشد"
            color = "tomato"
            setTimeout(() => {
                isPostAdded = false
                message = ""
            }, 4000);
        } else if (res.status == 403) {
            isPostAdded = true
            message = "کاربر قبلا اضافه شده است"
            color = "tomato"
            setTimeout(() => {
                isPostAdded = false
                message = ""
            }, 4000);
        } else if (res.status == 400) {
            isPostAdded = true
            message = "خطایی رخ داد"
            color = "tomato"
            setTimeout(() => {
                isPostAdded = false
                message = ""
            }, 4000)
        } else if (res.status < 300) {
            isPostAdded = true
            message = "کاربر  با موفقیت افزوده شد"
            color = "#43ff11"
            setTimeout(() => {
                isPostAdded = false
                message = ""
            }, 4000)
        }
    })
}
</script>

{#if isPostAdded}
<Notification color={color} message={message}></Notification>
{/if}
<div class="mt-5 bg-white  shadow-lg w-full p-4" style=" margin-top: 8rem; overflow: hidden; margin-bottom: 3rem;border-radius : 16px; ">
    <div class="w-11/12 rounded bg-white mx-auto p-5">
        <div class="flex flex-wrap">
            <div class="w-full my-3 p-5">
                <p>انتخاب کانال</p>
            </div>
            {#each channels as item,index}
            <div on:click="{()=> {
                selectList.fill(false , 0 , channels.length)
                selectList[index] = true
                id = item._id
                console.log(id);
                
                }}"  class="rounded px-12 mx-4  py-2" style="color:white ; background-color: {selectList[index] ? "yellowgreen" : "gray"};">{item.name}</div>
            <!-- <input  type="radio" style="display: none;" id={item._id} name="channel" value={item._id}/> -->
            {/each}
        </div>
        <div class="flex-flex-wrap">
            <div class="md:w-6/12 w-full p-5" >
                <label for="name">افزودن کاربر </label>
                <input type="text" id="name" bind:value="{theUser}"
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
                <div class="w-full md:w-6/12">
                    <!-- <button on:click={submit}
                        style="margin: 0rem auto 1.5rem auto; display: inherit;background-color: blueviolet; width:96% ; cursor: pointer;"
                        class="rounded text-white w-full py-2 text-center"  > ارسال تغیرات</button> -->
                        <AddBtn on:click={submit}> ارسال تغیرات</AddBtn>
                </div>
            </div>
        </div>
    </div>
    
</div>
