<script>
import { onMount } from "svelte";

  import { Link,navigate} from "svelte-routing";

 
  
 
  onMount(()=> {
   
    navigate("/login")
  })
</script>