<script>
    import { createEventDispatcher, onMount } from "svelte";
    import configs from "../../config";
    import Notification from "../../components/Notification.svelte";
    import AddBtn from "../../components/addButton/AddBtn.svelte";
    let theAdmin = JSON.parse(localStorage.getItem("user"));
    let entry;
    let token = localStorage.getItem("token");
    const dispatch = createEventDispatcher();
    let messageReceived = false,
        missingData = false,
        messageStatus = 0,
        result = "";
    let type = "text",
        data,
        caption,
        isFollowable = false,
        sms = false,
        coin,
        symbol,
        externalLink,
        targets = [],
        publishedIn,
        file,
        exitPoint = 0;
    let roomMenu,
        showRoomMenu = false;
    let src = "";
    const sendSignal = async () => {
        let dataa = symbol.split("/");
        let can = false;
        if (dataa[1].toLowerCase() == "usdt") dataa[1] = "usd";

        let resp = await fetch(
            `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=${dataa[1]} `,
            {
                method: "GET",
                header: {
                    "Content-Type": "application/json",
                },
            }
        );
        resp = await resp.json();
        console.log(resp);
        if (resp[coin] != undefined) can = true;

        if (!can) {
            messageReceived = true;
            messageStatus = 400;
            result = "نام كوين اشتباه است";
            setTimeout(() => {
                messageReceived = false;
            }, 4000);
            return;
        }
        let form = new FormData();
        console.log(coin, symbol, publishedIn, caption);
        if (
            coin == undefined ||
            symbol == undefined ||
            publishedIn == undefined ||
            caption == undefined
        ) {
            missingData = true;
            setTimeout(() => {
                messageReceived = false;
                messageStatus = missingMessage;
            }, 4000);
            return;
        }
        form.append("type", type);
        form.append("isFollowable", isFollowable);
        form.append("caption", caption);
        form.append("coin", coin);
        form.append("exitPoint", exitPoint);
        form.append("externalLink", externalLink);
        form.append("entry", entry);
        form.append("symbol", symbol);
        form.append("sms", sms);
        let t = [],
            e = "",
            ep = "";
        let a;

        if (targets.length > 0) {
            t = targets.map((item) => item.toString());
        }

        if (isFollowable) {
            form.append("targets", t);
            e = entry.toString();
            ep = exitPoint.toString();
            console.log(t, entry, exitPoint);
        }
        if (type == "text") {
            if (data == null || data == undefined) {
                dispatch("mismatch");

                return;
            }
            form.append("data", data);

            let finalData = JSON.stringify({
                type,
                isFollowable: isFollowable,
                caption,
                coin,
                symbol,
                externalLink,
                entry: e,
                data,
                targets: t,
                exitPoint: ep,
                sms: sms,
            });

            console.log(finalData);
            fetch(`${configs.baseUrl}api/signals/${publishedIn}/addSignal`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    authorization: `Bearer ${token}`,
                    phone: theAdmin.phone,
                    email: theAdmin.email,
                    imei: theAdmin.imei,
                },
                body: finalData,
            })
                .then(async (res) => {
                    if (res.status < 300) {
                        result = "سیگنال با موفقیت افزوده شد";
                        messageReceived = true;
                        messageStatus = res.status;
                    } else {
                        res = await res.json();
                        console.log(res);
                        result = "خطایی رخ داد";
                        messageReceived = true;
                        messageStatus = res.status;
                    }
                    setTimeout(() => {
                        messageReceived = false;
                    }, 4000);
                })
                .catch(console.log);
        } else {
            if (file == undefined || file == null) {
                missingData = true;
                console.log("!!");
                setTimeout(() => {
                    missingData = false;
                }, 4000);
                return;
            }

            form.append("file", file);
            fetch(`${configs.baseUrl}api/signals/${publishedIn}/addSignal`, {
                method: "POST",
                headers: {
                    authorization: `Bearer ${token}`,
                    phone: theAdmin.phone,
                    email: theAdmin.email,
                    imei: theAdmin.imei,
                },
                body: form,
            })
                .then(async (res) => {
                    messageReceived = true;
                    messageStatus = res.status;
                    if (res.status < 300) {
                        result = "سیگنال با موفقیت افزوده شد";
                    } else {
                        res = await res.json();
                        console.log(res);
                        result = "خطایی رخ داد";
                    }
                    setTimeout(() => {
                        messageReceived = false;
                    }, 4000);
                })
                .catch(console.log);
        }
    };
    let channelsName = [];
    onMount(() => {
        fetch(`${configs.baseUrl}api/infos/getchannelnames`, {
            headers: {
                authorization: `Bearer ${token}`,
                phone: theAdmin.phone,
                email: theAdmin.email,
                imei: theAdmin.imei,
            },
        }).then(async (res) => {
            res = await res.json();
            channelsName = res;
            console.log(channelsName);
        });
    });
</script>

{#if messageReceived}
    {#if messageStatus > 299}
        <Notification message={result} color="tomato" />
    {:else}
        <Notification color="#43ff11" message={result} />
    {/if}
{/if}
{#if missingData}
    <Notification message={"ورودی ها را بررسی کنید"} color="tomato" />
{/if}
<div class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4" style=" margin-top: 8rem; overflow: hidden; margin-bottom: 3rem;border-radius : 16px; "  >
    <div class="w-full md:w-6/12 p-5">
        <label for="coin">قابلیت فالوو </label>
        <div class="w-full">
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input
                        type="radio"
                        class="form- export let location"
                        name="isFollowable"
                        on:change={() => {
                            isFollowable = true;
                            console.log(isFollowable);
                        }}
                        checked={isFollowable}
                        group={isFollowable}
                    />
                    <span class="ml-2">دارد</span>
                </label>
            </div>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input
                        type="radio"
                        class="form- export let location"
                        name="isFollowable"
                        on:change={() => {
                            isFollowable = false;
                            console.log(isFollowable);
                        }}
                        checked={!isFollowable}
                        group={isFollowable}
                    />
                    <span class="ml-2">ندارد</span>
                </label>
            </div>
        </div>
    </div>

    <div class="w-full md:w-6/12 p-5">
        <label for="type">نوع سیگنال</label>
        <div class="w-full">
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input
                        type="radio"
                        class="form- export let location"
                        name="type"
                        on:change={() => {
                            type = "text";
                            console.log(type);
                        }}
                        checked={type == "text"}
                        group={type}
                    />
                    <span class="ml-2">متنی</span>
                </label>
            </div>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input
                        type="radio"
                        class="form- export let location"
                        name="type"
                        on:change={() => {
                            type = "image";
                            console.log(type);
                        }}
                        checked={type == "image"}
                        group={type}
                    />
                    <span class="ml-2">عکس</span>
                </label>
            </div>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input
                        type="radio"
                        class="form- export let location"
                        name="type"
                        on:change={() => {
                            type = "voice";
                            console.log(type);
                        }}
                        checked={type == "voice"}
                        group={type}
                    />
                    <span class="ml-2">صوت</span>
                </label>
            </div>
        </div>
    </div>


    <div class="w-full md:w-6/12 p-5">
        <label for="coin">کوین</label>
        <input
            type="text"
            id="coin"
            bind:value={coin}
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
        />
    </div>

    <div class="w-full md:w-6/12 p-5">
        <label for="symbol">كوين(نماد)</label>
        <input
            type="text"
            id="symbol"
            bind:value={symbol}
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
        />
    </div>

    <div class="w-full md:w-6/12 p-5">
        <label for="symbol">کپشن</label>
        <input
            type="text"
            id="symbol"
            bind:value={caption}
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
        />
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="coin">لینک خارجی</label>
        <input
            type="text"
            id="coin"
            bind:value={externalLink}
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
        />
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="coin">ارسال پيامك</label>
        <div class="w-full">
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input
                        type="radio"
                        class="form- export let location"
                        name="sms1"
                        on:change={() => {
                            sms = true;
                            console.log(sms);
                        }}
                        checked={sms}
                        group={sms}
                    />
                    <span class="ml-2">بله</span>
                </label>
            </div>
            <div style="display: inline-block;">
                <label class="inline-block items-center">
                    <input
                        type="radio"
                        class="form- export let location"
                        name="sms2"
                        on:change={() => {
                            sms = false;
                            console.log(sms);
                        }}
                        checked={!sms}
                        group={sms}
                    />
                    <span class="ml-2">خير</span>
                </label>
            </div>
        </div>
    </div>

    <div class="w-full  p-5">
        <label for="">انتخاب کانال</label>
        <div class="relative mt-2 text-left">
            <div>
                <button
                    type="button"
                    on:click={() => (showRoomMenu = !showRoomMenu)}
                    class="inline-flex justify-center w-full bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                >
                    کانال
                    <svg
                        class="-mr-1 ml-2 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </button>
            </div>

            {#if showRoomMenu}
                <div class="">
                    <div
                        bind:this={roomMenu}
                        class="origin-top-right absolute right-0 w-full mt-2  rounded-md shadow-lg
                    bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="menu-button"
                        tabindex="-1"
                    >
                        <div class="py-1" role="none">
                            {#each channelsName as r}
                                <div
                                    style="cursor: pointer;"
                                    on:click={() => {
                                        publishedIn = r._id;
                                        console.log(publishedIn);
                                        showRoomMenu = false;
                                    }}
                                    class="text-gray-700 block px-4 py-2 text-sm"
                                    role="menuitem"
                                    tabindex="-1"
                                    id="menu-item-0"
                                >
                                    {r.name}
                                </div>
                            {/each}
                        </div>
                    </div>
                </div>
            {/if}
        </div>
    </div>

    <div class="w-full p-5">
        {#if type == "text"}
            <label for="symbol">محتوا</label>
            <textarea
                id="symbol"
                style="min-height: 7rem"
                bind:value={data}
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            />
        {:else if type == "image"}
            <div class="flex flex-wrap ">
                <div class="w-full md:w-6/12 " style="height: 16rem;">
                    <div
                        class="overflow-hidden relative w-64  "
                        style="position: relative;margin-top: 2rem"
                    >
                        <label
                            id="img"
                            style="background-color: blueviolet;float: right"
                            class=" hover:bg-indigo-dark text-center text-white font-bold py-2 rounded  px-4 w-full items-center"
                        >
                            انتخاب عکس
                            <input
                                style="visibility: hidden;"
                                id="img"
                                on:change={(e) => {
                                    file = e.target.files[0];
                                    console.log(file);
                                    src = URL.createObjectURL(
                                        e.target.files[0]
                                    );
                                }}
                                accept="image/*"
                                class="cursor-pointer absolute block opacity-0 pin-r pin-t"
                                type="file"
                                name="vacancyImageFiles"
                            />
                        </label>
                    </div>
                </div>
                <div class="w-full md:w-6/12 p-5 " style="height: 16rem;">
                    <div
                        class=" inline-block mt-3"
                        style="width: 100%;
                    border: 2px dashed gray; border-radius: 8px;
                    background-image: url({src}) ; height: 100%; background-size: cover ; background-repeat: round; "
                    />
                </div>
            </div>
        {:else if type == "voice"}
            <div class="flex flex-wrap ">
                <div class="w-full md:w-6/12 " style="height: 5rem;">
                    <div
                        class="overflow-hidden relative w-64  "
                        style="position: relative;margin-top: 2rem"
                    >
                        <label
                            id="img"
                            style="background-color: blueviolet;float: right"
                            class=" hover:bg-indigo-dark text-center text-white font-bold py-2 rounded  px-4 w-full items-center"
                        >
                            انتخاب صوت
                            <input
                                style="visibility: hidden;"
                                id="img"
                                on:change={(e) => {
                                    file = e.target.files[0];
                                    console.log(file);
                                    src = URL.createObjectURL(
                                        e.target.files[0]
                                    );
                                }}
                                accept="audio/*"
                                class="cursor-pointer absolute block opacity-0 pin-r pin-t"
                                type="file"
                                name="vacancyImageFiles"
                            />
                        </label>
                    </div>
                </div>
                <div class="w-full md:w-6/12 p-5 " style="height: 5rem;">
                    <!-- svelte-ignore a11y-media-has-caption -->
                    <audio controls class="w-full mt-2" {src}>
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio>
                </div>
            </div>
        {/if}
    </div>

    {#if isFollowable}
        <div class="w-full md:w-6/12 p-5">
            <label for=""> تعداد هدف ها </label>
            <input
                type="number"
                id=""
                bind:value={targets.length}
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            />
        </div>

        <div class="w-full md:w-6/12 p-5">
            <label for="entry">نقطه ورود </label>
            <input
                type="number"
                id="entry"
                bind:value={entry}
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            />
        </div>

        <div class="w-full md:w-6/12 p-5">
            {#if targets.length > 0}
                <label for=""> اهداف </label>
            {/if}
            {#each targets as _, i}
                <input
                    type="number"
                    id=""
                    bind:value={targets[i]}
                    class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
                />
            {/each}
        </div>
        <div class="w-full md:w-6/12 p-5 ">
            <label for=""> نقطه خروج </label>
            <input
                type="number"
                id=""
                bind:value={exitPoint}
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
            />
        </div>
    {/if}
    <div class="w-full p-2">
        <AddBtn on:click={sendSignal} > ارسال</AddBtn>
    </div>
</div>
<div class="" style="height: 3rem;width: 100%;" />
<style>
    @import url('https://v1.fontapi.ir/css/Estedad');
     button , div , input , label{
        font-family: Estedad, sans-serif;
        
    }
</style>
