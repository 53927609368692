<script>
    import { createEventDispatcher } from "svelte";
    import configs from "../config";
    export let data;
    let { name, color, forPersian } = data;
    console.log(data, "data");
    let theAdmin = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token");
    const sendForm = () => {
        fetch(
            configs.baseUrl +
                "api/channels/public/" +
                data._id +
                "/editChannel",
            {
                method: "PATCH",
                body: JSON.stringify({ name, color, forPersian }),
                headers: {
                    "Content-type": "application/json",
                    authorization: `Bearer ${token}`,
                    phone: theAdmin.phone,
                    email: theAdmin.email,
                    imei: theAdmin.imei,
                },
            }
        )
            .then(async (res) => {
                let code = res.status;
                res = await res.json();

                if (code < 300)
                    dispatch("finished", {
                        message: "done",
                        data: { color, name, forPersian, id: data._id },
                    });
                else dispatch("finished", { message: "failed" });
                setTimeout(() => close(), 10);
            })
            .catch((e) => {
                console.log(e);
                setTimeout(() => close(), 10);
            });
    };
    let loader = false;
    let imageDone = 0;
    const sendImage = (e) => {
        let file = e.target.files[0];
        loader = true;
        let form = new FormData();
        form.append("file", file);
        form.append("message", file.filename);
        fetch(
            configs.baseUrl +
                "api/channels/public/" +
                data._id +
                "/changeImage",
            {
                method: "PATCH",
                body: form,
                headers: {
                    authorization: `Bearer ${token}`,
                    phone: theAdmin.phone,
                    email: theAdmin.email,
                    imei: theAdmin.imei,
                },
            }
        )
            .then(async (res) => {
                res = await res.json();
                console.log(res);
                imageDone = 1;
                loader = false;
                dispatch("image", {
                    value: "logoes/" + res.message,
                    id: data._id,
                });
            })
            .catch((e) => {
                console.log(e);
                loader = false;
                imageDone = 2;
            });
    };
    const close = () => {
        dispatch("close", {});
    };
    const dispatch = createEventDispatcher();
</script>

<div
  class="fixed modal overflow-hidden"
  style="
min-height: 130vh;
z-index: 100;
width: 100vw;
position: fixed;
top:0;
left: 0;
background: #000000aa;
inset : 0;

"
>
<button class="hideBtn" on:click={() => dispatch("close", {})} />
  <div class="relative flex justify-center  " style="width: 100% ; height: 100%; ">
    
    <div
      class="mt-2 flex flex-wrap bg-white rounded-lg MODAL md:p-4"
      style=""
    >
            <div class="md:w-6/12 w-full p-5 ">
                <label for="name">نام</label>
                <input
                    type="text"
                    id="name"
                    bind:value={name}
                    class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
                />
            </div>
            <div class="md:w-6/12 w-full p-5">
                <label for="color">رنگ</label>
                <input
                    type="text"
                    id="color"
                    bind:value={color}
                    style="color: {color};border : 2px solid {color};"
                    class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow"
                />
            </div>
            <div class="md:w-6/12 w-full p-5">
                <p style="margin-bottom: 0.6rem;">زبان</p>
                <div style="display: inline-block;">
                    <label class="inline-block items-center">
                        <input
                            type="radio"
                            class="form-radio"
                            name="forPersian"
                            on:change={() => {
                                forPersian = true;
                                console.log(forPersian);
                            }}
                            checked={forPersian}
                            group={forPersian}
                        />
                        <span class="ml-2">فارسی</span>
                    </label>
                </div>
                <div style="display: inline-block;">
                    <label class="inline-block items-center">
                        <input
                            type="radio"
                            class="form-radio"
                            name="forPersian"
                            on:change={() => {
                                forPersian = false;
                                console.log(forPersian);
                            }}
                            checked={!forPersian}
                            group={forPersian}
                        />
                        <span class="ml-2">غیر فارسی</span>
                    </label>
                </div>
            </div>
            <div class="md:w-6/12 w-full p-5">
                <div class="overflow-hidden relative w-64 mt-4 mb-4">
                    <label
                        id="img"
                        style="background-color: blueviolet;"
                        class=" hover:bg-indigo-dark text-white font-bold py-2 rounded mt-2 px-4 w-full inline-flex items-center"
                    >
                        انتخاب عکس
                        <input
                            style="visibility: hidden;"
                            id="img"
                            on:change={(e) => sendImage(e)}
                            accept="image/*"
                            class="cursor-pointer absolute block opacity-0 pin-r pin-t"
                            type="file"
                            name="vacancyImageFiles"
                        />
                    </label>
                    {#if imageDone == 0}
                        <small>عکس بلافاصله پس از انتخاب تغییر میکند</small>
                    {:else if imageDone == 1}
                        <small>لوگو با موفقیت ارسال شد</small>
                    {:else if imageDone == 2}
                        <small>خطایی رخ داد</small>
                    {/if}
                </div>
            </div>
            <div class="w-full p-5">
                <!-- svelte-ignore a11y-missing-attribute -->
                <button
                    on:click={() => sendForm()}
                    style="background-color: blueviolet; width:100% ; cursor: pointer;"
                    class="rounded text-white w-full py-2 text-center"
                >
                    ارسال تغیرات</button
                >
                <button
                    on:click={() => close()}
                    style="margin-top: 0.5rem; background-color: tomato; width:100% ; cursor: pointer;"
                    class="rounded text-white w-full py-2 text-center"
                >
                    بستن پنجره</button
                >
            </div>
        </div>
    </div>
</div>

<style>
  .MODAL {
    border-radius: 16px;
    animation-name: modal;
    animation-duration: 1s;
    z-index: 110;
    inset: 0;
    position: relative;
    overflow-y: auto;
    height: 80vh;
    width: 90%;
  }

  @media screen and (max-width : 992px){
    .MODAL{
      width: 90%;
      overflow-x: hidden;
    }
  }

    @keyframes modal {
        0% {
            transform: scale(0.8) translateY(1000px);
            opacity: 0;
        }
        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
        }
    }

    .MODAL::-webkit-scrollbar {
        width: 5px;
        
    }

    .MODAL::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);

    }

    .MODAL::-webkit-scrollbar-thumb {
        background-color: #dcdcdc;
        border-radius: 16px;
        padding: 10px;
    }
</style>
