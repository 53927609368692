<script>
import {
    onMount,
    tick
} from "svelte";

import Notification from "../../components/Notification.svelte";
import Spinner from "../../components/Spinner.svelte"
import InsertUser from "../../components/InsertUser.svelte"
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
import {
    userPageNumber,
    totalUsers,
user,
} from "../../store"

import UserEdit from "../../components/UsersEdit.svelte"
let modal = false , emailEdit =false ,emailToEdit= "" , phoneToEdit = "" , phoneEdit = false;
let payment = []
let result, messageReceived = false,
    messageStatus
let usersReceived = false,
    plansReceived = false,
    showPlanMenu = false,
    addUserComponent = false,
    planMenu, searchItem, canClear = false
let users = [],
    plans = [],
    tempUser = [],
    testtt = []
let item = {}
let total = 0
let sort = "createdAt",
    sortMode = -1
import configs from "../../config"
// let phone , email , firstName , lastName , isPersian = true, imei , expiresIn  , plan
let cc = ""
import UserPaginator from "../../components/UserPaginator.svelte";
import { log } from "util";
const openModal = (data) => {
    item = data;
    modal = true;
}

onMount(()=>{
    fetch(`${configs.baseUrl}api/payments/getpayments` , {
        method: "GET",
        headers: {"Content-Type" : "application/json",
        "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    }).then(async (res)=> {
         
       
        if (res.status < 300)
        {
           let data = await res.json()
           payment = [...data]
        }
        else 
        {
          console.log("the Payment doesnt exist!");
        }
    })
})
const submitEmail = (item ,email) => {
    console.log(email);
    fetch(`${configs.baseUrl}api/users/${item._id}/editUser` , {
        method: "PATCH",
        body: JSON.stringify({email}),
        headers: {"Content-Type" : "application/json",
        "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    }).then(async (res)=> {
        emailEdit = false 
       
        if (res.status < 300)
        {
           
            messageReceived = true
                result = "کاریر با موفقیت ویرایش شد"
                messageStatus = 200
               item.email = email
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
        }
        else 
        {
            messageReceived = true
                result = "خطایی رخ داد"
                messageStatus = 400
                users = users.filter(i => i != item)
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
                res = await  res.json();
                console.log(res);
        }
    })
}


const submitPhone = (item ,phone) => {
    
    fetch(`${configs.baseUrl}api/users/${item._id}/editUser` , {
        method: "PATCH",
        body: JSON.stringify({phone}),
        headers: {"Content-Type" : "application/json",
        "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    }).then(async (res)=> {
        phoneEdit = false 
       
        if (res.status < 300)
        {
           
            messageReceived = true
                result = "کاریر با موفقیت ویرایش شد"
                messageStatus = 200
               item.phone = phone
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
        }
        else 
        {
            messageReceived = true
                result = "خطایی رخ داد"
                messageStatus = 400
                users = users.filter(i => i != item)
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
                res = await  res.json();
                console.log(res);
        }
    })
}


const deleteItem = (item) => {
    fetch(`${configs.baseUrl}api/users/${item._id}/deleteUser`, {
            method: "DELETE",
            headers:{
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
        .then(res => {
            if (res.status < 300) {
                messageReceived = true
                result = "کاریر با موفقیت حذف شد"
                messageStatus = 200
                users = users.filter(i => i != item)
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            } else {
                messageReceived = true
                result = "خطایی رخ داد"
                messageStatus = 400
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            }
        })
}
let pr = false , itemIndex , pr12 = false
onMount(() => {
    userPageNumber.subscribe(d => {
        fetchUsers(10, d, sort, sortMode)
    })
    
    pr = JSON.parse(localStorage.getItem("user")).priviliages[1]
    pr12 = JSON.parse(localStorage.getItem("user")).priviliages[12]
    console.log(pr12 , " PR12")
    fetch(configs.baseUrl + "api/plans/getPlans",{
        headers:{
              "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
    
        plans = res.map(plan => plan)
        plansReceived = true
   
        
    })
})


const fetchUsers = (num, skip, sort, mode) => {
    fetch(`${configs.baseUrl}api/users/${num}/${skip}/${sort}/${mode}/getUsers`,{
        headers:{
              "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).then(async (res) => {
        res = await res.json()
        console.log("user" , res)
        users = [...res.users]
        totalUsers.update(d => d = res.totalUsers)
        usersReceived = true
        total = res.totalUsers
     
    })
}
const setEditorData = (e) => {
    console.log(e);
    result = e.detail.message
    let data = e.detail.data
    messageReceived = true
    messageStatus = e.detail.status
    if (messageStatus < 300 && !modal) {

        users = [e.detail.data, ...users]
    } else if (messageStatus < 300 && modal) {

        let i = users.find(itemm => itemm._id == data._id)
        let index = users.indexOf(i)
        users[index] = e.detail.data
        users = [...users]

    }

    modal = false
    addUserComponent = false
    setTimeout(() => {
        messageReceived = false
        result = ""
        messageStatus = 0

    }, 4000);

}
const close = () => {
    modal = false
}
const getPlan = (item) => {

  
    let temp = plans.find(i => i._id == item)
    if (temp) return temp.name
}
const doSort = (data) => {
    let temp = data.split("/")
    sort = temp[0]
    sortMode = +temp[1]

    userPageNumber.subscribe(d => {
        fetchUsers(10, d, sort, sortMode)
    })
}
const searchUser = () => {
    if (searchItem.length > 0) {
      
        fetch(`${configs.baseUrl}api/users/${searchItem}/getUsersByPartial`,{
            headers:{
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
            .then(async (res) => {
                if (res.status < 300) {
                    canClear = true
                    tempUser = [...users]
                    res = await res.json()
                    users = []
                    
                    users.push(...res)
                    users = [...users]
                    console.log(users);
                    
                } else {
                    result = "کاربر پیدا نشد"
                    messageReceived = true
                    messageStatus = 400
                    
                    setTimeout(() => {
                        messageReceived = false
                    }, 4000);
                }
            })
    }
}
const doClear = () => {
    users = [...tempUser]
    canClear = false
    tempUser = []
}
const download = () => {
    fetch("https://marveltradeconsult.com/api/users/getuserscsv" , {
        headers:{
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).then(async (res) => {
        let blob = await res.blob()
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "users.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
    })
}

    const getMili = (enter , enter2) => {
        var date1 = new Date(enter);
        var date2 = new Date(enter2);
				var difference = date1.getTime() - date2.getTime();
				var days = Math.ceil(difference / (1000 * 3600 * 24));
				console.log(days);
        return days
    }

    const countPayments = (phone) => {
        let count = 0
        payment.forEach((item)=>{

            if(phone == item.phone) count+=1
        })
        return count
    }





</script>

{#if messageReceived}
{#if messageStatus > 299}
<Notification message={result}  color = "tomato"></Notification>
{:else}
<Notification color="#43ff11" message={result}></Notification>
{/if}

{/if}

{#if modal}
<UserEdit on:close={()=> modal = false} on:finish={setEditorData} external={item} plans={plans}></UserEdit>
{/if}

<div class=" md:mt-50 z-10 estedad " style="padding-bottom: 2rem;">

    <div class="channel-table">

        <div class="flex flex-col justify-center md:flex-row md:justify-between md:items-end w-full my-4 p-4" style="font-weight: 600; font-size: 1.2rem ; margin-top: 6rem ;">
            <!-- <div class=" flex relative">
                <input type="text" id="coin" bind:value="{searchItem}"
                    placeholder="نام کاربری ( شماره تلفن  یا ایمیل)"
                    class="bg-gray-100 border-0 rounded-lg focus:bg-white shadow">
                svelte-ignore a11y-missing-attribute
                <button on:click="{searchUser}"
                class="bg-lightBlue-500 text-white absolute top-0 left-0"
                    style=" font-weight: bold; cursor: pointer;  border-radius: 8px;">جستجو
                </button>

                {#if canClear}
                svelte-ignore a11y-missing-attribute
                <a on:click="{doClear}"
                    style="background-color: blueviolet; color: whitesmoke; font-weight: bold;
                    padding: 0.6rem 1.38rem ; cursor: pointer;  border-radius: 8px;" >پاک کردن</a>

                {/if}
            </div> -->

            <div class="flex justify-center">
                <div class="w-full  p-5 w-2/4">
                  <div class="flex">
                    <input bind:value="{searchItem}" placeholder="جستجو کنید ..."  type="text" style="border-top-right-radius : 16px;border-bottom-right-radius : 16px;"  class="w-full border-0 shadow bg-white pl-2 text-base font-semibold outline-0" id="" />
                    <button on:click="{searchUser}" style="border-top-left-radius : 16px;border-bottom-left-radius : 16px;" class="flex bg-lightBlue-500 text-white w-10 items-center justify-center bg-white p-5">
                        <svg fill="none" stroke="white" viewBox="0 0 20 20" aria-hidden="true" class="pointer-events-none absolute w-5 text-white transition">
                          <path d="M16.72 17.78a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM9 14.5A5.5 5.5 0 0 1 3.5 9H2a7 7 0 0 0 7 7v-1.5ZM3.5 9A5.5 5.5 0 0 1 9 3.5V2a7 7 0 0 0-7 7h1.5ZM9 3.5A5.5 5.5 0 0 1 14.5 9H16a7 7 0 0 0-7-7v1.5Zm3.89 10.45 3.83 3.83 1.06-1.06-3.83-3.83-1.06 1.06ZM14.5 9a5.48 5.48 0 0 1-1.61 3.89l1.06 1.06A6.98 6.98 0 0 0 16 9h-1.5Zm-1.61 3.89A5.48 5.48 0 0 1 9 14.5V16a6.98 6.98 0 0 0 4.95-2.05l-1.06-1.06Z"></path>
                        </svg>
                    </button>
                  </div>
                </div>
              </div>

            <div class="" style="">
                <div class="" >
                    <label class="" for="sort" style="display: block; margin-bottom: 0.2rem; ">  مرتب سازی</label>
                    <!-- svelte-ignore a11y-no-onchange -->
                    <select on:change={(e)=> doSort(e.target.value)} name="" style="display: inline-block;border-radius : 16px" id="sort" class="border-0 shadow">
                        <option style="text-align: right;" value="createdAt/-1">  تاریخ ثبت نام/  نزولی</option>
                        <option style="text-align: right;" value="createdAt/1"> تاریخ ثبت نام / سعودی</option>
                        <option style="text-align: right;" value="expiresIn/-1"> تاریخ انقضا / نزولی</option>
                        <option style="text-align: right;" value="expiresIn/1"> تاریخ انقضا / سعودی</option>
                        <option style="text-align: right;" value="firstName/-1"> نام / نزولی</option>
                        <option style="text-align: right;" value="firstName/1"> نام / سعودی</option>
                        <option style="text-align: right;" value="lastName/-1"> نام خانوادگی / نزولی</option>
                        <option style="text-align: right;" value="lastName/1"> نام خانوادگی / سعودی</option>
                        <option style="text-align: right;" value="plan/-1"> پلن / نزولی</option>
                        <option style="text-align: right;" value="plan/1"> پلن / سعودی</option>
                    </select>
                </div>
            </div>
            
        </div>
        <section class="container mx-auto w-full  font-mono" style="">
            <div class="w-full mb-8 overflow-hidden shadow-lg " style="border-radius: 16px;">
                <div class="w-full overflow-x-auto">
                    <table class="w-full estedad" style="">
                        <thead>
                            <tr class="text-md bg-white font-medium tracking-wide text-left text-black  uppercase border-b shadow estedad" style=" ">
                                <th class="px-2 py-3 text-right font-semibold text-lg estedad">نام کاربری</th>
                                <th class="px-2 py-3 text-right font-semibold text-lg">ایمیل</th>
                                <th class="px-2 py-3 text-right font-semibold text-lg">پرداخت</th>
                                <th class="px-2 py-3 text-right font-semibold text-lg">كد معرف</th>
                                <th class="px-2 py-3 text-right font-semibold text-lg">امتياز كاربر</th>
                                <th class="px-2 py-3 text-right font-semibold text-lg">پلن</th>
                                <th class="px-2 py-3 text-right font-semibold text-lg">نام و نام خانوادگی</th>
                                <th class="px-2 py-3 text-right font-semibold text-lg">تاریخ ثبت نام</th>
                                <th class="px-2 py-3 text-right font-semibold text-lg">تاریخ انقضا</th>
                                <th class="px-2 py-3 text-right font-semibold text-lg">حذف / بازنویسی</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            {#if usersReceived && plansReceived}
                            {#each users as item, index}
                            {#if index < 10}
                            <!-- ‍{#each payment as i , index}
                            {#if i.phone == item.phone} -->
                            <tr class="text-gray-700 border-b shadow">
                                <td class="px-4 py-3">
                                    <div class="flex items-center text-sm">
                                        {#if phoneEdit && index == itemIndex}
                                        <div class="bg-gray-100 flex rounded-lg p-1" >
                                            <input class="bg-gray-100 border-0 " type="text" bind:value="{phoneToEdit}">
                                            <button class=" border-0 rounded-lg p-3 bg-blue-500" style="background-color: slateblue;color: white" on:click="{submitPhone(item ,phoneToEdit)}"> تایید</button>
                                        </div>
                                        
                                        {:else}
                                            <div>
                                                <p class="font-semibold text-black">{item.phone}</p>
                                            </div>
                                        {/if}
                                    
                                        
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        {#if emailEdit && index == itemIndex}
                                             <input type="text" bind:value="{emailToEdit}">
                                             <button class="mx-1 border rounded-lg p-3 bg-blue-500" style="background-color: slateblue;color: white" on:click="{submitEmail(item ,emailToEdit)}"> تایید</button>
                                        {:else}
                                        <div>
                                            <p class="font-semibold text-lightBlue-400">{item.email}</p>
                                        </div>
                                        {/if}
                                      
                                    </div>
                                </td>

                                
                                
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                      {countPayments(item.phone)}  
                                    </div>
                                </td>

                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <!-- {#if item.phone == i.phone} -->
                                       
                                        <p class="font-semibold text-center">
                                            ‍{item.referralCode ? item.referralCode : "" }
                                        </p>
                                        
                                    </div>
                                </td>

                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <!-- {#if item.phone == i.phone} -->
                                        <p style="color : green" class="font-semibold text-center">
                                            

                                            {#if item.referredByMe== null}
                                                    0
                                                {:else}
                                                {item.referredByMe.length}
                                            {/if}
                                        </p>

                                        
                                        
                                    </div>
                                </td>

                                

                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <!-- let expire = new Date(data.expiresIn) ; let now = new Date() ;
                                            let daysToExpire = (expire - now)  / (1000 * 60 * 60 * 24)
                                            console.log(expire , daysToExpire); -->
                                            
                                            {#if item.firstTime && (getMili(item.expiresIn , Date.now())>30) && (getMili(item.expiresIn , Date.now())<38)}
                                                <p class="font-semibold text-black"> <span class="text-xs inline-block py-2 px-3 leading-none text-center whitespace-nowrap align-baseline font-bold bg-emerald-500 text-white rounded">{getPlan(item.plan)}  (7روزه)</span></p>
                                            {:else}
                                                <p class="font-semibold text-black"> <span class="text-xs inline-block py-2 px-3 leading-none text-center whitespace-nowrap align-baseline font-bold bg-emerald-500 text-white rounded"> {getPlan(item.plan)}</span></p>
                                            {/if}
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{item.firstName + " " + item.lastName}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{new Date(Date.parse(item.createdAt)).toDateString()}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{new Date(Date.parse(item.expiresIn)).toDateString()}</p>
                                        </div>
                                    </div>
                                </td>

                                <td class=" " style="padding: 0 1rem">
                                    {#if pr}
                                         <!-- content here -->
                                         <i class="fa-edit fas"  style="margin: 0 0.5rem; cursor: pointer " on:click={openModal(item)} ></i>
                                         <i class="fa-trash fas text-red-500"  style="margin: 0 0.5rem; cursor: pointer" on:click={deleteItem(item)} ></i>
                                         {/if}
                                         {#if pr12}
                                         <i class="fa-phone fas text-emerald-500"  style="margin: 0 0.5rem; cursor: pointer" on:click={()=>{phoneToEdit= item.phone; phoneEdit = !phoneEdit ; itemIndex = index} } ></i>

                                         <i class="fa-envelope fas text-lightBlue-400"  style="margin: 0 0.5rem; cursor: pointer" on:click={()=>{emailToEdit= item.email; emailEdit = !emailEdit ; itemIndex = index} } ></i>
                                         {/if}
                                        </td>
                            </tr>
                            <!-- {/if}
                            {/each} -->
                            {/if}
                            {/each}
                            {:else}
                            <div class="w-full" style="background-color: #343434; width: 100%">
                                <Spinner></Spinner>
                            </div>
                            {/if}

                        </tbody>
                    </table>
                    
                </div>
            </div>
            <UserPaginator total={total} ></UserPaginator>
        </section>
    </div>
    <!-- svelte-ignore missing-declaration -->
    <!-- svelte-ignore a11y-missing-attribute -->
    <a on:click="{download}" download=""
        style="background-color: whitesmoke; color: #343434; padding: 0.5rem 1.5rem  cursor: pointer; border-radius: 8px; border: 1px solid #34343455" >خروجی CSV</a>
</div>

<style>
    @import url('https://v1.fontapi.ir/css/Estedad');
    p , button , table , tr , th , td , div , input , label{
        font-family: Estedad, sans-serif;
        
    }
</style>

