<script>
    import {
        onMount
    } from "svelte";
    let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
    import Notification from "../../components/Notification.svelte";
    import configs from "../../config";
    let loaded = false
    let searchItem = ""
    let foundUser = {},
        selectedCat,
        selectedCatt
    let messageReceived = false,
        messageStatus = 0,
        message = "",
        color = "",
        result = ""
    let cats = [],
        subCat
    onMount(() => {
        loaded = true
        fetch(`${configs.baseUrl}api/infos/getCategories`,{
            headers: {
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
            .then(async (res) => {
                let data = await res.json()
                console.log(data);
                cats = [...data]
            })
    })
 
    let cat
    const deleteCat = ()=> {
        if (selectedCatt != undefined)
        {
            fetch(`${configs.baseUrl}api/infos/${selectedCatt}/deleteCategory`,{
                headers: {
                    "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
                }
            })
            .then(async (res) => {
                if (res.status < 300)
                    {
                        alert("انجام شد")
                        cats = cats.filter(item=>item != selectedCatt)
                        cats = [...cats]
                    }
                else
                {
                    console.log(await res.json());
                    alert("خطا")
                }
            })
        }
    }
    const addCat = () => {
        if (cat != undefined || cat.length > 0)
            fetch(`${configs.baseUrl}api/infos/${cat}/addCategory`,{
                headers: {
                    "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
                }
            })
            .then(async (res) => {
                if (res.status < 300)
                    alert("DONE")
            })
    }
    const addSubCat = () => {
        if (subCat != undefined || subCat.length > 0)
            fetch(`${configs.baseUrl}api/infos/${selectedCat}/${subCat}/addSubCategory`,{
                headers: {
                    "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
                }
            })
            .then(async (res) => {
                if (res.status < 300) {
                    alert("Done");
                    subCat = undefined
                }
            })
    }

    </script>
    
    {#if messageReceived}
    {#if messageStatus > 299}
    <Notification message={result}  color = "tomato"></Notification>
    {:else}
    <Notification color="#43ff11" message={result}></Notification>
    {/if}
    {/if}
    
            <div  class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4" style=" margin-top: 8rem; overflow: hidden; margin-bottom: 3rem;border-radius : 16px; " >
                <label for="" style="display: block;">افزودن دسته بندی پست ها</label>
                <input type="text" id="coin" bind:value="{cat}"
    
                class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
                <!-- svelte-ignore a11y-missing-attribute -->
                <a class="bg-lightBlue-500" on:click="{addCat}"
                    style=" color: white; font-weight: bold; padding: 0.6rem 1.55rem ; cursor: pointer;  border-radius: 8px;" >افزودن</a>
            </div>
            <div class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4"  style="margin: 2rem 0 ; ; padding-bottom: 5rem;border-radius : 16px;">
                <label for="" style="display: block;">افزودن زیر دسته بندی پست ها</label>
                <select  id="" bind:value={selectedCat} class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow" >
                    <option value={undefined}></option>
                    {#each cats as item}
                    <option value="{item}">{item}</option>
                    {/each}
                </select>
                {#if selectedCat != undefined}
                <input type="text"  id="coin" bind:value="{subCat}" class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
                <!-- svelte-ignore a11y-missing-attribute -->
                <button class="bg-lightBlue-500" on:click="{addSubCat}" disabled={subCat == undefined}
                    style="color: white; font-weight: bold; padding: 0.6rem 1.55rem ; cursor: pointer;
                    border-radius: 8px;" >افزودن</button>
    
                {/if}
            </div>
            <div class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4"  style="margin: 2rem 0 ;border-radius : 16px ; padding-bottom: 5rem">
                <label for="" style="display: block;">حذف  دسته بندی پست ها</label>
                <select  id="" bind:value={selectedCatt} class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
                    <option value={undefined}></option>
                    {#each cats as item}
                    <option value="{item}">{item}</option>
                    {/each}
                </select>
                <button on:click="{deleteCat}" 
                class="bg-lightBlue-500"
                    disabled={selectedCatt == undefined}
                    style="color: white; font-weight: bold; padding: 0.6rem 1.55rem ; cursor: pointer;
                    border-radius: 8px;" >حذف</button>
            </div>



            <style>
                @import url('https://v1.fontapi.ir/css/Estedad');
                 button , div , input , label{
                    font-family: Estedad, sans-serif;
                    
                }
            </style>
 
    