<script>
    import { onMount } from "svelte";
    import configs from "../../config"
    import toast, { Toaster } from 'svelte-french-toast';
    import axios from 'axios'
    import Spinner from "../../components/Spinner/Spinner.svelte";
    import BackDrop from "../../components/BackDrop/BackDrop.svelte";


    let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")

    let files , planMenu , choosePlan = "";
    let plans = []
    let checkBtn = false ,showPlanMenu = false

    onMount(()=>{
        fetch(configs.baseUrl + "api/plans/getPlans",{
        headers:{
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
        }).
        then(async (res) => {
            res = await res.json();
            if (res) plans = [...res];
            console.log(plans)
            // console.log(plans);
            // plansReceived = true
        })
    })



    const upload = () => {
        const formData = new FormData();
        checkBtn = true
        
        if(files == undefined){
            toast.error("لطفا فایل مورد نظر را انتخاب کنید")
            checkBtn = false
            return
        }

        if(choosePlan.length == 0){
            toast.error("لطفا پلن مورد نظر را انتخاب کنید")
            checkBtn = false
            return
        }

        let b = []
        b= files[0].name?.split(".")
        
        if(String(b[b?.length - 1]) == "xls" || String(b[b?.length - 1]) == "xlsx"){
            // formData.append('damName', value);


            formData.append('file', files[0]);
            formData.append('plan', choosePlan);

            axios.post(`${configs.baseUrl}api/users/useexel` , formData , {
                headers: {
                "Content-Type": "multipart/form-data",
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        }).then(( data ) => {
            console.log(data)
            const text = "آپلود فایل موفقیت آمیز بود";
            checkBtn =false
            choosePlan = ""
            files = ""
            toast.success(text);
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message)
            files = ""
            checkBtn = false
        });
        }else{
            toast.error("فایل مورد نظر Excel نمی باشد.")
            files = ""
            checkBtn=false
            return
        }
        
        
    }

</script>

{#if checkBtn}
    <BackDrop/>
    <div class="w-100 flex justify-center items-start">
        <Spinner/>
    </div>

{/if}

<div class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4" style=" margin-top: 8rem; overflow: hidden; margin-bottom: 3rem;border-radius : 16px; "  >
    <div class="w-full my-4">
        <p>انتخاب پلن</p>
        <div class="relative mt-2 text-left">
            <div>
                <button type="button"
                    on:click="{()=> showPlanMenu = !showPlanMenu}"

                    class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                    پلن
                    <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
            </div>
            {#if showPlanMenu }
            <div class=""  style="height: auto;">

                <div  style="" bind:this="{planMenu}" class="origin-top-right absolute right-0 w-full mt-2  rounded-md shadow-lg
                    bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">

                    <div class="py-1" role="none" style="height: auto;">
                        {#each plans as r}
                            {#if r._id.slice(0,3) == "321"}
                                <div style="cursor: pointer;"
                                    on:click="{()=> {choosePlan = r._id ;showPlanMenu = false ;
                                    }}" class="text-gray-700 block px-4 py-2 text-sm"
                                    role="menuitem" tabindex="-1" id="menu-item-0">{r.name}
                                </div>
                            {/if}
                        {/each}
                    </div>
                </div>
            </div>
            {/if}
            </div>
    </div>
    <div class="flex items-center justify-center flex-col w-full bg-white rounded-md shadow p-4">
        <label for="dropzone-file" class="p-4 flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                <p class="text-xs text-gray-500 dark:text-gray-400">فایل excel را انتخاب کنید.</p>
            </div>
            <input  bind:files id="dropzone-file" type="file" class="hidden" />
        </label>
        <button style="background-color: rgb(74 222 128);" class="w-full my-2 p-2  text-white rounded flex justify-center items-center" on:click={upload}>
                آپلود   
                <span class="mx-2">
                    <svg fill="#FFF" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"/>
                    </svg>
                </span>
        </button>
    </div>
    

     
</div>


<style>

</style>