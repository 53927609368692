<script>
import {
    createEventDispatcher, onMount
} from "svelte"
const dispatch = createEventDispatcher();
import configs from "../../config"
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
import Notification from "../../components/Notification.svelte"
import AddBtn from "../../components/addButton/AddBtn.svelte";
let phone, email, firstName, lastName, isPersian = true,
    imei, expiresIn, plan
let planMenu, showPlanMenu = false, devicee = true , device = "android"
let messageReceived, messageStatus, result , password
const addUser = () => {

    let data = JSON.stringify({
        phone,
        email,
        firstName,
        lastName,
        isPersian,
        imei,
        expiresIn,
        plan,
        device , 
        password
    })
    
    if (email && phone && firstName && lastName && isPersian)
        fetch(`${configs.baseUrl}api/authentication/register`, {
            method: "POST",
            body: data,
            headers: {
                "Content-Type": "application/json",
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        }).then(async (res) => {
            messageReceived = true
            let st = res.status
            res = await res.json()
            console.log(st);
            if (st < 300)

            {
                     messageStatus = 200,
                    result = "کاربر با موفقیت افزدده شد"
            } else {
                messageStatus = 400,
                    result = "خطایی رخ داد"
            }
            setTimeout(() => {
                messageReceived = false
            }, 4000);
        })

}
let plans = [] , plansReceived = false
onMount(()=> {
    fetch(configs.baseUrl + "api/plans/getPlans",{
        headers:{
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    }).
    then(async (res) => {
        res = await res.json();
        if (res) plans = [...res];
        console.log(plans);
        plansReceived = true
    })
})
</script>

{#if messageReceived}
{#if messageStatus > 299}
<Notification message={result}  color = "tomato"></Notification>
{:else}
<Notification color="#43ff11" message={result}></Notification>
{/if}

{/if}
<div class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4" style=" margin-top: 8rem; overflow: hidden; margin-bottom: 3rem;border-radius : 16px; "  >
    <div class="w-full md:w-6/12 p-5">
        <label for="symbol">نام کاربری (شماره همراه)</label>
        <input type="text" id="symbol" bind:value="{phone}"
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="symbol">ایمیل</label>
        <input type="email" validate id="symbol" bind:value="{email}"
        class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="symbol">نام</label>
        <input type="text"  id="symbol" bind:value="{firstName}"
        class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="symbol">نام خانوادگی</label>
        <input type="text" id="symbol" bind:value="{lastName}"
        class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="symbol">IMEI</label>
        <input type="text" id="symbol" bind:value="{imei}"
        class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="symbol">رمز عبور</label>
        <input type="text"  id="symbol" bind:value="{password}"
        class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>


    <div class="w-full md:w-6/12 p-5 h-auto">
        <p>انتخاب پلن</p>
        <div class="relative mt-2 text-left">
            <div>
                <button type="button"
                    on:click="{()=> showPlanMenu = !showPlanMenu}"

                    class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-100 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:bg-white focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="menu-button" aria-expanded="true" aria-haspopup="true">
                    پلن
                    <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                </button>
            </div>

            {#if showPlanMenu }
            <div class=""  style="">

                <div  style="height : 400px;" bind:this="{planMenu}" class=" overflow-y-auto origin-top-right absolute right-0 w-full mt-2  rounded-md shadow-lg 
                    bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">

                    <div class="py-1 overflow-y-auto" role="none" style="height: auto;">
                        {#each plans as r}
                        <div style="cursor: pointer;"
                            on:click="{()=> {plan = r._id ;showPlanMenu = false ; console.log(plan);
                            }}" class="text-gray-700 block px-4 py-2 text-sm"
                            role="menuitem" tabindex="-1" id="menu-item-0">{r.name}</div>
                        {/each}
                    </div>
                </div>
            </div>
            {/if}
        </div>
    </div>
    <div class="w-full md:w-6/12 p-5">
        <label for="symbol">تاریخ انقضا</label>
        <input type="date" id="symbol"  on:change="{(e)=> expiresIn = new Date(e.target.value).getTime().toString()}"
        class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>
    <div class="md:w-6/12 w-full p-5">
        <p>دستگاه</p>
        <div style="display: inline-block;">
            <label class="inline-block items-center">
                <input type="radio" class="form-radio" name="forPersian"
                    on:change="{()=> {device = "ios" ;}}" checked={devicee} group={devicee}>
                <span class="ml-2">َANDROID</span>
            </label>
        </div>
        <div style="display: inline-block;">
            <label class="inline-block items-center">
                <input type="radio" class="form-radio" name="forPersian"
                    on:change="{()=> {device = 'android' ;}}" checked={!devicee} group={devicee}>
                <span class="ml-2">IOS</span>
            </label>
        </div>
    </div>

        <div class="w-full md:w-6/12 p-5">
        <label for="symbol">کاربر ایرانی</label>
        <input type="checkbox" id="symbol" bind:value="{isPersian}" checked={isPersian} style="width: 1rem ; height: 1rem"
        class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>
    

    <div class="w-full p-2" style="padding-bottom: 3rem">
        <!-- <button
            style="background-color: blueviolet; width:100% ;  cursor: pointer;"
            class="rounded  text-white w-full py-2 text-center"
            on:click="{addUser}">افزودن کاربر</button> -->
        <AddBtn  on:click="{addUser}">افزودن کاربر</AddBtn>    
    </div>

</div>
<div class="" style="height: 1rem;"></div>


<style>
    @import url('https://v1.fontapi.ir/css/Estedad');
    p , button , div , input , label{
        font-family: Estedad, sans-serif;
        
    }
</style>