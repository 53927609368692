<script>
import {
    onMount
} from "svelte";

import Notification from "../../components/Notification.svelte";
import configs from "../../config";
import InsertDiscount from "./InsertDiscount.svelte";
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
let loaded = false
let searchItem = ""
let users = false,
    usersEdit = false,
    channels = false,
    channelsEdit = false,
    posts = false,
    postsEdit = false,
    signals = false,
    signalsEdit = false,
    payments = false,
    paymentsEdit = false,
    plans = false,
    email = false ,
    plansEdit = false,
    discount = false,
    editDiscount = false,
    reports = false,
    lottery = false,
    Robotpayment = false,
    useExcel = false

let userReceived = false
let foundUser = {},
    selectedCat
let messageReceived = false,
    messageStatus = 0,
    message = "",
    color = "",
    result = ""
let cats = [],
    subCat

onMount(() => {
    loaded = true
    fetch(`${configs.baseUrl}api/infos/getCategories`,{
        headers:{"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    })
        .then(async (res) => {
            let data = await res.json()
            console.log(data);
            cats = [...data]
        })
})
const searchUser = () => {
    if (searchItem.length > 0) {
        fetch(`${configs.baseUrl}api/users/${searchItem}/getUser`,{
            headers:{"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
        })
            .then(async (res) => {
                if (res.status < 300) {

                    res = await res.json()
                    foundUser = res.data
                    userReceived = true
                    userReceived = userReceived
                    let p = foundUser.priviliages
                    if (typeof p[0] == "boolean") users = p[0]
                    if (typeof p[1] == "boolean") usersEdit = p[1]
                    if (typeof p[2] == "boolean") channels = p[2]
                    if (typeof p[3] == "boolean") channelsEdit = p[3]
                    if (typeof p[4] == "boolean") posts = p[4]
                    if (typeof p[5] == "boolean") postsEdit = p[5]
                    if (typeof p[6] == "boolean") signals = p[6]
                    if (typeof p[7] == "boolean") signalsEdit = p[7]
                    if (typeof p[8] == "boolean") payments = p[8]
                    if (typeof p[9] == "boolean") paymentsEdit = p[9]
                    if (typeof p[10] == "boolean") plans = p[10]
                    if (typeof p[11] == "boolean") plansEdit = p[11]
                    if (typeof p[12] == "boolean") email = p[12]
                    if (typeof p[13] == "boolean") discount = p[13]
                    if (typeof p[14] == "boolean") editDiscount = p[14]
                    if (typeof p[15] == "boolean") reports = p[15]
                    if (typeof p[16] == "boolean") lottery = p[16]
                    if (typeof p[17] == "boolean") Robotpayment = p[17]
                    if (typeof p[18] == "boolean") useExcel = p[18]

                    console.log(users);
                } else {
                    result = "کاربر پیدا نشد"
                    messageReceived = true
                    messageStatus = 400
                    color = "tomato"
                    setTimeout(() => {
                        messageReceived = false
                    }, 4000);
                }
            })
    }
}
let cat
const addCat = () => {
    if (cat != undefined || cat.length > 0)
        fetch(`${configs.baseUrl}api/infos/${cat}/addCategory`,{
            headers: {
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
        .then(async (res) => {
            if (res.status < 300)
                alert("DONE")
        })
}
const addSubCat = () => {
    if (subCat != undefined || subCat.length > 0)
        fetch(`${configs.baseUrl}api/infos/${selectedCat}/${subCat}/addSubCategory`,{
            headers: {
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
        .then(async (res) => {
            if (res.status < 300) {
                alert("Done");
                subCat = undefined
            }
        })
}
const addAdmin = () => {
    let data = JSON.stringify([users, usersEdit, channels, channelsEdit, posts,
        postsEdit, signals, signalsEdit, payments, paymentsEdit, plans, plansEdit,email,
        discount,reports,editDiscount,lottery,Robotpayment,useExcel
    ])
    console.log(data);
    fetch(`${configs.baseUrl}api/authentication/${foundUser._id}/addAdmin`, {
            method: "PATCH",
            body: data,
            headers: {
                "Content-Type": "application/json",
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
        .then(async (res) => {
            if (res.status < 300)
                alert("DONE")
            else {
                res = await res.json()
                console.log(res);
            }
        })
}
</script>

{#if messageReceived}
{#if messageStatus > 299}
<Notification message={result}  color = "tomato"></Notification>
{:else}
<Notification color="#43ff11" message={result}></Notification>
{/if}
{/if}
{#if loaded}
<div class=" md:mt-50 z-10 " style="padding-bottom: 2rem;">
    <div class="w-full  p-5 " style="padding: 3rem 1rem; margin-top: 7rem ; border-radius: 8px;background-color: #fff">
        <label for="" style="display: block;">افزودن ادمین</label>
        <input type="text" id="coin" bind:value="{searchItem}"

            placeholder="نام کاربری ( شماره تلفن )"
            class="shadow mt-2 appearance-none border border-red-500 rounded
            w-6/12 py-2 px-5 text-gray-700  leading-tight focus:outline-none focus:shadow-outline">
        <!-- svelte-ignore a11y-missing-attribute -->
        <a on:click="{searchUser}"
            style="background-color: #33ff11; color: #343434; font-weight: bold; padding: 0.6rem 1.38rem ; cursor: pointer;  border-radius: 8px;" >جستجو</a>
        {#if userReceived}
        <div class="w-full flex flex-wrap my-5">
            <div class="w-full my-5" style="margin: 1rem 0">
                <p class="text-center  text-bold font-bold" style="font-size: 1.4rem; color: tomato">{foundUser.phone}</p>
                <p class="text-center  text-bold font-bold" style="font-size: 1.4rem; color: tomato">{foundUser.firstName + " " +foundUser.lastName}</p>
            </div>

            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">مشاهده کاربران</label>
                <input type="checkbox"   id="" bind:checked="{users}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                <label for="">تغیرات کاربران</label>
                <input type="checkbox" id="" bind:checked="{usersEdit}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
            </div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">مشاهده کانال ها</label>
                <input type="checkbox" id="" bind:checked="{channels}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                <label for="">تغیرات کانال ها</label>
                <input type="checkbox" id="" bind:checked="{channelsEdit}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">

            </div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">مشاهده پست ها</label>
                <input type="checkbox" id="" bind:checked="{posts}"  style="width: 2.6rem ; height: 2.6rem  ; margin: 0.7rem auto ;  "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                <label for="">تغیرات پست ها</label>
                <input type="checkbox" id="" bind:checked="{postsEdit}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">

            </div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">مشاهده كد تخفيف</label>
                <input type="checkbox" id="" bind:checked="{discount}"  style="width: 2.6rem ; height: 2.6rem  ; margin: 0.7rem auto ;  "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                <label for="">تغیرات كد تخفيف </label>
                <input type="checkbox" id="" bind:checked="{editDiscount}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">

            </div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">مشاهده  گزارشات</label>
                <input type="checkbox" id="" bind:checked="{reports}"  style="width: 2.6rem ; height: 2.6rem  ; margin: 0.7rem auto ;  "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                

            </div>
            <div class="" style="border: 1px solid gray; height: 1px; width: 80% ; margin: 1rem auto"></div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">مشاهده پلن ها</label>
                <input type="checkbox" id="" bind:checked="{plans}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                <label for="">تغیرات پلن ها</label>
                <input type="checkbox" id="" bind:checked="{plansEdit}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
            </div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">مشاهده سیگنال ها</label>
                <input type="checkbox" id="" bind:checked="{signals}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                <label for="">تغیرات سیگنال ها</label>
                <input type="checkbox" id="" bind:checked="{signalsEdit}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">

            </div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">مشاهده پرداخت ها</label>
                <input type="checkbox" id="" bind:checked="{payments}"  style="width: 2.6rem ; height: 2.6rem  ; margin: 0.7rem auto ;  "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
                <label for="">تغیرات پرداخت ها</label>
                <input type="checkbox" id="" bind:checked="{paymentsEdit}"  style="width: 2.6rem ; height: 2.6rem ; margin: 0.7rem auto ;   "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">

            </div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">ویرایش ایمیل کاربر</label>
                <input type="checkbox" id="" bind:checked="{email}"  style="width: 2.6rem ; height: 2.6rem  ; margin: 0.7rem auto ;  "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
              
            </div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">قرعه كشي</label>
                <input type="checkbox" id="" bind:checked="{lottery}"  style="width: 2.6rem ; height: 2.6rem  ; margin: 0.7rem auto ;  "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
              
            </div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">پرداخت ربات</label>
                <input type="checkbox" id="" bind:checked="{Robotpayment}"  style="width: 2.6rem ; height: 2.6rem  ; margin: 0.7rem auto ;  "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
              
            </div>
            <div class="w-6/12 md:w-4/12 text-center">
                <label for="">اکسل شماره ها</label>
                <input type="checkbox" id="" bind:checked="{useExcel}"  style="width: 2.6rem ; height: 2.6rem  ; margin: 0.7rem auto ;  "
                    class="shadow mt-2 appearance-none border border-red-500 rounded block py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
              
            </div>
            <div class="" style=" height: 1px; width: 80% ; margin: 1rem auto">
                <button style="border: none ; border-radius: 6px;color: whitesmoke; background-color: blueviolet; padding: 0.4rem 1rem" on:click="{addAdmin}">ذخیره تغیرات </button>
            </div>

        </div>
        {/if}
      
    
    </div>

</div>
{/if}
