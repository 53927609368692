<script>
import {
    createEventDispatcher, onMount
} from "svelte"
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
import configs from "../../config";
import Notification from "../../components/Notification.svelte";
    import AddBtn from "../../components/addButton/AddBtn.svelte";

 let channels = []
let loading = true
const dispatch = createEventDispatcher();
let wallet, price, title, channelss = [],
    qr,
    chColors = [],
    modalChColors = [],
    lastItemSelected = {}
const addToChannelss = (item, index) => {
    isSelected(index)
    const isThere = channelss.some(i => i == item._id)
    if (isThere) {
        channelss = channelss.filter(i => i != item._id)
        console.log(channelss);
    } else {
        channelss = [...channelss, item._id]
        console.log(channelss);
    }
}
let src = "",
    notifyMessage, notifyColor, notifyReceived
const submitData = () => {
    loading = true
    console.log(wallet, price, channelss);
    if (wallet != undefined && wallet != null && price != undefined && price != null && channelss.length != 0 && qr != undefined) {
        let data = {
            name: title,
            wallet,
            price,
            channels: channelss
        }
        let formData = new FormData()
        formData.append("name", title)
        formData.append("wallet", wallet)
        formData.append("price", price)
        formData.append("channels", channelss)
        formData.append("file", qr)
        fetch(`${configs.baseUrl}api/plans/addPlan`, {
            method: "POST",
            body: formData,
            headers:{"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}

        }).then(async (res) => {
            loading = false
            if (res.status < 300) {

                notifyMessage = "پلن با موفقیت افزوده شد "
                notifyColor = "#43ff11"
                notifyReceived = true
               setTimeout(() => {
                   notifyColor = false
               }, 4000);

            } else {
                notifyMessage = "خطایی رخ داد"
                notifyColor = "tomato"
                notifyReceived = true
               setTimeout(() => {
                   notifyMessage = false
               }, 4000);
            }
        })
    }

}
const isSelected = (index) => {
    chColors[index] = !chColors[index]
}

onMount(()=> {
    fetch(`${configs.baseUrl}api/infos/getchannelnames`,{
        headers:{"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    })
        .then(async (res) => {
            res = await res.json()
            channels = res
         
        })
})
</script>

{#if notifyReceived}
<Notification color = {notifyColor}  message = {notifyMessage}></Notification>
{/if}

<div class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4" style=" margin-top: 8rem; overflow: hidden; margin-bottom: 3rem;border-radius : 16px; " >
    <div class="md:w-6/12 w-full p-5" >
        <label for="name">عنوان</label>
        <input type="text" id="name" bind:value="{title}"
        class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>
    <div class="md:w-6/12 w-full p-5">
        <label for="name">مبلغ</label>
        <input type="text" id="name" bind:value="{price}"
        class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
    </div>
    <div class="md:w-6/12 w-full p-5">
        <label for="name">آدرس کیف پول</label>
        <input type="text" id="name" bind:value="{wallet}"
        class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
        </div>
        <div class="md:w-6/12 w-full p-5" >
            <div class="flex flex-wrap">
                <div class="w-full ">
                    <p class="text-bold">افزودن کانال ها</p>
                </div>
    
                <div class="w-full" style="padding: 1rem 0 ">
                    <div class="flex flex-wrap">
    
                        {#each channels as itemm, index}
                        {#if chColors[index]}
                        <div class=""  on:click={addToChannelss(itemm , index)}
                            style="padding: 0.4rem 1rem ; margin: 0.1rem 0.5rem; min-width: 25%; text-align: center;  cursor: pointer; background-color: lime ; cursor: pointer;
                            border-radius: 8px ;">{itemm.name}</div>
                        {:else}
                        <div class=" " on:click={addToChannelss(itemm , index)}
                            style="padding: 0.4rem 1rem ; margin: 0.1rem 0.5rem; min-width: 25%; text-align: center; cursor: pointer;  background-color: #ded ;
                            border-radius: 8px ;">{itemm.name}</div>
                        {/if}
                        {/each}
    
                    </div>
    
                </div>
    
            </div>
    
        </div>
    <div class="md:w-6/12 w-full p-5">
        <label id="img" style="background-color: blueviolet;float: right; margin-top: 0.5rem;"
            class=" hover:bg-indigo-dark text-center text-white font-bold py-2 rounded  px-4 w-full items-center">
            انتخاب عکس
            <input style="visibility: hidden;" id="img" on:change="{e=>{
            qr = e.target.files[0] ; console.log(qr);
            ;  src = URL.createObjectURL(e.target.files[0])
            }}"
            accept="image/*" class="cursor-pointer absolute block opacity-0 pin-r pin-t" type="file"a
            name="vacancyImageFiles">

        </label> </div>
    <div class="w-full md:w-6/12 p-5 " style="height: 16rem;">
        <div class=" inline-block mt-3" style="width: 100%;
            border: 2px dashed gray; border-radius: 8px;
            background-image: url({src}) ; height: 100%; background-size: cover ; background-repeat: round; " >

        </div>
    </div>
    <div class="w-full p-5 ">
        <AddBtn on:click={submitData}> ارسال پلن</AddBtn>
        <!-- <button on:click={submitData}
            style="margin: 0.5rem auto 1.5rem auto; display: inherit;background-color: blueviolet; width:96% ; cursor: pointer;"
            class="rounded text-white w-full py-2 text-center"   > ارسال پلن</button> -->
    </div>
 
</div>

<style>
    @import url('https://v1.fontapi.ir/css/Estedad');
    p  , div , input , label{
        font-family: Estedad, sans-serif;
        
    }
</style>
