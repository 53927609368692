<script>
let a = false
import {
    link
} from "svelte-routing";

// core components
import NotificationDropdown from "components/Dropdowns/NotificationDropdown.svelte";
import UserDropdown from "components/Dropdowns/UserDropdown.svelte";
import {
    onMount
} from "svelte";
import {showSide , user} from '../../store'

let collapseShow = "hidden";

function toggleCollapseShow(classes) {
    collapseShow = classes;
}
let pr = [],
    username = ""

    // user.priviliages.update(d => d=testt)
onMount(() => {
    let user = JSON.parse(localStorage.getItem("user"))

    pr = [...user.priviliages]
    console.log("pr" , pr )
    username = user.phone
})



// onMount(()=>{
//     showSide.subscribe(d=>{
//         show = d
//     })
// })


export let location
let signals = false,
    channels = false,
    users = false,
    posts = false,
    plans = false,
    media = false,
    discount = false

    let sidebar = [
        {
            
        }
    ]


</script>

<nav
    id="nav"
    style="right:0 !important ; position: fixed;"
    class=" sidebar md:right-0   rounded-tr-lg rounded-tl-sm  md:block h-auto md:bottom-0  top-0 w-full md:h-full md:overflow-y-auto
    shadow-xl flex flex-wrap items-center justify-between  md:w-64 z-10 py-2 px-6"
    >
    <!-- <button  style="position : absolute ; top : 10px; right:5px;" on:click={closeSide}>
        {#if showSide}
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
              </svg>
        {:else}
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                <path fill-rule="evenodd" d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
        {/if}
    </button> -->
    <div id="divside"
        class="md:flex-col md:items-stretch md:h-100 md:flex-nowrap px-0 flex flex-wrap items-center justify-center w-full mx-auto h-auto"
        >
        <!-- Toggler -->
        <button
            class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6 ')}
            >
            <i class="fas fa-bars"></i>
        </button>
        <!-- Brand -->
        <a
            use:link
            class="md:block text-center  md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-medium p-4 px-0"
            href="/admin/dashboard"
            >
            Marvel Trader
        </a>
        <!-- User -->
        <ul class="md:hidden items-center flex flex-wrap list-none text-white">
            <li class="inline-block relative">
                <NotificationDropdown />
            </li>
            <li class="inline-block relative">
                <UserDropdown />
            </li>
        </ul>
        <!-- Collapse -->
        <div
            class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
            >
            <!-- Collapse header -->
            <div
                class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
                >
                <div class="flex flex-wrap">
                    <div class="w-6/12">
                        <a
                            use:link
                            class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-medium p-4 px-0"
                            href="/"
                            >
                            Marvel trader
                        </a>
                    </div>
                    <div class="w-6/12 flex justify-end">
                        <button
                            type="button"
                            class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                            on:click={() => toggleCollapseShow('hidden')}
                            >
                            <i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!-- Form -->
            <form class="mt-6 mb-4 md:hidden">
                <div class="mb-3 pt-0">
                    <input
                        type="text"
                        placeholder="Search"
                        class=" px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                        />
                </div>
            </form>

            <!-- Divider -->
            <hr class="my-4 md:min-w-full" />
            <!-- Heading -->
            <h6
                class="md:min-w-full text-blueGray-500 text-sm uppercase font-medium block pt-1 pb-4 no-underline"
                >
                Admin panel pages
            </h6>
            <!-- Navigation -->

            <ul class="md:flex-col md:min-w-full flex flex-col list-none estedad">
                <li class=" {location.href.indexOf('/admin/dashboard') !== -1 ? "select my-1 px-3 estedad" : "notSelect my-1 estedad "} ">
                    <a
                        use:link
                        href="/admin/dashboard"
                        class="text-md uppercase py-3 font-sans font-medium  block estedad "
                        >
                        <i
                            class="fas fa-tv mr-2 text-sm  "
                            ></i>
                        داشبورد
                    </a>
                </li>
                
                {#if pr[0]}
                <li class="items-center my-1">

                    <!-- svelte-ignore a11y-missing-attribute -->
                    <a
                        style="cursor: pointer;"
                        class="text-md uppercase py-3 font-sans font-medium  block notSelect "
                        on:click="{()=> users=!users}"
                        >
                        <i
                            class="fas fa-user mr-2 text-sm"
                            ></i>
                        کاربران
                    </a>
                    <div class="" style="height: {users ? '5rem' : '0rem'};padding: 0 1rem ;transition: 0.3s all ;" >
                        {#if pr[1]}
                        <a
                            use:link
                            href="/admin/insertUser"
                            style="display: {users ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block   {location.href.indexOf('/admin/insertUser') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            افزودن
                        </a>
                        {/if}
                        <a
                            use:link
                            href="/admin/users"
                            style="display: {users ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block   {location.href.indexOf('/admin/users') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            ویرایش
                        </a>
                    </div>

                </li>
                {/if}
                {#if pr[2]}
                <li class="items-center my-1">
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <a
                        style="cursor: pointer;"
                        on:click="{()=> channels = !channels}"
                        class="text-md uppercase py-3 font-sans font-medium  block notSelect "
                        >
                        <i
                            class="fas fa-tools mr-2 text-sm "
                            ></i>
                        کانال ها
                    </a>
                    <div class="" style="height: {channels ? '8rem' : '0rem'};padding: 0 1rem ;transition: 0.3s all ;" >
                        {#if pr[3]}
                        <a
                            use:link
                            href="/admin/insertChannel"
                            style="display: {channels ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/insertChannel') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            افزودن
                        </a>
                        {/if}
                        <a
                            use:link
                            href="/admin/channels"
                            style="display: {channels ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/channels') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            ویرایش
                        </a>
                        <a
                            use:link
                            href="/admin/privates"
                            style="display: {channels ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/privates') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            خصوصی
                        </a>
                    </div>
                </li>
                {/if}
                {#if pr[4]}

                <li class="items-center my-1">
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <a
                        on:click="{()=> posts = !posts}"
                        style="cursor: pointer;"
                        class="text-md uppercase py-3 font-sans font-medium  block notSelect "
                        >
                        <i
                            class="fas fa-clipboard-list mr-2 text-sm notSelect"
                            ></i>
                        پست ها

                    </a>
                    <div class="" style="height: {posts ? '5rem' : '0rem'};padding: 0 1rem ;transition: 0.3s all ;" >
                        {#if pr[5]}
                        <a
                            use:link
                            href="/admin/insertPost"
                            style="display: {posts ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/insertPost') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            افزودن
                        </a>
                        {/if}
                        <a
                            use:link
                            href="/admin/posts"
                            style="display: {posts ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/posts') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            ویرایش
                        </a>
                    </div>
                </li>
                {/if}
               
                {#if pr[6]}
                <li class="items-center my-1">
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <a
                        on:click="{()=> signals=!signals}"
                        style="cursor: pointer;"
                        class="text-md uppercase py-3 font-sans font-medium  block notSelect "
                        >
                        <i
                            class="fas fa-signal mr-2 text-sm "
                            ></i>
                        سیگنال ها
                    </a>
                    <div class="" style="height: {signals ? '5rem' : '0rem'};padding: 0 1rem ;transition: 0.3s all ;" >
                        {#if pr[7]}
                        <a
                            use:link
                            href="/admin/insertSignal"
                            style="display: {signals ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/insertSignal') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            افزودن
                        </a>
                        {/if}
                        <a
                            use:link
                            href="/admin/signals"
                            style="display: {signals ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/signals') !== -1 ? "select  my-2 px-3" : "notSelect my-2"} "
                            >
                            ویرایش
                        </a>
                    </div>
                </li>
                {/if}
                {#if pr[8]}
                <li class="items-center my-1">
                    <a
                        use:link
                        href="/admin/payments"
                        class="text-md uppercase py-3 font-sans font-medium  block {location.href.indexOf('/admin/payments') !== -1 ? "select my-2 px-3" : "notSelect my-2"} "
                        >
                        <i
                            class="fas fa-credit-card mr-2 text-sm"
                            ></i>
                        پرداخت ها
                    </a>
                </li>
                {/if}
                
                {#if pr[10]}
                <li class="items-center my-1">
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <a
                        style="cursor: pointer;"
                        on:click="{()=> plans = !plans}"
                        class="text-md uppercase py-3 font-sans font-medium  block notSelect "
                        >
                        <i
                            class="fas fa-clipboard-list mr-2 text-sm "
                            ></i>
                        پلن ها
                    </a>
                    <div class="" style="height: {plans ? '5rem' : '0rem'};padding: 0 1rem ;transition: 0.3s all ;" >
                        {#if pr[11]}
                        <a
                            use:link
                            href="/admin/insertPlan"
                            style="display: {plans ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/insertPlan') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            افزودن
                        </a>
                        {/if}
                        <a
                            use:link
                            href="/admin/plans"
                            style="display: {plans ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/plans') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            ویرایش
                        </a>
                    </div>
                </li>
                {/if}
                {#if pr[13]}
                <li class="items-center my-1">
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <a
                        on:click="{()=> discount=!discount}"
                        style="cursor: pointer;"
                        class="text-md uppercase py-3 font-sans font-medium  block notSelect"
                        >
                        <i
                            class="fas fas-ticket mr-2 text-sm "
                            ></i>
                         كد تخفيف
                    </a>
                    <div class="" style="height: {discount ? '5rem' : '0rem'};padding: 0 1rem ;transition: 0.3s all ;" >
                        {#if pr[14]}
                        <a
                            use:link
                            href="/admin/insertDiscount"
                            style="display: {discount ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/insertDiscount') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            افزودن
                        </a>
                        {/if}
                        
                        <a
                            use:link
                            href="/admin/Discount-code"
                            style="display: {discount ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/Discount-code') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            ویرایش
                        </a>
                    </div>
                </li>
                {/if}
                {#if pr[15]}
                <li class="items-center my-1">
                    <a
                        use:link
                        href="/admin/reports"
                        class="text-md uppercase py-3 font-sans font-medium  block {location.href.indexOf('/admin/reports') !== -1 ? "select  my-2 px-3" : "notSelect my-2"} "
                        >
                        <i
                            class="fas fa-file mr-2 text-sm "
                            ></i>
                        گزارش ها
                    </a>
                </li>
                {/if}
                <!-- {#if pr[16]}
                <li class="items-center">
                    <a
                        use:link
                        href="/admin/Lottery"
                        class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/Lottery') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
                        >
                        <i
                            class="fas fa-clipboard-list mr-2 text-sm {location.href.indexOf('/admin/Lottery') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
                            ></i>
                         قرعه كشي
                    </a>
                </li>
                {/if} -->
                <!-- {#if pr[17]}
                <li class="items-center">
                    <a
                        use:link
                        href="/admin/Robot-payment"
                        class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/Robot-payment') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}"
                        >
                        <i
                            class="fas fa-clipboard-list mr-2 text-sm {location.href.indexOf('/admin/Robot-payment') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"
                            ></i>
                         پرداخت ربات
                    </a>
                </li>
                {/if} -->

                {#if pr[18]}
                <li class="items-center my-1">
                    <a
                        use:link
                        href="/admin/useExcel"
                        class="text-md uppercase py-3 font-medium block {location.href.indexOf('/admin/useExcel') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                        >
                        <i
                            class="fas fas-file-excel-o mr-2 text-sm "
                            ></i>
                          آپلود اکسل
                    </a>
                </li>
                {/if}
                
                
                <!-- {#if  username == "9912073427"} -->
                <li class="items-center my-1">
                    <a
                        use:link
                        href="/admin/admins"
                        class="text-md uppercase py-3 font-medium block {location.href.indexOf('/admin/admins') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                        >
                        <i
                            class="fas fa-user-plus mr-2 text-sm "
                            ></i>
                        ادمین ها
                    </a>
                </li>
                <!-- {/if} -->
                <li class="items-center my-1">
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <a
                        on:click="{()=> media=!media}"
                        style="cursor: pointer;"
                        class="text-md uppercase py-3 font-medium block notSelect"
                        >
                        <i
                            class="fas fa-clipboard-list mr-2 text-sm"
                            ></i>
                        رسانه ها
                    </a>
                    <div class="" style="height: {media ? '5rem' : '0rem'};padding: 0 1rem ;transition: 0.3s all ;" >
                        <a
                            use:link
                            href="/admin/insertMedia"
                            style="display: {media ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-bold block {location.href.indexOf('/admin/insertMedia') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            افزودن
                        </a>
                        <a
                            use:link
                            href="/admin/media"
                            style="display: {media ? "block" : "none"}; padding-right: 1.5rem "
                            class="text-sm uppercase py-3 font-medium block {location.href.indexOf('/admin/media') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                            >
                            ویرایش
                        </a>
                    </div>
                </li>
                <li class="items-center my-1">
                    <!-- svelte-ignore a11y-missing-attribute -->
                    <a
                        href="/admin/insertCategory"
                        style="cursor: pointer;"
                        class="text-md uppercase py-3 font-medium block {location.href.indexOf('/admin/insertCategory') !== -1 ? "select  my-2 px-3" : "notSelect my-2"}"
                        >
                        <i
                            class="fas fa-object-group mr-2 text-sm "
                            ></i>
                        دسته بندی ها
                    </a>

                </li>
            </ul>
        </div>
    </div>
</nav>



<style>

    .sidebar{
        background-color: white;
        border-radius: 16px;

    }

    .sidebar::-webkit-scrollbar {
        width: 5px;
    }
    
    /* .sidebar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px white;
    } */
    
    .sidebar::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 16px;
    }

    .select{
        color: #009EFA;
        width: 100%;
        height: auto;
        position: relative;
        opacity: 0.8;
        transition: ease-in-out 1s;
    }

    .select::before{
        content: "";
        border-left: #009EFA 4px solid;
        position: absolute;
        left: 0px;
        top: 10px;
        bottom: 10px;
        transition: ease-in-out 1s;
    }

    .notSelect{
        color: black;
    }

    .notSelect:hover{
        color: #009EFA;
    }

    @import url('https://v1.fontapi.ir/css/Estedad');
    .estedad{
      font-family: Estedad, sans-serif;

    }

    a  {
        font-family: Estedad, sans-serif;
        font-size: 16px;

    }
</style>
