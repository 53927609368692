<script>
	
	let buttonProps = {
	class:[$$restProps.class]
	}
</script>
	<button on:click
			on:mouseover
			on:mouseenter
			on:mouseleave
		{...buttonProps}>
			<slot/>
	</button>

<style>
    button{
        padding: 10px;
        width: 100%;
        color: white;
        border-radius: 16px;
        background: #673AB7;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #512DA8, #673AB7);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #512DA8, #673AB7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    button:hover{
        background: #5e34a6;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #512DA8, #5e34a6);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #512DA8, #5e34a6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }
</style>    