<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";
  import toast, { Toaster } from 'svelte-french-toast';
  import Tailwindcss from "./Tailwindcss.svelte";

  // Admin Layout
  import Admin from "./layouts/Admin.svelte";
  // Auth Layout
  import Auth from "./layouts/Auth.svelte";

  // No Layout Pages
  import Index from "./views/Index.svelte";
  import Landing from "./views/Landing.svelte";
  import Profile from "./views/Profile.svelte";
  
  export let url = "";
</script>

<svelte:head>
	<title>مارول ترید</title>
</svelte:head>
<Tailwindcss />
<Toaster/>
<Router url="{url}">
  <!-- admin layout -->
  <Route path="admin/*admin" component="{Admin}" />
  <!-- auth layout -->
  <Route path="/*auth" component="{Auth}" />
  <!-- no layout pages -->
  <Route path="landing" component="{Landing}" />
  <Route path="profile" component="{Profile}" />
  <Route path="/" component="{Index}" />
</Router>




<style>
  @import url('https://v1.fontapi.ir/css/Estedad');
  :global(body) {
		font-family: Estedad, sans-serif;
    --tw-bg-opacity: 1;
    background-color: rgba(244, 244, 245, var(--tw-bg-opacity));
	}

  :global(div,p,ul,li,table,tr,input,button , label , select , form , h2 ,h1,h3,h4,h5,h6,span) {
		font-family: Estedad, sans-serif;
	}


  :global(th) {
		font-family: Estedad, sans-serif;
    font-size: 18px;
	}

  :global(td  p) {
		font-family: Estedad, sans-serif;
    font-size: 16px;
	}


</style>
