<script>
import {
    onMount,
    tick
} from "svelte";

import InsertSignal from "../../components/InsertSignal.svelte";
import Notification from "../../components/Notification.svelte";
import Spinner from "../../components/Spinner.svelte"
import SignalPaginator from "../../components/SignalPaginator.svelte"
let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
import {
    signalPageNumber,
    totalSignals
} from "../../store"
let entry;
let result, messageReceived = false,
    messageStatus
let postReceived = false,
    modal = false, 
    active = false;
let signals = [],
    channelsName = []
let item = {},
    addSignal = false
let total = 0,
    src
let type = "text",
    data, caption, isFollowable = false,
    coin, symbol, externalLink, targets = [],
    publishedIn, file

import configs from "../../config"
import Paginator from "../../components/Paginator.svelte";
import SignalEdit from "../../components/SignalEdit.svelte";
const openModal = (data) => {
    item = data;
    modal = true;
}
const deleteItem = (item) => {
    fetch(`${configs.baseUrl}api/signals/${item._id}/deleteSignal`, {
            method: "DELETE",
            headers:{
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            }
        })
        .then(res => {
            if (res.status < 300) {
                messageReceived = true
                result = "سیگنال با موفقیت حذف شد"
                messageStatus = 200
                signals = signals.filter(i => i != item)
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            } else {
                messageReceived = true
                result = "خطایی رخ داد"
                messageStatus = 400
                setTimeout(() => {
                    messageReceived = false
                    result = ""
                    messageStatus = 0
                }, 4000);
            }
        })
}
let pr = false
onMount(() => {
    fetch(`${configs.baseUrl}api/infos/getchannelnames`,{
        headers:{
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
        }
    })
        .then(async (res) => {
            res = await res.json()
            channelsName = res
            console.log(channelsName);
        })
    signalPageNumber.subscribe(d => {
        fetchPosts(10 , d , sort , sm)
    })
     pr = JSON.parse(localStorage.getItem("user")).priviliages[7]
})

let missingMessage = "مقادیر ورودی را بررسی کنید"

const sendSignal = () => {
    let form = new FormData()
    console.log(coin, symbol, publishedIn, caption);
    if (coin == undefined || symbol == undefined || publishedIn == undefined || caption == undefined) {
        missingData = true
        setTimeout(() => {
            messageReceived = false
            messageStatus = missingMessage
        }, 4000);
        return
    }
    form.append("type", type)
    form.append("isFollowable", isFollowable)
    form.append("caption", caption)
    form.append("coin", coin)
    form.append("symbol", symbol)
    form.append("externalLink", externalLink)
    form.append("entry", entry)
    form.append("active", active)

    if (isFollowable) {
        form.append("targets", targets)
    }
    if (type == "text") {
        if (data == null || data == undefined) {
            missingData = true
            console.log("!");
            setTimeout(() => {
                missingData = false
            }, 4000);

            return
        }
        form.append("data", data)
        let finalData = JSON.stringify({
            type,
            isFollowable: isFollowable,
            caption,
            coin,
            symbol,
            externalLink,
            entry,
            data,
            targets
        })
        let result = ""

        fetch(`${configs.baseUrl}api/signals/${publishedIn}/addSignal`, {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            },
            body: finalData
        }).then(async (res) => {
            messageReceived = true
            messageStatus = res.status

            if (res.status < 300) {
                result = "سیگنال با موفقیت افزوده شد"

                signals = [{
                    type,
                    isFollowable: isFollowable,
                    caption,
                    coin,
                    symbol,
                    externalLink,
                    entry,
                    data,
                    targets
                }, ...signals]
            } else {
                result = "خطایی رخ داد"
            }
            setTimeout(() => {
                messageReceived = false
            }, 4000);
        })
    } else {

        if (file == undefined || file == null) {
            missingData = true
            console.log("!!");
            setTimeout(() => {
                missingData = false
            }, 4000);
            return
        }

        form.append("file", file)
        fetch(`${configs.baseUrl}api/signals/${publishedIn}/addSignal`, {
            method: "POST",
            headers:{
                "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
            },
            body: form
        }).then(async (res) => {

            messageReceived = true
            messageStatus = res.status
            if (res.status < 300) {
                result = "سیگنال با موفقیت افزوده شد"
            } else {
                result = "خطایی رخ داد"
            }
            setTimeout(() => {
                messageReceived = false
            }, 4000);
        })
    }

}
let sort = "createdAt" , sm = -1
const fetchPosts = (num, skip, sort , sm) => {
    fetch(`${configs.baseUrl}api/signals/${num}/${skip}/${sort}/${sm}/getBatchSignals`,{headers:{
        "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
    }}).then(async (res) => {
        res = await res.json()
        signals = res.signals
        totalSignals.update(d => d = res.totalSignals)
        postReceived = true
    })
}


const sendEdit = (e) => {
    
    //TODO edit kardan notify add ham hast + voice editSignal
    console.log(e);
    let data = {
        ...e.detail.data
    }
    data = JSON.stringify(data)
    console.log("DATA" , data);
    // console.log(data)
    // delete data._id
    // // console.log(data)
    // let  fd  = new FormData()
    // Object.keys(data).forEach(item => {
    //     if(item == "targets")
    //     {
    //         fd.append("targets" , JSON.stringify(data[item]))
    //     }
    //     else
    //     fd.append(item , data[item]) 
    // })
    fetch(`${configs.baseUrl}api/signals/${e.detail.data._id}/editSignal`, {
        method: "PATCH",
        
        headers: {
            "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei,    
                "Content-Type": "application/json"
        },
        body: data
    }).then(async(res) => {
        messageReceived = true
        messageStatus = res.status
        if (res.status < 300) {
            result = "سیگنال با موفقیت ویرایش شد"
            let item = signals.find(i => i._id == JSON.parse(data)._id)
            let d = await res.json()
            data = JSON.parse(data)
            Object.keys(item).forEach(k => {
                if (k != "_id")
                    item[k] = data[k]
            })
            console.log(item);
            let index = signals.indexOf(item)

            signals = [...signals]

        } else {
            result = "خطایی رخ داد"
        }
        modal = false
        setTimeout(() => {
            messageReceived = false
        }, 4000);
    })

    

}
const doFinish = (e) => {
    result = e.detail.result
    messageReceived = true
    messageStatus = e.detail.messageStatus
    if (messageStatus < 300) {
        signals = [e.detail.data, ...signals]
    }
    setTimeout(() => {
        messageReceived = false
        messageStatus = missingMessage
    }, 4000);
}
const doMissing = () => {
    missingData = true
    setTimeout(() => {
        messageReceived = false
        messageStatus = missingMessage
    }, 4000);
}
const toggleAddSignal = ()=> addSignal = !addSignal
let roomMenu, showRoomMenu = false
const doSort = (data) => {
    let temp = data.split("/")
    sort = temp[0]
    sm = +temp[1]

    signalPageNumber.subscribe(d => {
        fetchPosts(10, d, sort, sm)
    })
}
</script>

{#if modal}
<SignalEdit externalData={item} on:data={sendEdit} on:close={()=> {modal = false}}  channelsName = {channelsName}></SignalEdit>
{/if}

{#if messageReceived}
{#if messageStatus > 299}
<Notification message={result}  color = "tomato"></Notification>
{:else}
<Notification color="#43ff11" message={result}></Notification>
{/if}

{/if}

<div class=" md:mt-50 z-10 " style="padding-bottom: 2rem; margin-top: 10rem">
  <div class="w-full" style=" ">
    
  <div class="" style="float:left ; margin-bottom: 1rem;">
    <div class="" style="float: left;">
        <label for="sort" style="display: block; margin-bottom: 0.2rem; ">  مرتب سازی</label>
        {#if isFollowable}
            <div class="w-full block md:w-6/12 p-5">
                <label for="entry">قيمت ورود </label>
                <input type="number" id="entry" bind:value={entry}
                    class="shadow mt-2 appearance-none border border-red-500 rounded  w-full py-2 px-5 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
            </div>
        {/if}
        <!-- svelte-ignore a11y-no-onchange -->
        <select on:change={(e)=> doSort(e.target.value)} name="" style="display: inline-block;border-radius : 16px" id="sort" class="border-0 shadow">
            <option style="text-align: right;" value="createdAt/-1">  تاریخ ثبت نام/  نزولی</option>
            <option style="text-align: right;" value="createdAt/1"> تاریخ ثبت نام / سعودی</option>
            <option style="text-align: right;" value="isFollowable/-1"> دنبال شوندگی  / نزولی</option>
            <option style="text-align: right;" value="isFollowable/1"> دنبال شوندگی  / سعودی</option>
            <option style="text-align: right;" value="coin/-1"> ارز / نزولی</option>
            <option style="text-align: right;" value="coin/1"> ارز / سعودی</option>
            <option style="text-align: right;" value="active/-1"> وضعیت  / نزولی</option>
            <option style="text-align: right;" value="active/1"> وضعیت  / سعودی</option>
           
        </select>
    </div>
</div>
  </div>
    <div class="channel-table">

      
        <section class="container mx-auto w-full  font-mono" style="">
            <div class="w-full mb-8 overflow-hidden rounded-lg shadow-lg">
                <div class="w-full overflow-x-auto">
                    <table class="w-full">
                        <thead>
                            <tr class="text-sm bg-white font-medium tracking-wide text-left text-black  uppercase border-b shadow" style=" ">
                                <th class="px-2 py-3 text-right">عنوان</th>
                                <th class="px-2 py-3 text-right">دنبال شوندگی</th>
                                <th class="px-2 py-3 text-right">وضعیت</th>
                                <th class="px-2 py-3 text-right">ارز</th>
                                <th class="px-2 py-3 text-right">حذف / بازنویسی</th>
                                <th class="px-2 py-3 text-right">فعال / غيرفعال</th>
                            </tr>
                        </thead>
                        <tbody class="bg-white">
                            {#if postReceived}
                            {#if signals != undefined }
                            {#each signals as item, index}
                            {#if index < 10}
                            <tr class="text-gray-700 border-b shadow">
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{item.caption}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{item.isFollowable ? "دارد" : "ندارد"}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{item.active ? "فعال" : "غیر فعال"}</p>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-3 ">
                                    <div class="flex items-center text-sm">
                                        <div>
                                            <p class="font-semibold text-black">{item.coin} </p>
                                        </div>
                                    </div>
                                </td>

                                <td class=" " style="padding: 0 1rem">
                                    {#if pr}
                                    <i class="fa-edit fas"  style="margin: 0 1rem; cursor: pointer " on:click={openModal(item)} ></i>
                                    <i class="fa-trash fas text-red-500"  style="margin: 0 1rem; cursor: pointer" on:click={deleteItem(item)} ></i>
                                    {/if}
                                </td>
                                <td class=" ">
                                        <div class="w-full">
                                            <div style="display: inline-block;">
                                                <label class="inline-block items-center">
                                                    <input type="checkbox" class="form rounded checked:bg-red-600" name="active"
                                                        on:change="{()=> {sendEdit({detail:{data:{active : true,_id : item._id}}});item.active = true ; console.log(item.active)}}" checked={item.active} group={item.active}>
                                                    <span class="ml-2">فعال</span>
                                                </label>
                                            </div>
                                            <div style="display: inline-block;">
                                                <label class="inline-block items-center">
                                                    <input type="checkbox" class="form rounded" name="active"
                                                        on:change="{()=> {sendEdit({detail:{data:{active : false,_id : item._id}}});item.active = false ;console.log(item.active)}}" checked={!item.active} group={item.active}>
                                                    <span class="ml-2">غير فعال</span>
                                                </label>
                                            </div>
                                            
                                            
                                        </div>
                                </td>
                            </tr>
                            {/if}
                            {/each}
                            {/if}
                            {:else}
                            <div class="w-full" style="background-color: #343434; width: 100%">
                                <Spinner></Spinner>
                            </div>
                            {/if}

                        </tbody>
                    </table>
                </div>
            </div>
            <SignalPaginator total={total}></SignalPaginator>
        </section>

       

    </div>
</div>
