<script>
    import {
        createEventDispatcher, onMount
    } from "svelte"
    let theAdmin = JSON.parse(localStorage.getItem("user"))
    let token = localStorage.getItem("token")
    import configs from "../../config";
    import Notification from "../../components/Notification.svelte";
    import AddBtn from "../../components/addButton/AddBtn.svelte";
    
     let channels = [] , dis = false
    let loading = true
    const dispatch = createEventDispatcher();
    let title,
        file,link

    let src = "",
        notifyMessage, notifyColor, notifyReceived , uploading = false, rotate=0
    const submitBanner = async ()=> {
        if (banner) {
            let formData = new FormData()
            formData.append("file", banner)
            formData.append("link", link)
         
            await fetch(`${configs.baseUrl}api/infos/addbanner`, {
                method: "POST",
                body: formData,
                headers:{
                    "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
                }
                
            }).then(async (res) => {
                loading = false
                dis = false
                uploading = false
                if (res.status < 300) {
    
                    notifyMessage = "بنر با موفقیت افزوده شد "
                    notifyColor = "#43ff11"
                    notifyReceived = true
                   setTimeout(() => {
                       notifyColor = false
                   }, 4000);
    
                } else {
                    notifyMessage = "خطایی رخ داد"
                    notifyColor = "tomato"
                    notifyReceived = true
                   setTimeout(() => {
                       notifyMessage = false
                   }, 4000);
                }
            })
        } 
    }
    const submitData = async () => {
        loading = true
     
        if (title != undefined && file ) {

            let formData = new FormData()
            formData.append("title", title)
            formData.append("category", category)
            formData.append("subCategory", subCategory)
            formData.append("file", file)
            dis = true
            uploading = true
         
          let data =  await fetch(`${configs.baseUrl}api/medias/addMedia`, {
                method: "POST",
                body: formData,
                headers:{
                    "authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei
                }
                
            }).then(async (res) => {
                loading = false
                dis = false
                uploading = false
                if (res.status < 300) {
    
                    notifyMessage = "فایل با موفقیت افزوده شد "
                    notifyColor = "#43ff11"
                    notifyReceived = true
                   setTimeout(() => {
                       notifyColor = false
                   }, 4000);
    
                } else {
                    notifyMessage = "خطایی رخ داد"
                    notifyColor = "tomato"
                    notifyReceived = true
                   setTimeout(() => {
                       notifyMessage = false
                   }, 4000);
                }
            })
         
        }
    
    }

    let subCats =[]
    let category , subCategory
const getSubCat =(cat)=> {
    fetch(`${configs.baseUrl}api/infos/${cat}/getSubCategories`,{
        headers: {"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    })
    .then(async (res)=> {
        let data = await res.json()
        subCats = [...data]
        console.log(subCats);
    })
}
let cats = [""]
let banner, srcc
onMount(async () => {

    fetch(`${configs.baseUrl}api/infos/getCategories`,{
        headers: {"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
    })
        .then(async (res) => {
            let d = await res.json()
            console.log(d);
            cats = ["", ...d]
        })
    
        fetch(`${configs.baseUrl}api/medias/getCategories`,{
        headers: {"authorization" :`Bearer ${token}`,
                "phone" : theAdmin.phone,
                "email":theAdmin.email ,
                "imei": theAdmin.imei}
     })
        .then(async (res) => {
            let d = await res.json()
            console.log(d);
            cats = ["", ...d]
        })
});
    </script>
    
    {#if notifyReceived}
    <Notification color = {notifyColor}  message = {notifyMessage}></Notification>
    {/if}
    {#if uploading}
    <div class="flex items-center justify-center" style="width:100%; height:100%; position: fixed ; z-index: 1000 ; background: #00000060 ;top: 0; right: 0;">
        <div class="text-white font-4xl text-center">
                فایل در حال ارسال میباشد 
                <br/>
                لطفا شکیبا باشید
        </div>
    </div>
    {/if}
    <div  class="mt-5 flex flex-wrap bg-white  shadow-lg w-full p-4" style=" margin-top: 8rem; overflow: hidden; margin-bottom: 3rem;border-radius : 16px; " >
        <div class="w-full p-5">
            <label for="cats" style="direction: rtl !important; float: right;">دسته بندی</label>
            <!-- svelte-ignore a11y-no-onchange -->
            <select name="" on:change="{(e)=> {category = e.target.value ; console.log(category); getSubCat(e.target.value)
            }}" id="cats" class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
                <option value={undefined}></option>
                {#each cats as cat}
                <option value="{cat}">{cat}</option>
                {/each}
            </select>
           {#if subCats.length > 0}
           <label for="scats" style="direction: rtl !important; float: right;">زیر دسته بندی</label>
           <!-- svelte-ignore a11y-no-onchange -->
           <select name="" bind:value={subCategory}
           on:change="{(e)=> { 
               
           }}"
           id="cats" style="margin: 1rem 0;
               border-radius: 6px;
               width: 100%;">
               <option value={undefined}></option>
               {#each subCats as cat}
               <option value="{cat}">{cat}</option>
               {/each}
           </select>
           {/if}

           
           
        </div>
        <div class="md:w-6/12 w-full p-5" >
            <label for="name">عنوان</label>
            <input type="text" id="name" bind:value="{title}"
            class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
        </div>
        

 <div class="md:w-6/12 w-full p-5">

            <label id="img" style="float: right; margin-top: 2rem;border-radius : 16px;"
                class=" text-white w-full py-2 text-center bg-lightBlue-400" >
                انتخاب فایل
                <input style="visibility: hidden;" id="img" on:change="{e=>{
                 file = e.target.files[0] ; 
                ;  src = URL.createObjectURL(e.target.files[0])
                }}"
                class="cursor-pointer absolute block opacity-0 pin-r pin-t" type="file"a
                name="vacancyImageFiles">
    
            </label> </div>

        <div class="w-full ">
            
            <button 
            disabled={dis && file}
            on:click={submitData}
                
                style="margin: 0.5rem auto 1.5rem auto; display: inherit;width:96% ; cursor: pointer;border-radius : 16px;"
                class=" text-white w-full py-2 text-center bg-lightBlue-400"   > ارسال رسانه</button>
        </div>
      <div class=" w-full flex flex-wrap">
                <div class="w-full p-5">
                    <p class="my-2 ">بنر اپلیکیشن</p>
                </div>
                <div class="w-full p-5 "><label for="link">لینک</label></div>
                <div class="w-full p-5">
                    <input type="text" id="link" bind:value="{link}"
                    class="bg-gray-100 border-0 w-full rounded-lg py-2 px-5 mt-2 mb-3 focus:bg-white shadow">
                </div>
                

                <div class="flex flex-col p-5 w-full md:w-1/2">
                    <div class="w-full md:w-6/12 p-5">
                        <label id="img" style="background-color: blueviolet;float: right"
                        class=" hover:bg-indigo-dark text-white font-bold py-2 rounded  px-4 w-full items-center">
                        انتخاب عکس
                        <input style="visibility: hidden;" id="img" on:change="{e=>{ banner = e.target.files[0] ;  srcc = URL.createObjectURL(e.target.files[0])}}"
                        accept="image/*" class="cursor-pointer absolute block opacity-0 pin-r pin-t" type="file"
                        name="vacancyImageFiles">
    
                    </label>
                    </div>
                    <div class="w-full md:w-6/12 p-5">
                        <div class=" inline-block mt-3" style="width: 100%;
                        border: 2px dashed gray; border-radius: 8px;
                        background-image: url({srcc}) ;height: 16rem; background-size: cover ; " >
    
                        </div>
                    </div>
                </div>
                
                <div class="mt-2 w-full p-5">
                <AddBtn disabled={banner == undefined || link == undefined}
                on:click={submitBanner}>ارسال بنر</AddBtn>
                </div>
               

      </div>
    </div>
    
    <style>
        @import url('https://v1.fontapi.ir/css/Estedad');
        p , button , div , input , label{
            font-family: Estedad, sans-serif;
            
        }
    </style>
    

