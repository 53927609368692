<div class="" style="max-width: 150px ; height: 30px; border: 2px #334477aa solid;margin: 0 auto ; border-radius: 6px;">
    <!-- svelte-ignore a11y-missing-attribute -->
    <a class="w-2/5" style="text-align: center ;background-color: whitesmoke;float:right; width:40%; height: 100% ; 
        ; font-weight: 600;cursor: pointer  ; border-radius: 6px ; border-top-left-radius: 0px;border-bottom-left-radius: 0px;
         {lDisabled ? "color: #34343434 ; background: #ffffff22!important": "color:  #3333aa ; background: whitesmoke" }" disabled={rDisabled} on:click={inc}>بعدی</a>
    <!-- svelte-ignore a11y-missing-attribute -->
    <a class=""
        style="width: 20% ; text-align: center ; height: 100%;border-right: 1px solid gray; border-left: 1px solid gray;display: inline-block">{pageNumber
        }</a>
    <!-- svelte-ignore a11y-missing-attribute -->
    <a class="w-2/5" style="text-align: center  ;float: left;width: 40%;height: 100% ;
         ; font-weight: 600;cursor: pointer ; border-radius: 6px ; 
         {rDisabled ? " color: #34343434 ; background: #ffffff22 !important"
        : "color:  #3333aa; background: whitesmoke" }" disabled={lDisabled} on:click={dec} >قبلی</a>

</div>

<script>
    import {
        onMount
    } from "svelte";

    let disabled = false,
        rDisabled = false,
        lDisabled = false
    let pageNumber = 1
    let total;
    import {
        postPageNumber,
        totalPosts
    } from "../store"
    let b;
    onMount(() => {
        totalPosts.subscribe(d => {
            total = d
            b = Math.floor(total / 10)
           
        })

    })

    $:  if (b + 1 > pageNumber) {
                if (pageNumber == 1) {
                    console.log("E1");
                    
                    rDisabled = true
                    lDisabled = false
                } else {
                    console.log("E2");
                    rDisabled = false
                    lDisabled = false
                }

            } else if (b + 1 < pageNumber) {
                console.log("E3");
                rDisabled = true
                lDisabled = true
            } else {
                if (b == 0) {
                    console.log("E4");
                    rDisabled = true
                    lDisabled = true
                } 
                else {
                    console.log("E5");
                    rDisabled = false
                    lDisabled = true
                }
            }

    const inc = () => {
        console.log("inc");
        if (pageNumber >= b + 1) return 
        {
            postPageNumber.update(n => n += 1)
            pageNumber++
        }
    }
    const dec = () => {
        if (pageNumber == 1) return
         {
            postPageNumber.update(n => n -= 1)
            pageNumber--
        }
    }

    console.log(rDisabled);
    console.log(lDisabled);

</script>